import React from "react";

function ChampionList(props) {
  return (
    <div id="news" className={"section"}>
      <div
        style={{
          paddingTop: "1rem",
          paddingBottom: props.notOnlyOne ? 0 : "auto",
        }}
        className={"section-wrapper block content-1170 center-relative"}
      >
        {props.sectionTitle && (
          <h5 className={"entry-title medium-text make-green"}>
            {props.sectionTitle}
          </h5>
        )}

        <div className={"content-wrapper"}>
          <div
            className={
              "blog-holder-scode latest-posts-scode block center-relative"
            }
          >
            <div
              style={{ padding: "0.75rem" }}
              className={"relative blog-item-holder-scode"}
            >
              <div className={"post-thumbnail "}>
                <a href="single.html">
                  <img
                    className={"latest-posts-background-featured-image-holder "}
                    src={require(`../../../../${props.image1}`)}
                  />
                </a>
              </div>
              <h4 className={"entry-title"}>
                <a href="single.html">{props.name1}</a>
              </h4>
              <div className={"excerpt"}>{props.text1}</div>
            </div>

            <div
              style={{ padding: "0.75rem" }}
              className={"relative blog-item-holder-scode"}
            >
              <div className={"post-thumbnail "}>
                <a href="single.html">
                  <img
                    className={"latest-posts-background-featured-image-holder "}
                    src={require(`../../../../${props.image2}`)}
                  />
                </a>
              </div>
              <h4 className={"entry-title"}>
                <a href="single.html">{props.name2}</a>
              </h4>
              <div className={"excerpt"}>{props.text2}</div>
            </div>
          </div>

          {/* <br />
          <div className={"button-holder center-text"}>
            <a href="blog.html" className={"button"}>
              {" "}
              GO TO BLOG{" "}
            </a>
          </div> */}
        </div>
        <div className={"clear"}></div>
      </div>
    </div>
  );
}

export default ChampionList;
