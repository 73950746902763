import React, { useState } from "react";
import { FaArrowCircleDown } from "react-icons/fa";

const ScrollBottomButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled <= document.documentElement.scrollHeight - 2000) {
      setVisible(true);
    } else if (scrolled > document.documentElement.scrollHeight - 2000) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight - 150,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="sticky-scroll-button">
      <FaArrowCircleDown
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </div>
  );
};

export default ScrollBottomButton;
