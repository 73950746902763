import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import YearsPagination from "./components/YearsPagination";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import useWindowDimensions from "../../hooks/useWindowDimension";
import ScrollBottomButton from "../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../components/ScrollButton/ScrollTopButton";

function Gallery(props) {
  const [next, setNext] = useState(null); // <-- initial null state
  const [prev, setPrev] = useState(2019); // <-- initial null state

  let locationURLArray = window.location.href.split("/");
  const isPCA =
    locationURLArray[locationURLArray.length - 2] === "PCA" ? true : false;
  const { _, width } = useWindowDimensions();
  const currentYear = locationURLArray.pop();
  return (
    <div id="gallery" className={"section no-page-title"}>
      <div className={"section-wrapper block content-1170 center-relative"}>
        <h1
          className="medium-text padding-1020"
          style={{ textAlign: "center" }}
        >
          {props.title}
        </h1>
        <br />

        <div style={{ textAlign: "center" }}>
          <YearsPagination
            last={props.last}
            first={props.first}
            setNext={setNext}
            setPrev={setPrev}
            showOnlyThree={width > 950 ? false : true}
          />
        </div>

        <Outlet />
        {currentYear !== "gallery" && (
          <div style={{ textAlign: "center" }}>
            <Link
              onClick={() => {
                setNext(next === props.last + 1 ? next : next + 1);
                setPrev(prev !== props.first - 1 ? prev - 1 : prev);
              }}
              to={`${isPCA ? "PCA/" : ""}${prev}`}
            >
              <h1 className="pagination-prev-next">
                <FaChevronLeft />
              </h1>
            </Link>

            <Link
              className="pagination-prev-next"
              onClick={() => {
                setNext(next === props.last + 1 ? next : next + 1);
                setPrev(prev !== props.first - 1 ? prev - 1 : prev);
              }}
              to={`${isPCA ? "PCA/" : ""}${next}`}
            >
              <h1 className="pagination-prev-next">
                <FaChevronRight />
              </h1>
            </Link>
          </div>
        )}
      </div>
      ;
    </div>
  );
}

export default Gallery;
