import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import TextAndTwoButtons from "../../components/TextAndTwoButtons/TextAndTwoButtons";
import { Link } from "react-router-dom";
function Store(props) {
  return (
    <GenericInfoPage
      title="Store"
      preQuoteBody={[
        <div style={{ textAlign: "center" }}>
          <a href="https://www.danubeadornments.com/">
            <img
              alt="store"
              className="values-image"
              src={require("../../images/store.webp")}
            />
          </a>
          <h6>
            Click on the image or click{" "}
            <a href="https://www.danubeadornments.com/">HERE</a> to enter the
            store!
          </h6>
          <br />
          <br />
          <span style={{ fontSize: "18px" }}>
            We do the best for our poodles. Our both websites are proof of that.
            Keep reading in order to discover more about who we are, what do we
            stand for and what do we do with and for our poodles. <br />
            <b>
              Or visit our online store and order the best items for your
              companion.
            </b>
          </span>
        </div>,

        <div
          style={{ textAlign: "center" }}
          className={"button-holder text-left"}
        >
          <a
            className={"button special"}
            href="https://www.danubeadornments.com/"
          >
            Visit the Store
          </a>{" "}
          <Link className={"button"} to="/">
            Go to the Home Page
          </Link>
        </div>,
      ]}
    />
  );
}

export default Store;
