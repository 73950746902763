import React, { Component } from "react";

class Milestones extends Component {
  render() {
    return (
      <div id="millstones" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>Achievements</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <ul className={"milestones-holder"}>
              <li className={"milestone"}>
                <div className={"milestone-info-left"}>
                  <p className={"milestone-num"}>A</p>
                </div>
                <div className={"milestone-info-right"}>
                  <h5>Breeder of Merit</h5>
                  <p className={"milestone-text"}>
                    AKC recognises responsible breeders who have gone above and
                    beyond on health issues, temperament, and genetic screening,
                    as well as to the individual care and placement of puppies
                    in responsible homes.
                  </p>
                </div>
              </li>

              <li className={"milestone"}>
                <div className={"milestone-info-left"}>
                  <p className={"milestone-num"}>N</p>
                </div>
                <div className={"milestone-info-right"}>
                  <h5>Natural Reared Poodles</h5>
                  <p className={"milestone-text"}>
                    The Natural Rearing Breeders Association is a premier
                    association and community for natural rearing breeders who
                    are raising their dogs as naturally healthy as possible for
                    the betterment of their chosen breed.
                  </p>
                </div>
              </li>

              <li className={"milestone"}>
                <div className={"milestone-info-left"}>
                  <p className={"milestone-num"}>C</p>
                </div>
                <div className={"milestone-info-right"}>
                  <h5>Championships</h5>
                  <p className={"milestone-text"}>
                    We are proud to have our kennel name on many champions in US
                    as well as throughout Europe.
                  </p>
                </div>
              </li>

              <li className={"milestone"}>
                <div className={"milestone-info-left"}>
                  <p className={"milestone-num"}>G</p>
                </div>
                <div className={"milestone-info-right"}>
                  <h5>Gene diversity </h5>
                  <p className={"milestone-text"}>
                    Working closely with my family in Europe gives us the
                    benefits of having a more diversified gene pool by using
                    stud dogs with European Pedigrees.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Milestones;
