import React from "react";

function GenericInfoSection(props) {
  return (
    <div id={props.id} className={"section"}>
      <div className={"page-title-holder"}>
        <h3 className={"entry-title"}>{props.sectionTitle}</h3>
      </div>
      <div className={"section-wrapper block content-1170 center-relative"}>
        <div className={"content-wrapper"}>
          <div className={"one_half"}>
            {props.titleDescription && (
              <p className={"title-description-up"}>{props.titleDescription}</p>
            )}
            <h2 className={"entry-title medium-text"}>{props.title}</h2>
            {props.description}
            {props.content1}
            {props.content2}
          </div>
          <div className={"one_half last"}>
            {props.images === "false" ? (
              <div className={"skills-holder"}>
                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>100%</div>
                  <div className={"skill-text"}>
                    <span>The Heighest Standards</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"75%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>100%</div>
                  <div className={"skill-text"}>
                    <span>Care</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"45%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>100%</div>
                  <div className={"skill-text"}>
                    <span>Dedication</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"90%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>100%</div>
                  <div className={"skill-text"}>
                    <span>Love</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"65%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>100%</div>
                  <div className={"skill-text"}>
                    <span>Respect</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"85%"}></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "5rem",
                  flexDirection: "column",
                }}
              >
                <img
                  className="image"
                  style={{
                    margin: "auto",
                    maxHeight: "700px",
                    maxWidth: "90%",
                  }}
                  src={props.image2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenericInfoSection;
