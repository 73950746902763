import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

function YearsPagination(props) {
  const [isActive, setActive] = useState(null); // <-- initial null state

  const generateArrayOfYears = () => {
    var max = props.last;
    var min = props.first;
    var years = [];

    if (props.showOnlyThree === false) {
      for (var i = min; i <= max; i++) {
        years.push(i);
      }
    } else
      for (var i = min; i <= max; i++) {
        if (
          i === currentURLYear ||
          i + 1 === currentURLYear ||
          i - 1 === currentURLYear
        )
          years.push(i);
      }

    if (years.length === 0) years = [2007, 2015, 2022];
    return years;
  };
  const toggleClass = (index) => {
    setActive((isActive) => (isActive = index));
  };

  const setParentStates = useCallback(() => {
    props.setPrev(currentURLYear !== props.first ? isActive - 1 : props.first);
    props.setNext(currentURLYear === props.last ? props.last : isActive + 1);
  });

  let currentURLYearString = window.location.href.split("/").pop();
  let currentURLYear = parseInt(currentURLYearString);
  let locationURLArray = window.location.href.split("/");
  const isPCA =
    locationURLArray[locationURLArray.length - 2] === "PCA" ? true : false;

  console.log(currentURLYear);
  // console.log(isPCA);

  useEffect(() => {
    setActive((isActive) => (isActive = parseInt(currentURLYear)));
    setParentStates();
  }, [currentURLYear, setParentStates]);

  // setParentStates();

  const years = generateArrayOfYears();

  const yearLinks = years.map((year, i) => {
    return (
      <React.Fragment>
        <Link
          onClick={() => {
            setParentStates();
            toggleClass(year);
          }}
          className={`button ${isActive === year ? "special" : ""} `}
          to={`${isPCA ? "PCA/" : ""}${year}`}
        >
          {year}
        </Link>{" "}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {yearLinks}
      {currentURLYearString !== "gallery" && (
        <h1 className="entry-title medium-text">{isActive}</h1>
      )}
    </React.Fragment>
  );
}

export default YearsPagination;
