import React from "react";
import TestingTable from "./TestingTable/TestingTable";
import Contact from "../../../components/Home/Contact/Contact";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";
import check1 from "../../../images/testings/health1.png";
import check2 from "../../../images/testings/health2.png";
import check3 from "../../../images/testings/health3.png";
import Gallery from "../../../components/Gallery/Gallery";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";

function HealthTestings() {
  const checkImages = [
    {
      original: check1,
      thumbnail: check1,
    },
    {
      original: check2,
      thumbnail: check2,
    },
    {
      original: check3,
      thumbnail: check3,
    },
  ];
  return (
    <React.Fragment>
      <div className={"single single-post"}>
        <div>
          <div id="content" className={"site-content center-relative"}>
            <article>
              <div className={"post-wrapper center-relative"}>
                <h1 className={"entry-title"} style={{ paddingTop: "7rem" }}>
                  Health Testing
                </h1>
                <div className={"entry-content"}>
                  <h4 style={{ textAlign: "center" }}>
                    At Danube, poodles health testing plays a very important
                    role. We genetically test all breeding stock.
                  </h4>
                  <TestingTable
                    impact="T"
                    title="Health Tests"
                    testings={[
                      "HIPS",
                      "EYES",
                      "PATELLAS",
                      "DWARFISM",
                      "DEGENERATIVE MYELOPATHY",
                    ]}
                    testsDescriptions={[
                      "Hips are radiographed at 1 year (preliminary) and 2 years (official) to check for hip dysplasia and sent to the Orthopedic Foundation of Animals (OFA) for evaluation.  With this radiograph the radiologist can view and rule out a debilitating hereditary degenerative joint disease called Leggs-Calf Perthes (LCP).",
                      "All poodles are prcd tested by DNA blood test, or are ‘A’ by parentage. Prcd(progressive rod-cone degeneration) is only ONE form of PRA( progressive retinal atrophy) that can afflict poodles.",
                      "Patellas are examined by a veterinarian as puppies and then certified with OFA as adults.  It is not unusual for a young puppy to have slightly loose (but not luxating) patellas until they build muscle mass, but by 1 year the ligaments should be tight. Knees are certified after 1 year of age.",
                      "All poodles are DNA tested for the dwarfism gene.",
                      "All poodles are DNA tested",
                    ]}
                  />
                </div>

                <Gallery
                  makeGreen="true"
                  paddingBottom="50px"
                  originalHeight={300}
                  title="Health Checks"
                  images={checkImages}
                />
                <GenericInfoPage
                  preQuoteBody={[
                    <iframe
                      width="500"
                      height="759"
                      src="https://www.youtube.com/embed/ghEhMKigpe0"
                      title="Alternative Medicine is used at Danube Poodles."
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>,
                  ]}
                  articleLayout={true}
                />
                <TextAndTwoButtons
                  text={
                    <span>
                      {" "}
                      <h6 style={{ textAlign: "center" }}>
                        Have you made up your mind? Are you ready for a poodle?
                        Fill the application form below and let's get to know
                        each other!
                      </h6>
                      <p style={{ textAlign: "center" }}>
                        Or if you need more information, check the page below.
                      </p>
                    </span>
                  }
                  button2="Health Protocol"
                  href2="/danube-health-protocol"
                  button1="Puppy application"
                  href1="/litter-plans"
                />
              </div>
            </article>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HealthTestings;
