import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import image1 from "../../../images/testings/health6.png";

function SpayingAndNeutering() {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Spaying and Neutering. Beneficial or Not?"
        preQuoteHeading={[
          <a href="http://genteelstandardpoodles.com/natural-rearing/">
            A little known fact that is slowly being recognized is that there
            are negative aspects of neutering (or spaying) your dog by Dr.
            Jeannie Thomason ( posted with approval )
          </a>,
          "",
          "",
        ]}
        preQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "400px" }}
              src={image1}
            />
          </div>,
          <h3>
            Spaying and neutering can actually be one of the most detrimental
            things we can do to the health of our dog(s).
          </h3>,
          <p>
            Think about it for a minute. The removing of the reproductive organs
            (via neutering or spaying) means – no more important hormones such
            as – progesterone. “When and animal is neutered, they are literally
            THROWN into “menopause”, with their progesterone levels suddenly
            plummeting. And what was that progesterone doing? It was keeping
            certain occult/subclinical inflammatory conditions under control
            (allergies, asthma, IBS, and immune-mediated diseases like lupus,
            rheumatoid disease, MS/peripheral neuropathies, and many more)” -Dr.
            John Symes
          </p>,
          <h3>Extend Your Dog’s Lifespan by over 30%!</h3>,
          <p>
            A study conducted at the Gerald P. Murphy Cancer Foundation and
            published in the December, 2009 issue of Aging Cell, has found a
            correlation between the age at which female Rottweilers are spayed
            and their lifespan. The study compared long-lived female Rotties
            (those with a lifespan of 13 or more years) with a group who lived a
            usual lifespan of about nine years.
          </p>,
          <p>
            “
            <em>
              Like women, female dogs in our study had a distinct survival
              advantage over males”
            </em>{" "}
            said the lead researcher David J. Waters, associate director of
            Purdue University’s Center on Aging and the Life Course and a
            professor in the Department of Clinical Sciences. “But taking away
            ovaries during the first four years of life completely erased the
            female survival advantage. We found that female Rottweilers that
            kept their ovaries for at least six years were four times more
            likely to reach exceptional longevity compared to females who had
            the shortest lifetime ovary exposure.” Because death from cancer is
            so prevalent in Rottweilers, researchers conducted a subgroup
            analysis of only dogs that did not die of cancer. This focused
            research further proved the strong association between intact
            ovaries and longevity.
          </p>,
          <p>
            Even in dogs that did not die of cancer, the female Rotties that
            kept their ovaries the longest were nine times more likely to
            achieve exceptional longevity (13+ years). Simply put, this study’s
            results indicate that the removal of a female dog’s ovaries
            significantly increases the risk for a major lethal disease!
          </p>,
          <p>
            Interestingly, the Rottweiler research lines up with findings from
            another recent study of women who had undergone hysterectomies. In
            that study, women who lost their ovaries prior to age 50 were at
            greater risk of death by causes other than breast, ovarian and
            uterine cancer than women who kept their ovaries until age 50.
          </p>,
          <h6>Sources:</h6>,
          <a href="https://pubmed.ncbi.nlm.nih.gov/19732047/">
            Exploring mechanisms of sex differences in longevity: lifetime ovary
            exposure and exceptional longevity in dogs
          </a>,
          <span>
            ⎯ Aging Cell . 2009, <br />
            <em>
              PubMed comprises more than 30 million citations for biomedical
              literature from MEDLINE, life science journals
            </em>
          </span>,
          <a href="http://www.gpmcf.org/respectovaries.html">
            Gerald P. Murphy Cancer Foundation GPMCF: Healthier Respect for
            Ovaries
          </a>,
          <br></br>,
          <h3>Health Problems Associated with Gonad Removal</h3>,
          <p>
            Common sense tells us, and research proves there are a number of
            health benefits associated with the sex hormones (estrogen,
            progesterone, testosterone) produced by ovaries and testicles. These
            advantages vary with the age, gender and breed of each animal.
          </p>,
          <p>
            Halting production of these hormones through spaying and neutering
            has been found to increase the risk of certain specific diseases and
            conditions in dogs. Remember, reproductive hormones affect more than
            just reproduction.
          </p>,
          <h6>Altered/Spayed Females:</h6>,
          <p>
            Recent veterinary studies have shown that dogs that are spayed have
            a much higher rate of bone cancer than unspayed dogs or those spayed
            late in life.
          </p>,
          <ul>
            <li>Increased aggression in altered females. (recent study)</li>
            <li>Increased occurrence of urinary calculi.</li>
            <li>Increased difficulty passing urinary calculi.</li>
            <li>Increased likelihood of vulvar pyoderma (urine scald)</li>
            <li>Increased likelihood of urinary incontinence.</li>
            <li>
              Increased likelihood of adverse reaction to vaccinations (27-38%).
            </li>
            <li>
              Notable decrease of activity/drive. ( important to those whose
              dogs aren’t just pets but are trained to do work too)
            </li>
            <li>
              Increased chance of “perpetual puppy syndrome” undesirable
              urination.
            </li>
            <li>
              Inhibited social adjustment if spayed prior to complete cognitive
              development (usually a good time AFTER sexual maturity).
            </li>
            <li>
              Substantial likelihood of appreciable demeanor change after spay
              (menopausal women know about hormone drop.. it’s not fun)
            </li>
            <li>
              Increased likelihood of cognitive disorders if spayed before
              sexual maturity.
            </li>
            <li>
              Increased likelihood of, or speeded progress of, degenerative
              osteological disorders.
            </li>
            <li>
              Notable decrease in muscle mass (again, not all dogs are lawn
              ornaments or carpet speedbumps)
            </li>
            <li>Higher rate of bone cancer</li>
            <li>
              Generally live 2 (or greater) years less than unaltered
              littermates in controlled studies.
            </li>
          </ul>,
          <h6>Altered/Neutered males:</h6>,
          <ul>
            <li>Increased occurrence of urinary calculi.</li>
            <li>Increased difficulty passing urinary calculi.</li>
            <li>Increased chance of urinary obstruction.</li>
            <li>Increased likelihood of urinary incontinence.</li>
            <li>
              Increased likelihood of adverse reaction to vaccinations (27-38%).
            </li>
            <li>
              Notable decrease in activity/drive. (same as above in female list)
            </li>
            <li>
              Increased chance of “perpetual puppy syndrome” undesirable
              urination.
            </li>
            <li>
              Inhibited social adjustment if castrated prior to sexual maturity.
            </li>
            <li>
              Substantial likelihood of appreciable demeanor change after
              castration (same concept as above in female list… reproductive
              hormones affect more than just reproduction).
            </li>
            <li>
              Increased likelihood of cognitive disorders if castrated before
              complete cognitive development (usually a good time AFTER sexual
              maturity).
            </li>
            <li>Notable decrease in muscle mass (yep, same as above)</li>
            <li>
              Generally live 2 (or greater) years shorter than unaltered
              littermates in controlled studies
            </li>
          </ul>,

          <p>
            Sterilization decisions should be a part of an informed, holistic
            approach to your pet’s gealth and quality of Life and the decision
            to neuter or not is and should remain YOURS.
          </p>,
          <p>
            <em>Be informed, be responsible!</em> Not neutering does not give
            you the license to breed your dog. Be responsible! Leave breeding to
            true breed preservationists such as natural rearing dog breeders or
            at the very least, breeders who keep their dogs current on all
            genetic health testing pertinent to their specific breed. Talk with
            natural rearing breeders and other experienced dog owners, and
            consult a veterinary naturopath or a true holistic veterinarian to
            understand what steps you can take to insure the overall health and
            longevity of your pet.
          </p>,
          <p>
            If you have a puppy or even an adult dog that is intact and you are
            considering a spay/neuter decision, I encourage you to please
            research and continue to learn all you can about surgical
            sterilization options and the risks associated with the procedures
            and if you still must neuter your dog please{" "}
            <em>wait until they are at least two years old</em>!
          </p>,
          <h6>Important Information</h6>,
          <p>
            In a tubal ligation, the oviducts are cut and tied off, preventing
            ova from getting to the uterus or coming in contact with sperm.
            Tubal ligation does NOT shut off hormone production, so your dog
            will continue to go into heat and can mate with male dogs, but no
            pregnancy will result. Dogs having had a vasectomy are still able to
            breed with a female but will not produce sperm to get her pregnant.
          </p>,
          <p>
            If you should decide for a tubal ligation, vasectomy, spaying or
            castration is best for you and your dog, make sure that your dog is
            mature and healthy enough to be considered balanced both physically
            and mentally. Generally speaking, maturity is not achieved until a
            dog has reached at least two years of age. Keep in mind that giant
            breed dogs are still developing at 2 years of age and should not be
            considered candidates for the loss of hormones until after the age
            of two.
          </p>,
          <h6>More information here</h6>,
          <a href="https://naiaonline.org/pdfs/LongTermHealthEffectsOfSpayNeuterInDogs.pdf">
            Long-Term Health Effects of Spay-Neuter in Dogs
          </a>,
          <a href="http://thewholedog.org/wholedognews/?p=145">
            Spaying/Neutering Being Promoted As A Replacement For Training &
            Responsibility
          </a>,
        ]}
        blockQuoteValue=<span>
          After reading this, do you feel as though your veterinarian has told
          you about things? <br />I don’t know about you, but to me it certainly
          doesn’t sound as if altered animals are more healthy than their
          brethren who were left as Nature started them out to be.
        </span>
      />
      <br />
      <br />
    </React.Fragment>
  );
}

export default SpayingAndNeutering;
