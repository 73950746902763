import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <div id="about" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>ABOUT</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper about-wrapper"}>
            <div className={"one_half"}>
              <p className={"title-description-up"}>WHO WE ARE</p>
              <h2 className={"entry-title medium-text"}>
                Welcome to <br />
                Danube Poodles!
              </h2>
              <p>
                My name is Timea R. Bodi and I breed quality Miniature Poodles
                under the name Danube.
              </p>
              <p>
                Growing up, my family and I bred and showed miniature poodles in
                Europe. Deeply in love with the poodle breed for their
                intelligence and beauty, devotion and happy joyful spirit.
              </p>
              <p>
                In 2007 I began my own adventure in the USA, as well as in
                Europe, to continue the family tradition while working as a
                Veterinary Technician for a decade accumulating knowledge,
                education and information from accomplished poodle fanciers and
                from people outside the breed.
              </p>
              <br />
            </div>

            <div className={"one_half last"}>
              <img
                style={{ borderRadius: "15px" }}
                src={require("../../../images/values.jpg")}
                alt=""
              />
              {/* <div
            style={{
              textAlign: "center",
              maxWidth: "95%",
              margin: "auto",
              marginBottom: "3rem",
            }}
          >
            <img
              className={"values-image"}
              src={require("../../../images/values.jpg")}
              alt="Values"
            />
          </div> */}
            </div>

            <div className={"clear"}></div>
          </div>
          <div
            style={{ margin: "auto", textAlign: "center" }}
            className={"button-holder"}
          >
            <Link className={"button"} to={"about"}>
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
