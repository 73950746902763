import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2017(props) {
  const folder1 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    if (i === 0 || i === 3 || i === 4) ext = "png";
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "450px" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    if (i === 0) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(12)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/5/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder6 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/6/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder7 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/7/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder8 = [...Array(3)].map((e, i) => {
    let ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/8/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder9 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/9/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder10 = [...Array(7)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/10/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder11 = [...Array(12)].map((e, i) => {
    let ext = "jpg";
    if (i === 1) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/11/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder12 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    if (i === 1 || i === 2 || i === 6) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/12/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder13 = [...Array(6)].map((e, i) => {
    let ext = "jpg";
    if (i === 5) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/13/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder14 = [...Array(1)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2017"
        src={require(`../../../images/gallery/2017/14/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>
      <div className="flex-custom-container">{folder5}</div>,
      <div className="flex-custom-container">{folder6}</div>,
      <div className="flex-custom-container">{folder7}</div>
      <div className="flex-custom-container">{folder8}</div>
      <div className="flex-custom-container">{folder9}</div>
      <div className="flex-custom-container">{folder10}</div>
      <div className="flex-custom-container">{folder11}</div>,
      <div className="flex-custom-container">{folder12}</div>,
      <div className="flex-custom-container">{folder13}</div>,
      <div className="flex-custom-container">{folder14}</div>, ;
    </React.Fragment>
  );
}

export default Gallery2017;
