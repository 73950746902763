import React from "react";
import { Carousel } from "react-responsive-carousel";

function TestimonialBody(props) {
  return (
    <div className={"content-wrapper"}>
      <div>
        <div className={"text-slider-wrapper relative"}>
          <div className={"text-slider-header-quotes"}></div>

          <div className={"text-slide"}>
            <p className={"text-slide-content"}>{props.text}</p>
            {props.name1 && <p className={"text-slide-name"}> {props.name1}</p>}
            {props.name2 && (
              <p className={"text-slide-position"}>{props.name2}</p>
            )}
          </div>
          <br />
          <br />
          <Carousel
            showThumbs={false}
            showIndicators={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            {props.images}
          </Carousel>

          <div className={"clear"}></div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialBody;
