import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";
import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={
              <span>
                Louis is an absolute delight--beautiful, playful, smart and
                affectionate. We are so happy to have him in our family.
              </span>
            }
            images={[...Array(10)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page2/1/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>

        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              Ruby is smart, affectionate, adaptable, and a ball of boundless
              energy. She loves to play with toys, explore her new environment,
              go for long walks, and spend time with our granddaughters. Ruby is
              a joy
            </span>
            images={[...Array(11)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page2/2/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text=<span>
              Rowan has an angel face with a devilish playfulness. She loves
              tormenting her best bud Onyx but when she gets tired she drops
              like a wet noodle. She is also a little Houdini who escapes from
              her xpen or closed room. She's a great pup that loves to cuddle
              with you.
            </span>
            images={[...Array(9)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page2/3/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              Flynn has been the sweetest and most lovable baby, we love him
              more each day! He is such a friendly and happy puppy, his tail
              rarely stops wagging. <br />
              He wants to make friends every where he goes, offering kisses to
              everyone he comes in contact with! He also has learned sit in
              command, knows his name and scratches the door to go outside.{" "}
              <br />
              We are so grateful to you for such an awesome poodle dog. Thank
              you for everything.
            </span>
            images={[...Array(9)].map((e, i) => {
              let ext = ".jpg";

              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page2/4/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div>2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div>3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div>4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div className="active">5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div>6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
