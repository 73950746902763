import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";

class Team extends Component {
  render() {
    return (
      <div id="team" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>TEAM</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <Parallax translateX={[10, 0]}>
              <div className={"member member-left"}>
                <img
                  src={require("./about_item_01.jpg")}
                  alt=""
                  data-threshold="0 0"
                  data-jarallax-element="60 0"
                />

                <div className={"member-info"}>
                  <p className={"member-postition"}>Puppy 1</p>
                  <h5 className={"member-name"}>Marty Stone</h5>
                  <div className={"member-content"}>
                    <p>
                      Code the energy hidden in matter citizens of distant
                      epochs sun. Citizens of distant epochs encyclopaedia
                      galant ctica the ash of stellar alchemy Vangelis white
                      dwarf adipisci velit. Nemo enim ipsam volupta tem quia
                      voluptas sit aspernatur aut odit aut fugit radio telescope
                      quis nostrum exercitatio nem ullam corporis suscipit
                      laboriosam quis.
                    </p>
                    <div
                      className={"member-social-holder"}
                      data-jarallax-element="0 30"
                    >
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-facebook"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-twitter"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-instagram"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-vimeo"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-behance"}></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>

            <br />
            <Parallax translateXY={[-10, 0]}>
              {" "}
              <div className={"member big-screen member-right"}>
                <div className={"member-info"}>
                  <p className={"member-postition"}>Puppy 2</p>
                  <h5 className={"member-name"}>John Williams</h5>
                  <div className={"member-content"}>
                    <p>
                      Code the energy hidden in matter citizens of distant
                      epochs sun. Citizens of distant epochs encyclopaedia
                      galant ctica the ash of stellar alchemy Vangelis white
                      dwarf adipisci velit. Nemo enim ipsam volupta tem quia
                      voluptas sit aspernatur aut odit aut fugit radio telescope
                      quis nostrum exercitatio nem ullam corporis suscipit
                      laboriosam quis.
                    </p>
                    <div
                      className={"member-social-holder"}
                      data-jarallax-element="0 -30"
                    >
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-facebook"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-twitter"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-instagram"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-vimeo"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-behance"}></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={require("./about_item_02.jpg")}
                  alt=""
                  data-threshold="0 0"
                  data-jarallax-element="60 0"
                />
              </div>
            </Parallax>

            <br />
            <Parallax translateX={[10, 0]}>
              <div className={"member member-left"}>
                <img
                  src={require("./about_item_03.jpg")}
                  alt=""
                  data-threshold="0 0"
                  data-jarallax-element="60 0"
                />
                <div className={"member-info"}>
                  <p className={"member-postition"}>Puppy 3</p>
                  <h5 className={"member-name"}>Vanessa Doe</h5>
                  <div className={"member-content"}>
                    <p>
                      Code the energy hidden in matter citizens of distant
                      epochs sun. Citizens of distant epochs encyclopaedia
                      galant ctica the ash of stellar alchemy Vangelis white
                      dwarf adipisci velit. Nemo enim ipsam volupta tem quia
                      voluptas sit aspernatur aut odit aut fugit radio telescope
                      quis nostrum exercitatio nem ullam corporis suscipit
                      laboriosam quis.
                    </p>
                    <div
                      className={"member-social-holder"}
                      data-jarallax-element="0 30"
                    >
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-facebook"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-twitter"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-instagram"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-vimeo"}></span>
                        </a>
                      </div>
                      <div className={"social"}>
                        <a href="#" target="_blank">
                          <span className={"fa fa-behance"}></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
