import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import KeyFeatures from "./KeyFeatures/KeyFeatures";
import TextCards from "../../../components/TextCards/TextCards";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

import image1 from "../../../images/healthProtocol/health_1.jpeg";
import image2 from "../../../images/healthProtocol/health_2.jpeg";
import image3 from "../../../images/healthProtocol/health_3.jpeg";
import image4 from "../../../images/healthProtocol/health_4.jpeg";
import image5 from "../../../images/healthProtocol/health_5.jpeg";
import image6 from "../../../images/healthProtocol/health_6.jpeg";
import image7 from "../../../images/healthProtocol/health_7.jpeg";
import image8 from "../../../images/healthProtocol/health_protocol3.png";
import image9 from "../../../images/healthProtocol/health_protocol4.png";

import Diet1 from "../../../resources/vaccines/Junk Pet Food And The Damage Done - Dr. Tom Lonsdale.pdf";
import Diet2 from "../../../resources/vaccines/Why fat in the diet isn't 'bad' when you are a dog - Mogens Eliason.pdf";

import Gallery from "../../../components/Gallery/Gallery";

function HealthProtocol() {
  const images = [
    {
      original: image1,
      thumbnail: image1,
      description: "Dr. Melissa Shelton DVM",
    },
    {
      original: image2,
      thumbnail: image2,
      description: "Dr. Judy Morgan, DVM,CVA,CVCP,CVFT",
    },
    {
      original: image3,
      thumbnail: image3,
      description: "Dr. Gerald Buchoff, BVScAH, MS",
    },
    {
      original: image4,
      thumbnail: image4,
      description: "Dr. Laurie Coger DVM,CVCP",
    },
    {
      original: image5,
      thumbnail: image5,
      description: "Caroline Ingraham-Zoopharmacognocy Pioneer",
    },
    {
      original: image6,
      thumbnail: image6,
      description: "Dr. Melissa Shelton DVM",
    },
    {
      original: image7,
      thumbnail: image7,
      description: "Dr. Karen S. Becker DVM, CVA",
    },
  ];
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Danube Health Protocol"
        preQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "500px" }}
              src={image9}
            />
            ,
          </div>,
          " I take pride in producing healthy and quality Miniature Poodles. Although no breed is without health issues, I have the best interest of the breed at heart.",
          " I provide all health certifications and although certifications don’t guarantee an issue won’t arise it certainly provides proactive peace of mind.",
        ]}
        blockQuoteValue={[
          "   Immeasurable care and attention is given to each litter. My poodles are an intricate part of my family and they each get the individualized attention they need to excel in development of their temperaments. It’s important to research and understand the lifelong commitment involved in caring for these precious family members to insure many years of mutual sheared happiness.",
        ]}
        postQuoteBody={[
          " I collaborate with breeders in Europe. This unique opportunity enables me to contribute to creating a more vast gene pool for the breed. I don’t breed for financial gain but with each generation of puppies, I’m passionately looking for the next champion show dog.",
          " Above all, I breed for the better of the breed, health, and temperament. If you and yours are in search of a family companion that will absolutely warm your hearts and light up your life then the poodle breed is the right choice. I will answer as many questions you need answered to satisfy your queries, concerns, curiosity and share knowledge and provide advice for years to come. Prospective owners will be carefully screened to ensure that your next family addition will be nothing short of the perfect match.",
        ]}
      ></GenericInfoPage>
      <KeyFeatures
        title="WHAT YOU GET"
        keys={[
          {
            impact: "Q",
            title: "QUALITY & HEALTH",
            text: "I have the best interest of the breed at heart and I provide all health certifications. All these should provide proactive peace of mind.",
          },
          {
            impact: "D",
            title: "DIVERSITY & HERITAGE",
            text: "I collaborate with breeders in Europe. This way I can create a more vast gene pool for the breed and to preserve a special lineage of champions.",
          },
          {
            impact: "C",
            title: "CARE & ATTENTION",
            text: "I breed for the better of the breed, health, and temperament. Immeasurable care and attention is given to each litter, as each poodle is a part of my family.",
          },
          {
            impact: "L",
            title: "LIFETIME CONSULTING",
            text: "I will answer as many questions you need answered to satisfy your queries, concerns, curiosity and share knowledge and provide advice for years to come.",
          },
        ]}
      ></KeyFeatures>
      <GenericInfoPage
        removeClear="true"
        title="Here are some highlights of our protocol"
        preQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "500px" }}
              src={image8}
            />
            ,
          </div>,
        ]}
        // articleLayout="true"
      ></GenericInfoPage>
      <SimpleInfoSection
        title="1. Diet"
        content1={
          <React.Fragment>
            <iframe
              width="560"
              height="400"
              src="https://www.youtube.com/embed/4r3cw5QuISc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p>
              <em>
                <b>
                  "When diet is wrong, medicine is of no use. When diet is
                  correct, medicine is of no need."
                </b>
              </em>
            </p>
            <p>
              We believe the healthiest happiest dog you could have, would and
              should be on a raw diet!! Diet plays a huge part in our poodles
              overall wellness. It is the most influential thing!
            </p>
            <p>Here are some useful resources on this topic:</p>

            <blockquote>
              1. "What should we actually feed them" by Sarah Griffiths click{" "}
              <a href="https://blog.adoredbeast.com/dog-nutrition-what-should-we-actually-feed-them/">
                HERE
              </a>
              <br />
              2. Dr. Becker Discusses Raw Food Diet (Part 1) click{" "}
              <a href="https://www.youtube.com/watch?v=Qx2YIIpF4cc">HERE</a>
              <br />
              3. Dr. Becker Discusses Raw Food Diet (Part 2) click{" "}
              <a href="https://www.youtube.com/watch?v=TJPToVsJj-c">HERE</a>
            </blockquote>
            <a className={"button"} href={Diet1}>
              Junk Pet Food And The Damage Done - Dr. Tom Lonsdale
            </a>
            <br />
            <a className={"button"} href={Diet2}>
              Why fat in the diet isn't 'bad' when you are a dog - Mogens
              Eliason
            </a>
          </React.Fragment>
        }
      ></SimpleInfoSection>
      <SimpleInfoSection
        title="2. How we raise our poodles"
        content1={
          <React.Fragment>
            <p>
              <em>
                <b>With healthy parents come healthy puppies.</b>
              </em>
            </p>
            <p>
              We belive that with healthy parents comes healthy puppies.So
              please ask us how we care for our poodle parents.
            </p>
            <p>
              Puppie's first weeks of their lifes is crucial in development of
              brain, healthy immune system and temperament. We implement{" "}
              <strong>Puppy Culture</strong> vision and so much more. For more
              about Puppy Cultre click{" "}
              <a href="https://shoppuppyculture.com/">HERE</a>
            </p>

            <blockquote>
              1. "What should we actually feed them" by Sarah Griffiths click{" "}
              <a href="https://blog.adoredbeast.com/dog-nutrition-what-should-we-actually-feed-them/">
                HERE
              </a>
              <br />
              2. Dr. Becker Discusses Raw Food Diet (Part 1) click{" "}
              <a href="https://www.youtube.com/watch?v=Qx2YIIpF4cc">HERE</a>
              <br />
              3. Dr. Becker Discusses Raw Food Diet (Part 2) click{" "}
              <a href="https://www.youtube.com/watch?v=TJPToVsJj-c">HERE</a>
            </blockquote>
          </React.Fragment>
        }
      ></SimpleInfoSection>
      <TextCards
        marginBottom="50px"
        titles={[
          "Socializing Your Puppy",
          "Must-Do Puppy Training",
          "Communication Trinity",
          "Problem Prevention",
        ]}
        // eslint-disable-next-line no-sparse-arrays
        texts={[
          <ul className={"no-bullets"}>
            <li>How to Hold a Puppy Party</li>
            <li>How to Recognize Appropriate Playmates</li>
            <li>How to Find a Good Puppy Class</li>
            <li>Vaccination vs Socialization </li>
            <li>Handling Fear Periods</li>
          </ul>,
          <ul className={"no-bullets"}>
            <li>Leash Walking</li>
            <li>Recall</li>
            <li>Crate Training</li>
            <li>Potty Training</li>
          </ul>,
          <ul className={"no-bullets"}>
            <li>Training Markers/Clickers</li>
            <li>Box Game</li>
            <li>Manding</li>
          </ul>,
          <ul className={"no-bullets"}>
            <li>Aggression</li>
            <li>Resource Guarding </li>
            <li>Aggression</li>
            <li>Biting and Jumping</li>
          </ul>,
        ]}
      />

      <SimpleInfoSection
        content1={
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "#2b7a0b" }}>All backed up by science!</h2>
            <p>
              Our puppies are exposed to over hundreds of stimuli with an
              extensive socialization program.
              <br />
              <strong>We are with our poodles and puppies 24/7.</strong>
              <br />
              You are welcome to set up a phone conversation with us to find out
              more about our poodles lifestyle. We are extremly transparent.
            </p>
          </div>
        }
      />

      <SimpleInfoSection
        title="3. Toxic enviroment "
        content1={
          <React.Fragment>
            <blockquote>
              1. Dr. Jean Dodds talks about toxic chemicals in pet foods - click{" "}
              <a href="https://www.youtube.com/watch?v=cIpLoFg-C4U&feature=youtu.be">
                HERE
              </a>
              <br />
              2. Dr. Becker and Dr. Dodds Talk About Titers - click{" "}
              <a href="https://www.youtube.com/watch?v=jQrh34kHB7s">HERE</a>
              <br />
              3. Dr. Becker Discusses Canine Leptospirosis - click{" "}
              <a href="https://www.youtube.com/watch?v=Sp-rK2kQREY">HERE</a>
              <br />
              4. Dr. Becker Discusses Canine Coronavirus (CCV) - click{" "}
              <a href="https://www.youtube.com/watch?v=cNrI6uWn3Qk">HERE</a>
            </blockquote>
          </React.Fragment>
        }
      ></SimpleInfoSection>
      <SimpleInfoSection
        title="4. Spaying or Neutering"
        content1={
          <React.Fragment>
            <blockquote>
              1. Dr. Becker: The Truth About Spaying and Neutering - click{" "}
              <a href="https://www.youtube.com/watch?v=enPCZA1WFKY&feature=youtu.be">
                HERE
              </a>
              <br />
              2. Dr M. Shelton - Essential Oils for Animals - click{" "}
              <a href="https://animaleo.info/shop/">HERE</a>
              <br />
            </blockquote>
            <br />
          </React.Fragment>
        }
      ></SimpleInfoSection>

      <Gallery
        id="millstones"
        title="We follow & consult with highly experienced veterinarians."
        images={images}
      />
      <TextAndTwoButtons
        text={
          <div style={{ textAlign: "center" }}>
            <h3>We take our poodles' health and wellbeing very seriously.</h3>
            <p>
              Keep exploring our website and see if a poodle is the right
              companion for you.
            </p>
          </div>
        }
        button1="Go Back"
        button2="Learn More About Us"
        href2="/about"
      />
    </React.Fragment>
  );
}

export default HealthProtocol;
