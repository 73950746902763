import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";
import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={
              <span>Its always fun time when Scarlet is being spoiled</span>
            }
            images={[...Array(3)].map((e, i) => {
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/9/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text={
              <span>
                There's never a doll moment in this bunch ! Remy, Meadow and
                Primrose love to keep it tight
              </span>
            }
            images={[...Array(8)].map((e, i) => {
              let ext = ".jpg";
              if (i === 4 || i === 0 || i === 6) ext = ".jpeg";
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/10/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text="Merry Christmas and Happy New Year! From Indigo and Winston"
            images={[...Array(7)].map((e, i) => {
              let ext = ".jpg";
              if (i === 4 || i === 0 || i === 6) ext = ".png";
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/11/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              Timea, we can't thank you enough for Bailey. He is a loving,
              energetic puppy who has already brought a great deal of joy into
              our lives. Bailey is also a big hit with our neighbors winning
              many compliments for his beautiful coat, good looks, and spunky
              personality. <br />
              He gets lots of attention, hugs and kisses from his neighborhood
              fan club and can't wait to go for walks to meet everyone. He is
              also the smartest little fellow, has a great disposition and has
              been easy to train. <br />
              We couldn't be happier and if you ever this way please stop by and
              say hello.
            </span>
            images={[...Array(7)].map((e, i) => {
              let ext = ".jpg";

              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/12/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div>2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div className="active">3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div>4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div>5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div>6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
