import React from "react";
import { Carousel } from "react-responsive-carousel";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function RetiredChampions() {
  const vivi_array = [...Array(7)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/retired/vivi/img_${
          i + 1
        }.png`)}
      />
    );
  });

  const indie_array = [...Array(6)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/retired/indie/img_${
          i + 1
        }.png`)}
      />
    );
  });

  const merlot_array = [...Array(50)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/retired/merlot/img_${
          i + 1
        }.jpg`)}
      />
    );
  });

  const piper_array = [...Array(4)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/retired/piper/img_${
          i + 1
        }.jpeg`)}
      />
    );
  });

  return (
    <>
      <GenericInfoPage
        title="Our Retired Poodles"
        preQuoteHeadings={["We love all our poodles young and old."]}
        preQuoteBody={[
          "We pamper our retired poodles and we make sure they get to enjoy their golden years in retirement with us. We would never consider rehoming them. They are our lives and hearts that run in our family generationally.",
        ]}
        postQuoteHeadings={[
          "CH Dimarnique's Christmas Eve At Danube aka Vivian",
          "Dimarniques California Dream'In aka Indie",
          "FCI Ch Bonbon Iz Volzhskoy Serenady aka Merlot",
          "Danube's And Dimarnique's Eroica Symphony aka Pipper",
        ]}
        postQuoteBody={[
          <>
            <p>
              {" "}
              Vivi is a love bug! Always in tune with everything and everybody.
              Loves to cuddle and always in mood to play. She learns commands
              really fast and gives her all at all times. She is not a picky
              eater and she wont hold back on kisses that's for sure. Give her a
              toy and time her, because she will have the squawker out in less
              then a minute.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {vivi_array}
            </Carousel>
          </>,
          <>
            <p>
              {" "}
              Indie is the king of the poodle pack. His got the biggest
              personality and character as well as the wits and looks. His very
              friendly and welcoming with new guests as well. Always in mood to
              play games and act goofy. He has good hunting instincts that we
              have seen passed down to his offsprings. Always full of energy but
              takes time to show his affection by snuggling and giving kisses,
              sometimes chewing your ears.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {indie_array}
            </Carousel>
          </>,
          <>
            <p>
              He's our second Russian import stud dog. He is a very friendly and
              happy boy. He loves sitting on the couch and watching TV. When he
              is in a playful mood he likes to chase squirrels. He is a very
              athletic dog and loves agility games.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {merlot_array}
            </Carousel>
          </>,
          <>
            <p>
              Pipper is the quintessential princess of the house. She’s
              sensitive, has lots of love to offer and in tune with every human
              emotion. She can express her feelings using a plethora of sounds
              and she usually ends up getting what she wants. We can’t say no to
              her cuteness ! In the pack she likes to boss her playmates around
              and she always has the last say. She’s also the best kisser.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {piper_array}
            </Carousel>
          </>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol or natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>
  );
}

export default RetiredChampions;
