import React from "react";
import { Carousel } from "react-responsive-carousel";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function Retired() {
  const maya1 = [...Array(7)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/maya/lugoj/img_${i}.jpg`)}
      />
    );
  });
  const maya2 = [...Array(5)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/maya/us/img_${i + 1}.jpg`)}
      />
    );
  });
  const maya3 = [...Array(12)].map((e, i) => {
    let ext = "jpg";
    if (i === 11) ext = "png";
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/maya/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder1 = [...Array(2)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/1/img_${i + 1}.jpg`)}
      />
    );
  });
  const folder2 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    if (i === 9 || i === 6) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/2/img_${
          i + 1
        }.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(10)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/3/img_${i + 1}.jpg`)}
      />
    );
  });
  const folder4 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    if (i === 0) ext = "png";
    if (i === 9) ext = "webp";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/4/img_${i}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(7)].map((e, i) => {
    let ext = "jpg";
    if (i === 1) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/5/img_${
          i + 1
        }.${ext}`)}
      />
    );
  });
  return (
    <>
      <GenericInfoPage
        title="Our Retired Poodles"
        postQuoteHeadings={[
          "FCI Ch Danube's Hungarian Rhapsody No. 5 aka Maya",
          "Dimarnique's Changing Colors With Danube aka Autumn",
          "Maya & Autumn",
        ]}
        postQuoteBody={[
          <>
            {" "}
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {[...maya3, ...maya2, ...maya1]}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {[...folder1, ...folder2, ...folder5]}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {[...folder4, ...folder4]}
            </Carousel>
          </>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol or natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>
  );
}

export default Retired;
