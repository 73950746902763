import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2019(props) {
  const folder1 = [...Array(11)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(5)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(6)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(7)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/5/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder6 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/6/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder7 = [...Array(1)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/7/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder8 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/8/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder9 = [...Array(11)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/9/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder10 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/10/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder11 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/11/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder12 = [...Array(6)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2019"
        src={require(`../../../images/gallery/2019/12/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>
      <div className="flex-custom-container">{folder5}</div>,
      <div className="flex-custom-container">{folder6}</div>,
      <div className="flex-custom-container">{folder7}</div>
      <div className="flex-custom-container">{folder8}</div>
      <div className="flex-custom-container">{folder9}</div>
      <div className="flex-custom-container">{folder10}</div>
      <div className="flex-custom-container">{folder11}</div>,
      <div className="flex-custom-container">{folder12}</div>, ;
    </React.Fragment>
  );
}

export default Gallery2019;
