import React, { Component } from "react";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navigation from "./Navigation/Navigation";

class Header extends Component {
  render() {
    return (
      <header className={"header-holder"}>
        <div className={"menu-wrapper center-relative relative"}>
          <div className={"header-logo"}>
            <a href="/">
              <img
                src={require("../../images/newLogos/img_1.png")}
                alt="Danube Poodles"
              />
            </a>
          </div>

          <div className={"toggle-holder"}>
            <NavDropdown
              menuVariant="dark"
              id="nav-dropdow-mobile"
              title={
                <div id="toggle">
                  <div className={"first-menu-line"}></div>
                  <div className={"second-menu-line"}></div>
                  <div className={"third-menu-line"}></div>
                </div>
              }
            >
              <Navigation />{" "}
            </NavDropdown>
          </div>

          <div className={"menu-holder"}>
            <nav id="header-main-menu">
              <Navigation />
            </nav>
          </div>
          <div className={"clear"}></div>
        </div>
      </header>
    );
  }
}

export default Header;
