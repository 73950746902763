import ImageGallery from "react-image-gallery";
import React from "react";

function Gallery(props) {
  return (
    <div
      style={{ paddingBottom: props.paddingBottom }}
      id={props.id ? props.id : "gallery"}
      className={"section single"}
    >
      <br />
      <div className={"block content-1170 center-relative"}>
        <div className={"content-wrapper"}>
          {props.title && (
            <h1
              className={`entry-title `}
              style={{
                textAlign: "center",
                color: props.id
                  ? "#FFFFFF"
                  : props.makeGreen
                  ? "#2b7a0b"
                  : "#000000",
              }}
            >
              {props.title}
            </h1>
          )}
          <ImageGallery
            originalclassName={"galleryClass"}
            items={props.images}
          />
        </div>
      </div>
      <br />
    </div>
  );
}

export default Gallery;
