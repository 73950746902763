import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";

function HeavenPage(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        imageName="about/heaven_3.jpeg"
        imageGallery={["about/heaven_1.jpeg", "about/heaven_4.jpeg"]}
        title="Our Piece of Heaven"
        imageGalleryHeight="340px"
        preQuoteHeadings={["Lots of nature and space"]}
        preQuoteBody={[
          "This is our little piece of heaven where Danube Poodles get to enjoy fresh air, run and play all day long. We live in middle of Tennessee on a 10 acre land surrounded by God's nature beauty , rivers and picturesque waterfalls. Here is where our poodles get to learn to sharpen their senses chasing rabbits, butterflies ,birds and share undivided time and attention from family and friends.",
        ]}
        blockQuoteValue="We feel getting into the nitty gritty of the breed is really what advances not only the individual poodle, but the breed as a whole."
        postQuoteBody={[
          <iframe
            width="560"
            height="700"
            src="https://www.youtube.com/embed/KQzMHMh9YZw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="848"
            height="697"
            src="https://www.youtube.com/embed/Xq1R-OFCewQ"
            title="Danube Poodles Puppies"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,

          <iframe
            width="476"
            height="847"
            src="https://www.youtube.com/embed/eydjH60Q0ek"
            title="Baldwin’s touchdown 🥳🐩"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="476"
            height="847"
            src="https://www.youtube.com/embed/-shE1uNpzUM"
            title="Exercises is important 😉🐩💞"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="476"
            height="847"
            src="https://www.youtube.com/embed/AM6jF2Kpeyg"
            title="Chianti got some really good instincts 🥳🙌🐩"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="848"
            height="482"
            src="https://www.youtube.com/embed/F0VByLa3tY8"
            title="Danube Poodles love snow ❄️☃️🐩🥳"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/89d3xmsyZKA"
            title="October 30, 2022"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="627"
            src="https://www.youtube.com/embed/0VXZBvkjyRs"
            title="Danube Poodles having fun 🌞🐩🥳"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />

      <br />
      <br />
    </React.Fragment>
  );
}

export default HeavenPage;
