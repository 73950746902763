import React from "react";

function SimpleInfoSection(props) {
  return (
    <div
      style={{
        color: props.id === "millstones" ? "white" : "black",
        paddingTop: props.paddingTop ? props.paddingTop : 0,
      }}
      id={props.id ? props.id : "simpleInfoSection"}
      className={"section single"}
    >
      {props.sectionTitle && (
        <React.Fragment>
          <div className={"page-title-holder"}>
            <h3 className={"entry-title"}>{props.sectionTitle}</h3>
          </div>
          {/* <div className={"page-title-holder-left"}></div> */}
        </React.Fragment>
      )}
      <div
        className={`block content-1170 center-relative ${
          props.additionalClass !== undefined ? props.additionalClass : ""
        }`}
      >
        <div className={"content-wrapper entry-content"}>
          <div>
            {props.titleDescription && (
              <p
                style={{
                  color: props.id === "millstones" ? "white" : "black",
                }}
                className={"title-description-up"}
              >
                {props.titleDescription}
              </p>
            )}

            {props.title && (
              <h5
                style={{ color: props.id === "millstones" ? "white" : "black" }}
                className={"entry-title medium-text"}
              >
                {props.title}
              </h5>
            )}
            {props.description && <p>{props.description}</p>}
            <div
              style={{ color: props.id === "millstones" ? "white" : "black" }}
            >
              {" "}
              {props.content1}
            </div>
            <div
              style={{ color: props.id === "millstones" ? "white" : "black" }}
            >
              {" "}
              {props.content2}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleInfoSection;
