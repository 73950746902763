import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import TextAndTwoButtons from "../../components/TextAndTwoButtons/TextAndTwoButtons";

function PageNotFound404(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title=<span>
          Oops, we could not find that page <br />
          <br /> <span className="make-green">Error 404</span>
        </span>
      />

      <div className="flex-custom-container">
        <img
          className={"champions-images"}
          alt="confused poodle"
          src={require("../../images/404.png")}
        />
      </div>
      <TextAndTwoButtons
        text=<div>
          <h3>Are you lost?</h3> Get back on the main page by clicking the first
          button below or keep learning and read our Health articles by clicking
          on the second button. Enjoy!
        </div>
        button1="Home"
        button2="Danube Health Protocol"
        href2="/danube-health-protocol"
      />
    </React.Fragment>
  );
}

export default PageNotFound404;
