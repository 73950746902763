import React, { Component } from "react";
import { Link } from "react-router-dom";

class Portfolio extends Component {
  render() {
    return (
      <div id="portfolio" className={"section no-padding"}>
        {/* <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>Gallery</h3>
        </div> */}
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <div id="portfolio-wrapper">
              <div className={"portfolio-load-content-holder"}></div>
              <h2 className={"entry-title medium-text"}>Gallery</h2>
              <p>Check out our memories over the years</p>
              <br />
              <br />

              <div className={"grid"} id="portfolio-grid">
                <div className={"grid-sizer"}></div>

                <div
                  id="p-item-1"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2022"
                    data-id="1"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2022.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2022</p>
                    </div>
                  </a>
                </div>

                <div
                  id="p-item-3"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2021"
                    data-id="3"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2021.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2021</p>
                    </div>
                  </a>
                </div>
                <div
                  id="p-item-3"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2018"
                    data-id="3"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2018.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2018</p>
                    </div>
                  </a>
                </div>
                <div
                  id="p-item-3"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2017"
                    data-id="3"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2017.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2017</p>
                    </div>
                  </a>
                </div>

                <div
                  id="p-item-3"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2020"
                    data-id="3"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2020.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2020</p>
                    </div>
                  </a>
                </div>

                <div
                  id="p-item-3"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery/2019"
                    data-id="3"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/2019.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>Click for more</p>
                      <p className={"portfolio-title"}>2019</p>
                    </div>
                  </a>
                </div>

                <div
                  id="p-item-4"
                  className={"grid-item element-item p_one_fourth"}
                >
                  <a
                    className={"item-link ajax-portfolio"}
                    href="/gallery"
                    data-id="4"
                  >
                    <img
                      src={require("../../../images/gallery/gallery-tiles/more.png")}
                      alt=""
                    />
                    <div className={"portfolio-text-holder"}>
                      <p className={"portfolio-desc"}>
                        Browse through our time capsule
                      </p>
                      <p className={"portfolio-title"}>All Years</p>
                    </div>
                  </a>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                  paddingTop: "3rem",
                }}
              >
                <br />
                <p>
                  {" "}
                  Do you want to see more images? Browse through our time
                  capsule by clicking on the button below.
                </p>
                <Link to="/gallery" className={"button"}>
                  GALLERY
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Portfolio;
