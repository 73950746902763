import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function NotAShowDog(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        imageName="medals.png"
        title="I want a PET, not a SHOW DOG"
        preQuoteHeadings={[
          "",
          "Show dogs are the best companions",
          "How does that affect me?",
        ]}
        preQuoteBody={[
          <div>
            <em>
              Why do people recommend choosing a breeder who shows their dogs to
              folks who are just looking for a pet and have no interest in
              showing? <br />
              Why should breeding to breed standard be important to a pet home?{" "}
              <br />
              Does it seem excessive, or “snobby”?
            </em>
            <h6 className={"make-green"}>
              <em>It’s not, and here’s why</em>
            </h6>
            <br />
          </div>,

          <div>
            <p>
              Dog shows are a means of evaluating dogs against the breed
              standard, to evaluate soundness, movement/gait, type, and
              temperament.
            </p>
            <br />

            <Row style={{ border: "1px solid black", textAlign: "center" }}>
              <Col style={{ border: "1px solid black" }} xs={12} sm={6}>
                <br />
                <h6>
                  <u>Soundness</u>
                </h6>
                <p>
                  The state of physical and mental health when all organs and
                  faculties are functioning properly, each in its rightful
                  relation to each other.
                </p>
                <br />
              </Col>
              <Col style={{ border: "1px solid black" }} xs={12} sm={6}>
                <br />

                <h6>
                  <u>Type</u>
                </h6>
                <p>
                  Breed type encompasses appearance, character, condition, bone
                  structure, temperament, and movement; "breed type is all these
                  things." Breed type also includes a character specific to each
                  breed, a combination of behavior, temperament and carriage
                  that demonstrate an essence of the breed.
                </p>
                <br />
              </Col>
              <Col style={{ border: "1px solid black" }} xs={12} sm={6}>
                <br />

                <h6>
                  <u>Gait</u>
                </h6>
                <p>
                  The gait of a dog is its quality of movement. You want to see
                  ease of movement, unimpaired by illness or poor structure.
                </p>
                <br />
              </Col>
              <Col style={{ border: "1px solid black" }} xs={12} sm={6}>
                <br />

                <h6>
                  <u>Temperament</u>
                </h6>
                <p>
                  The general attitude a dog has towards other animals and
                  people.
                </p>
                <br />
              </Col>
            </Row>
            <br />
            <br />
          </div>,
          <div>
            <p>
              So. That’s a very basic intro to what goes on at a show...{" "}
              <b>why does this matter</b>? You want a pet, a companion, not a
              show dog, right? Well, you chose a Poodle for a reason. You’ve
              done your research, and have read that they’re great with kids and
              other animals, they’re gentle, not aggressive, they make excellent
              companions and love spending time with their people. That their
              good and kind nature predisposes many Poodles to be excellent
              therapy dogs.{" "}
              <em>
                Good, responsible breeders seek to preserve those definitive and
                positive characteristics.
              </em>
            </p>
            <p>
              <b>What about type and structure? </b>How the dog is put together,
              able to move freely and comfortably? Would you rather buy from
              someone who has proven publicly, over time, that the dog they’re
              breeding can move well, free of limp, or a structural problem? Or
              just trust someone who has no interest in proving their dogs? It’s
              your puppy’s quality of life and comfort (as well as your wallet)
              that’s at stake.{" "}
            </p>
            <p>
              Not every dog in a well-bred litter is going to be show quality
              but there will ALWAYS be pet-quality puppies. Well-bred, but maybe
              with a slight imperfection, and those are the puppies placed in
              pet homes. You don’t have to want a show-quality puppy to get a
              well-bred puppy!{" "}
            </p>
            <p>
              This is not about being snobby, being elitist, thinking that one
              dog is “better” than another, it’s about ensuring you get a puppy
              that acts and looks like the breed you fell in love with. It’s
              about ensuring that all puppies have the best start in life, and
              will grow into a loving family member. It’s about loving our breed
              enough to want to see everything that’s good about them preserved
              for future generations to enjoy.
            </p>
            <br></br>
            <h6 style={{ textAlign: "center" }} className={"make-green"}>
              <em>
                If you want a healthy dog, with a properly sweet temperament,
                choose your breeder.{" "}
              </em>
            </h6>
          </div>,
          <br />,
        ]}
      />
    </React.Fragment>
  );
}

export default NotAShowDog;
