import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Link } from "react-router-dom";
import Services from "../../../components/Home/Services/Services";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";
import image1 from "../../../images/myStatement.png";
import image2 from "../../../images/testings/health4.png";

function NaturalRearings() {
  return (
    <React.Fragment>
      <GenericInfoPage image="" title="Natural Rearing" />
      <GenericInfoPage
        articleLayout="true"
        blockQuoteValue="Natural Rearing is much more than just a diet; it is a holistic approach to life. Simply put… it’s about utilizing the old time, natural ways, essentially caring for your dogs as closely to nature as possible. In fact, Natural Rearing is better for humans too, for our families health, it is environmentally-friendly and also benefits wildlife."
        preQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "500px" }}
              src={image2}
            />
          </div>,
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        postQuoteHeadings={[
          "How it all began",
          "The essence of natural rearing",
          "",
          "",
          "What we do",
          "",
          "",
          "Natural Rearing and Misconceptions",
        ]}
        postQuoteBody={[
          "Juliette de Bairacli Levy coined the phrase 'Natural Rearing'. Around 1930, while studying to be a veterinarian, this intrepid Englishwoman began rearing her Afghan Hounds naturally- feeding raw foods, and treating disease with herbs. For decades her book “The Complete Herbal Handbook For The Dog and Cat” and the formulas therein has been a relied upon reference of owners and breeders around the world.",
          "The very foundation of Natural Rearing is building a strong immune system. This is accomplished by feeding raw meaty bones and organs, fresh air, clean water, minimal stress, appropriate exercise, lots of love; using natural alternatives to chemicals/medication; supporting natural immunity so each dog has the opportunity to live a long life full of vitality; in turn passing this strength to future generations. A dog thus fortified is better able to handle ALL disease from within.",
          "It’s about eliminating inappropriate and toxic substances from every area of life. Avoiding chemical assaults in the garden as well as household cleaners. It’s about NOT putting toxic substances on or in our dogs– this includes flea and tick poisons, routine worming, and over-vaccinating. ",
          "Natural Rearing also involves a zero vaccine protocol relying on the dogs own natural immune responses and healing ability to see it through a disease cycle instead; along with the help of holistic medical help when needed be that herbs, homeopathy, essential oils, or any other holistic modality.",
          "We feed our dogs ONLY raw food and we do not vaccinate our dogs (except as compulsory by law). Science is proving that dogs given no vaccines are far stronger and resistant to disease than those who have had annual vaccinations. Natural Rearing is not a passing fad… it is a lifestyle. Commitment to it will change your dog’s life and your view of the world. Our Miniature Poodles thrive on Natural Rearing!",
          "We do not mean to imply that this is the only way of rearing dogs. However, we do feel it is the optimal method of feeding and rearing which raises and maintains the dogs overall good health and vitality. In turn, the dogs’ immune system can function at prime level.",
          <p>
            As{" "}
            <span>
              <b>Natural Rearing Coaches </b>
            </span>
            we will gladly assist you in learning how to apply the principles of
            NR with your own pets! In the meantime, our{" "}
            <Link to="/danube-health-protocol">
              <b>
                <u>Health Page</u>
              </b>
            </Link>{" "}
            is for your use in investigating Natural Rearing. It lists many
            wonderful sources and files of information devoted to the above
            topics.
          </p>,
          <div>
            <p>Natural rearing does not mean we do not:</p>
            <ul>
              <li>Use conventional medicine when necessary</li>
              <li>Clean our rooms or the spaces that our poodles occupy.</li>
              <li>
                Treat our poodles and puppies if they have fleas, ticks,
                parasites etc.
              </li>
              <li>Consult a veterinarian</li>
            </ul>
          </div>,
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        blockQuoteValue={
          <span>
            He is your friend, your partner, your defender, your dog.
            <br /> You are his life, his love, his leader. <br /> He will be
            yours, faithful and true, to the last beat of his heart.
            <br />
            You owe it to him to be worthy of such devotion.{" "}
          </span>
        }
      />
      <GenericInfoPage
        articleLayout="true"
        preQuoteHeadings={[
          "Aplying all the principles of Natural Rearing",
          "",
          "",
        ]}
        preQuoteBody={[
          <span>
            <b>
              A Brilliant Gem To us Natural Rearing is like a high quality
              diamond to which many precisely cut facets are applied for its
              brilliance to shine outwardly like a fire within.
            </b>
          </span>,
          "The “facets” of Natural Rearing are a species appropriate raw diet, vaccine-free, no chemicals on, in or around the dog and its diamond environment, and the use of natural veterinary treatment (allopathic methods being reserved for trauma injuries, emergency, and diagnostics).",
          "Some feel there is no need to follow all of the Natural Rearing principles; that it is just as beneficial to simply feed their dog a raw food diet, or only minimally vaccinate. There is some truth to this reasoning in the sense that if one weren’t doing even those their dog would be much worse off. However, removing any one principle significantly reduces the overall and long-term beneficial effects. Each and every “facet” must be present for Natural Rearing to brilliantly shine like the beautiful gem it is.",
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        blockQuoteValue={
          <p>
            A feeling of responsibility as stewards of the marvelous Poodle
            breed, and dedication to the health and longevity of future
            generations runs deep within our hearts at Danube Poodles. <br></br>
            As Natural Rearing breeders <u>you have our word</u> that we keep to
            every principle of Natural Rearing so that you may enjoy a
            sparkling, vital companion for many years to come!
            <br></br>
            <br></br>
            <br></br>
          </p>
        }
        postQuoteBody={[
          <iframe
            width="1206"
            height="656"
            src="https://www.youtube.com/embed/jDubyrObwWU"
            title="October 30, 2022"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />
      <br />
      <br />
      <br />
      <Services
        paddingTop="5rem"
        buttonIsAvailable="true"
        bigText={
          <span className={"text-center"}>
            <h6>
              Natural Rearing is about getting back to basics and the nature of
              how our canines were created!
            </h6>{" "}
            <p>
              These are the areas in which we apply natural rearing for our
              poodles:
            </p>
          </span>
        }
        href1="/raw-diet"
        href2="/maintaining-a-healthy-immune-system"
        href3="/vaccinations"
        first={{
          title: "DIET",
          description: (
            <p>
              Feeding a species appropriate diet includes raw meat, organs and
              bones, for emulating prey animals consumed in the wild. To learn
              more about this click on the button below.
            </p>
          ),
          number: "1",
        }}
        second={{
          title: "NON-TOXIC",
          description: (
            <p>
              Limiting exposure to noxious chemicals includes using alternative
              flea and tick medications as well as cleaners that are not
              chemically based. Learn more about this below.
            </p>
          ),
          number: "2",
        }}
        third={{
          title: "VACCINES",
          description: (
            <p>
              Limiting vaccinations leads to stronger dogs, but it's necessary
              to understand the pros and cons of vaccinating. To learn more
              about this topic click on the button below.
            </p>
          ),
          number: "3",
          extraDescription: "",
        }}
      ></Services>
      <Services
        paddingTop="0"
        first={{
          title: "PUPPIES",
          description: (
            <p>
              We let the mothers raise their puppies. We do not take their
              puppies away early and they are weaned when the mother weans them.
              For more information click{" "}
              <a href="https://shoppuppyculture.com/pages/puppy-culture-1">
                <u>HERE</u>.
              </a>
            </p>
          ),
          number: "4",
        }}
        second={{
          title: "CLEANLINESS",
          description:
            "We give out dogs access to clean air, clean leaving conditions, clean water, clean food and clean rooms. They they are not confined to cages or lack of fresh air and natural sunlight",
          number: "5",
        }}
        third={{
          title: "WELLNESS",
          description:
            "Our dogs exercise regularly and we do our best to help them live in a stress-free environment. We also limit their exposure to EMF rays. Last bot not least we believe in epigenetics.",
          number: "6",
          extraDescription: "",
        }}
      ></Services>
      <GenericInfoPage
        title="My statement"
        preQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "550px" }}
              src={image1}
            />
          </div>,
        ]}
        blockQuoteValue={
          <div>
            <h6>As a conscientious breeder I state that:</h6>
            <br />
            <ul>
              <li>
                I am committed to feeding/ rearing my poodles on raw meat and
                bone diet and do not feed processed kibble or canned food as a
                supplement or treats.
              </li>
              <li>I do not vaccinate except where required by state law.</li>
              <li>
                I do not use chemical products in or around my poodles such as
                commercial wormers, flea prevention, floor cleaners,lawn
                pesticides etc.
              </li>
              <li>
                I breed my poodles with care and attention and improvement.
              </li>
              <li>
                I test my breeding program in an objective venue such as
                conformation showing,handler assessment, tests and/or trials,
                etc.
              </li>
              <li>
                I rear the puppies in a human home where they will get a maximum
                of human contact and socialization.
              </li>
              <li>
                I provide health and temperament information on my breed in
                general as well as on my specific poodle or puppies to all
                prospective puppy buyers.
              </li>
              <li>
                <u>
                  I believe in a common sense approach to creating and
                  maintaining vibrant health for companion animals and an
                  unconventional, integrative approach to addressing disease and
                  re-establishing well being in ill pets.
                </u>
              </li>
            </ul>
          </div>
        }
        postQuoteBody={[
          <h6 style={{ textAlign: "center" }}>
            Holistic Health Care means treating the whole body - not just from
            head to toe, but <b>inside and out</b>. It also means treating from
            the full spectrum of medical assistance available. ​<br /> ​<br />{" "}
            <b style={{ color: "#2b7a0b" }}>Education is a key factor. </b>
          </h6>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/ltOjwaNH2E4"
            title="Danube Poodles Life Style"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />
      <SimpleInfoSection
        id="millstones"
        content1={
          <div style={{ color: "#ffffff" }}>
            <h5 style={{ textAlign: "center", paddingTop: "3em" }}>
              {" "}
              Less Common Therapies
            </h5>
            <h6 style={{ textAlign: "center" }}>
              Less Common Modalities, yet so helpful ways to help restore health
              in a sick animal
            </h6>
            <br />
            <br />
            <Row
              style={{
                textAlign: "center",
                border: "2px #2b7a0b solid",
                marginBottom: "50px",
              }}
            >
              <Col>
                <p></p>
                <p>Ozone Therapy -Biophotonic Blood Therapy</p>
                <p>Bemer and other PEMF therapies</p>
                <p>Magnetic Therapy- Pulsating Magnetic Therapy</p>
                <p>Prolozone Therapy</p>
                <p>Craniosacral Therapy</p>
                <p>NAET</p>
                <p>Osteopathy</p>
                <p>IAT ( Immuno -Augmentative Therapy)</p>
              </Col>
              <Col>
                <p></p>
                <p>Electromagnetic Therapy</p>
                <p>Color Therapy</p>
                <p>Stem Cell Therapy</p>
                <p>Platelet Rich Plasma</p>
                <p>Veterinary Medical Manipulation</p>
                <p>Microbiome Restorative Therapy</p>
                <p>Intravenous Vit-C</p>
                <p>Tui-na</p>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        }
      />
      <TextAndTwoButtons
        text={
          <span>
            <h6 style={{ textAlign: "center" }}>
              Want to find out more? Keep reading for more interesting and
              useful information.
            </h6>
            <p style={{ textAlign: "center" }}>
              Or if you think you are ready for a great companion, fill out the{" "}
              <b>puppy application</b> below.
            </p>
          </span>
        }
        button1="Immune System"
        href1="/maintaining-a-healthy-immune-system"
        button2="Puppy application"
        href2="/"
      />
    </React.Fragment>
  );
}

export default NaturalRearings;
