import React from "react";
import Gallery from "../../../components/Gallery/Gallery";
import image1 from "../../../images/about/img_1.jpeg";
import image3 from "../../../images/about/img_6.jpeg";
import image4 from "../../../images/about/img_7.jpeg";
import image5 from "../../../images/about/img_8.jpeg";
import image6 from "../../../images/about/img_9.jpeg";
import image7 from "../../../images/about/img_10.jpeg";
import image8 from "../../../images/about/img_11.jpeg";

import play1 from "../../../images/about/playRoom/img_1.jpg";
import play2 from "../../../images/about/playRoom/img_2.jpg";
import play3 from "../../../images/about/playRoom/img_3.jpg";
import play4 from "../../../images/about/playRoom/img_4.jpg";
import play5 from "../../../images/about/playRoom/img_5.jpg";
import play6 from "../../../images/about/playRoom/img_6.jpg";
import play7 from "../../../images/about/playRoom/img_7.jpg";
import play8 from "../../../images/about/playRoom/img_8.jpg";
import play9 from "../../../images/about/playRoom/img_9.jpg";
import play10 from "../../../images/about/playRoom/img_10.jpg";
import play11 from "../../../images/about/playRoom/img_11.jpg";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function GroomingPage(props) {
  const images = [
    {
      original: image1,
      thumbnail: image1,
    },
    {
      original: image3,
      thumbnail: image3,
    },
    {
      original: image4,
      thumbnail: image4,
    },
    {
      original: image5,
      thumbnail: image5,
    },

    {
      original: image6,
      thumbnail: image6,
    },
    {
      original: image7,
      thumbnail: image7,
    },
    {
      original: image8,
      thumbnail: image8,
    },
  ];

  const playImages = [
    {
      original: play1,
      thumbnail: play1,
    },
    {
      original: play2,
      thumbnail: play2,
    },
    {
      original: play3,
      thumbnail: play4,
    },
    {
      original: play4,
      thumbnail: play4,
    },

    {
      original: play5,
      thumbnail: play5,
    },
    {
      original: play6,
      thumbnail: play6,
    },
    {
      original: play7,
      thumbnail: play7,
    },
    {
      original: play8,
      thumbnail: play8,
    },
    {
      original: play9,
      thumbnail: play9,
    },
    {
      original: play10,
      thumbnail: play10,
    },
    {
      original: play11,
      thumbnail: play11,
    },
  ];
  return (
    <React.Fragment>
      <div style={{ paddingTop: "7rem" }} className={"single single-post"}>
        <h1 className={"entry-title"}>Our Special Poodle Rooms</h1>
      </div>
      <Gallery
        makeGreen="true"
        paddingBottom="50px"
        originalHeight={300}
        title="Our Play / Whelping Rooms"
        images={playImages}
      />
      <Gallery
        makeGreen="true"
        paddingBottom="50px"
        originalHeight={300}
        title="Our Spa/ Grooming Room"
        images={images}
      />
      <TextAndTwoButtons
        text=<span style={{ fontSize: "18px" }}>
          We do the best for our poodles. Our website is our proof of that. Keep
          reading in order to discover more about who we are, what do we stand
          for and what do we do with and for our poodles.
        </span>
        button1="Our About Page"
        button2="Home"
        href2="/"
      />
    </React.Fragment>
  );
}

export default GroomingPage;
