import React from "react";
import ChampionList from "./US/ChampionsList/ChampionList";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import TextAndTwoButtons from "../../components/TextAndTwoButtons/TextAndTwoButtons";
import { Link } from "react-router-dom";

function ChampionsUS(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        imageName="champions/champion_trophies.png"
        preQuoteBody={[
          <div style={{ textAlign: "center" }}>
            <h2>
              Get to know our champion poodles and discover their unique
              personalities by clicking on one of the categories below.
            </h2>
          </div>,
        ]}
        title=<span className="medium-text">Our US Poodle Team</span>
      />

      <div
        style={{
          textAlign: "center",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className="flex-custom-container champions"
      >
        <div className="poodle-sign">
          <br />
          <Link to="females">
            <img src={require(`../../images/champions/females.png`)} />
          </Link>
        </div>
        <div className="poodle-sign">
          <br />
          <Link to="males">
            <img src={require(`../../images/champions/males.png`)} />
          </Link>
        </div>
        <div className="poodle-sign">
          <br />
          <Link to="retired">
            <img src={require(`../../images/champions/retired.png`)} />
          </Link>
        </div>
        <div className="poodle-sign">
          <br />
          <Link to="angels">
            <img src={require(`../../images/champions/angels.png`)} />
          </Link>
        </div>

        <br />
      </div>

      {/* <br />
      <br />
      <div className="flex-custom-container ">
        <h4 className={"entry-title medium-text make-green"}>
          Danube's Females
        </h4>
      </div> */}
      {/* <ChampionList
        notOnlyOne="true"
        name1="Julia"
        image1="images/champions/US/females/julia/img_1.png"
        text1=<span>
          Julia is the most sweetest,gentle girl. Always lays somewhere up high
          to observe and not to miss a thing. She is the thinker...and engages
          in group plays strategically so she can always outsmart the rest of
          the gang. Always in mommy's shadow and she absolutely loves, loves to
          travel and it doesn't matter if its by car, boat or airplane.
        </span>
        name2="Karina"
        image2="images/champions/US/females/karina/img_1.png"
        text2=<span>
          Karina is the girl in charge. Don't challenge her because you will
          loose. She can perfect the "puppy look" just so she can get away with
          anything. She is always on a mission either to figure out why the cat
          is not home yet or if anyone in house eat their food. She is for sure
          the alarm system in the house that you can count on. This girl has
          lots of personality that I have seen pass on to her puppies.
        </span>
      />
      <ChampionList
        notFirst="true"
        name1="Vivi"
        image1="images/champions/US/females/vivi/img_7.png"
        text1=<span>
          Vivi is a love bug! Always in tune with everything and everybody.
          Loves to cuddle and always in mood to play. She learns commands really
          fast and gives her all at all times. She is not a picky eater and she
          wont hold back on kisses that's for sure. Give her a toy and time her,
          because she will have the squawker out in less then a minute.
        </span>
        name2="Shiraz"
        image2="images/champions/US/females/shiraz/img_1.png"
        text2=<span>Danube's Symphony No.9 Ode to Joy</span>
      />
      <div className="flex-custom-container ">
        <h4 className={"entry-title medium-text make-green"}>Danube's Males</h4>
      </div>
      <ChampionList
        notOnlyOne="true"
        name1="Dante"
        image1="images/champions/US/males/img_1.png"
        text1=<span>CH DiMarnique's and Danube's Empower Mint</span>
        name2="Norbi"
        image2="images/champions/US/males/img_2.png"
        text2=<span>Dzholli Star Shine Danube Dreams in Red</span>
      />
      <ChampionList
        notFirst="true"
        name1="Indie"
        image1="images/champions/US/males/img_3.png"
        text1=<span>DiMarnique's and Danube's California Dream'in</span>
        name2="Merlot"
        image2="images/champions/US/males/img_4.png"
        text2=<span>Bonbon Iz Volzhskoy Serenady</span>
      /> */}
      <div></div>
      <TextAndTwoButtons
        title="Learn More"
        text="Check out our Europe Champion Poodle Team by clicking on the second button below. Or go back to the homepage and discover more about us, about our health protocol and natural rearing."
        button1="Europe Champions"
        href1="/champions-eu"
        button2="Home"
        href2="/"
      />
    </React.Fragment>
  );
}

export default ChampionsUS;
