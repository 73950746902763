import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Carousel } from "react-responsive-carousel";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function AngelChampions() {
  const ozzie_array = [...Array(2)].map((e, i) => {
    return (
      <img
        key={i}
        className={"testimonial-images"}
        alt="puppies"
        src={require(`../../../images/champions/US/angels/ozzie/ozzie_${
          i + 1
        }.png`)}
      />
    );
  });

  const julia_array = [...Array(15)].map((e, i) => {
    return (
      <img
        key={i}
        className={"testimonial-images"}
        alt="puppies"
        src={require(`../../../images/champions/US/angels/julia/img_${
          i + 1
        }.png`)}
      />
    );
  });

  const karina_array = [...Array(9)].map((e, i) => {
    return (
      <img
        key={i}
        className={"testimonial-images"}
        alt="puppies"
        src={require(`../../../images/champions/US/angels/karina/img_${
          i + 1
        }.png`)}
      />
    );
  });

  const momo_array = [...Array(1)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/angels/momo/momo.jpeg`)}
      />
    );
  });

  return (
    <>
      <GenericInfoPage
        title="We are extremely grateful for all our Poodle Angels"
        preQuoteHeadings={[
          "Every single one of them gave us so much knowledge and valuable lessons.",
        ]}
        preQuoteBody={[
          "They teached us the true meaning of life and what it is all about. Every Danube Poodle story and accomplishments is paved with their footprints. They left us with legacies to be remembered and valued. We are proud to see them in our current and future generations and will continue to work hard to keep their legacy alive and to inspire. They will continue to live in our hearts and minds forever.",
        ]}
        postQuoteHeadings={[
          "Danube's Romanian Rhapsody No.1 aka Ozzie",
          "Denali's Julia Danube aka Julia",
          "Karina Una Fantasia De Danube aka Karina",
          "Momo",
        ]}
        postQuoteBody={[
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {ozzie_array}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {julia_array}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {karina_array}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={false}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {momo_array}
            </Carousel>
          </>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol or natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>
  );
}

export default AngelChampions;
