import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import { Link } from "react-router-dom";

import PuppyDeposit from "../../../resources/PuppyDeposit.pdf";

function PuppyPlans(props) {
  const createGallery = (n) => {
    let images = [];
    for (let i = 1; i <= n; i++) {
      images.push("../../../images/litter-plan/litter_plan_1.jpg");
    }
    return images;
  };

  const images = createGallery(7);
  console.log(images);

  return (
    <React.Fragment>
      <GenericInfoPage
        title="Our puppies"
        subtitle="Are you looking for your next family member ?"
        imageName="natural_rearer.png"
        preQuoteBody={[
          <div style={{ textAlign: "center" }}>
            <h4>We are planning for an exciting 2025 spring litter!</h4>
            <h6>
              If you want to find out more about our plans or how to get on our
              waiting list please complete a puppy application or email us.
            </h6>
            <br />
            <div className={"button-holder display-button"}>
              <a className={"button"} href="mailto:danubepoodles@yahoo.com">
                → EMAIL US ←
              </a>
              {"   "}
              <a
                className={"button special"}
                href="https://forms.gle/exVrsmmJnFoqYv2x9"
                target="_blank"
                rel="noreferrer"
              >
                Puppy application
              </a>
            </div>
          </div>,
        ]}
      />
      <GenericInfoPage
        articleLayout={true}
        preQuoteHeadings={["Valuable information"]}
        preQuoteBody={[
          "Here you can find all the information you need regarding our puppies, our puppy deposit policy our our past litters.",
          <div style={{ textAlign: "center" }}>
            <Link className={"button"} to={"/past-litters"}>
              See past litters
            </Link>{" "}
            <a
              variant="primary"
              className={"button"}
              href={PuppyDeposit}
              target="_blank"
              rel="noreferrer"
            >
              Puppy deposit policy
            </a>{" "}
            <a
              className={"button"}
              href="https://forms.gle/8yidZHkx4WLbynqVA"
              target="_blank"
              rel="noreferrer"
            >
              Wellness quiz
            </a>{" "}
          </div>,
        ]}
        blockQuoteValue=<p>
          All puppies are sold on a AKC limited registration (no breeding
          rights). We do extensive background checks to ensure integrity of both
          parties. <br />
          Please if you have other cross breeding intentions-- don't waste your
          time here!
        </p>
        postQuoteHeadings={["Aquiring a puppy"]}
        postQuoteBody={[
          <p>
            <b>
              We do not provide transportation and we expect future families to
              be able to drive to Rock Island, TN to pick up the new family
              member. We do not allow for young puppies to be transported via
              airplane due to health issues that can arise.
            </b>{" "}
            <br /> If interested in a Danube Poodle Puppy please complete a
            puppy application first. Like that I would have a base line to start
            to get to know you and what you are looking. Please review my{" "}
            <Link to={"/danube-health-protocol"}>
              <em>
                <u>Health</u>
              </em>{" "}
            </Link>
            page to get familiar with my protocol and some educative videos. I
            will do my best to answer you as fast as I can. <br />
            Thank you kindly.{" "}
          </p>,
          <div style={{ textAlign: "center" }}>
            {" "}
            <Link className={"button"} to={"/not-a-show-dog"}>
              But I want a puppy, not a show dog
            </Link>
          </div>,
          <div className="flex-custom-container">
            {images.map((image, i) => {
              return (
                <img
                  style={{
                    maxWidth: i + 1 !== 7 ? "300px" : "300px",
                    maxHeight: i + 1 !== 7 ? "300px" : "300px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "20px",
                    borderRadius: "15px",
                  }}
                  alt="puppy"
                  src={require(`../../../images/litter-plan/litter_plan_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          </div>,
        ]}
      />
      <SimpleInfoSection
        id="millstones"
        paddingTop="10rem"
        sectionTitle="Dog breeder mantra"
        titleDescription="Dog breeder guidelines"
        title="The words that guide us"
        content1={
          <blockquote style={{ color: "white" }}>
            <p>
              I am a dog breeder. I spend a lifetime learning pedigrees, going
              over dogs, talking, and learning from those in my breed and those
              outside it. I raise each litter as if I gave birth to them and
              spend an equal amount of time finding them loving forever homes. I
              only bring puppies into this world that I think will be the
              healthiest (mentally and physically) and best examples of their
              breed. I support each family who chooses one of my puppies and let
              them know they are now a part of our extended family. I treat them
              all well.
            </p>
            <p>
              I am there if one needs to come back and will aggressively pursue
              the return of one of my dogs if it's in the wrong place. I support
              my breed in rescue and education. I hold them when they arrive and
              leave this world. I share my knowledge and socialize my dogs so
              that they will be the advertisement for my dedication. I don't
              keep track of the money and time I put in to my love of dogs, it
              would not be a true measure of how I feel.
            </p>
            <p>
              The price I charge for my puppies is never profit, but investment
              in the next generation. I am not ashamed of who I am... I work
              hard at being a good dog breeder and encouraging others.
            </p>
            <p>⎯⎯ Author Unknown</p>
            <br />
            <br />
            <br />
            <div style={{ margin: "auto", textAlign: "center" }}>
              <img
                style={{ maxWidth: "90%" }}
                className="image"
                src={require("../../../images/past-litters/mantras/mantra_1.png")}
                alt=""
              />
            </div>
            <br />
            <br />
            <br />
          </blockquote>
        }
      />
      <SimpleInfoSection
        content1=<div style={{ textAlign: "center" }}>
          <div style={{ margin: "auto", textAlign: "center" }}>
            <img
              style={{ maxWidth: "75%" }}
              className="image"
              src={require("../../../images/past-litters/mantras/mantra_2.png")}
              alt=""
            />
          </div>
          <br />

          <h2>
            Learn more by checking out the materials on this page before
            applying for a puppy
          </h2>
          <br />
          <br />
          <Link className="button" to="/books-recommendations">
            {" "}
            Recommended Books{" "}
          </Link>

          <br />
        </div>
      />
    </React.Fragment>
  );
}

export default PuppyPlans;
