import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Carousel } from "react-responsive-carousel";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function FemalesChampions(props) {
  const aida_array = [...Array(13)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/females/aida/img_${
          i + 1
        }.jpg`)}
      />
    );
  });
  const shiraz_array = [...Array(6)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/females/shiraz/img_${
          i + 1
        }.jpg`)}
      />
    );
  });
  const chianti_array = [...Array(12)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/females/chianti/img_${
          i + 1
        }.jpg`)}
      />
    );
  });
  return (
    <>
      <GenericInfoPage
        title="US Female Champions"
        postQuoteHeadings={[
          "FCI CH Bohemian Rhapsody With A Danube Glissando aka Aida",
          "CH Danube's Symphony No.9 Ode To Joy aka Shiraz",
          "CH Danube's Firebird Suite Red Legend aka Chianti",
        ]}
        postQuoteBody={[
          <>
            <p>
              Aida is our “movie star” girl. Beside her looks she is also a very
              sensitive and laid back character. She’s got that 6th sense and
              can connect excellently with kids. When in playful mood she’s got
              the wits to outsmart us and when she’s relaxing she doesn’t skip
              on her beauty sleep.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {aida_array}
            </Carousel>
          </>,
          <>
            <p>
              Shiraz is our two in one doorbell & alarm system, she’s 24/7 on
              lookout for something to bark at. Her hobbies are eating, swimming
              and she always has the last say. She always gets very cheerful
              when we get new guests in the house.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {shiraz_array}
            </Carousel>
          </>,
          <>
            <p>
              Chianti has a very relaxed personality. When her hunter instincts
              kick in she loves chasing birds, rabbits and squirrels. Her tail
              is a giveaway of her mood and she very much likes to cuddle. It’s
              fun to be around her !
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {chianti_array}
            </Carousel>
          </>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol or natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>

    // <React.Fragment>
    //   <ChampionsGallery
    //     dogNames={["Julia", "Karina", "Vivi", "Shiraz"]}
    //     images={[
    //       [...Array(15)].map((e, i) => {
    //         return (
    //           <img
    //             className={"champions-images"}
    //             alt="julia"
    //             src={require(`../../../images/champions/US/females/julia/img_${
    //               i + 1
    //             }.png`)}
    //           />
    //         );
    //       }),
    //       [...Array(9)].map((e, i) => {
    //         return (
    //           <img
    //             className={"champions-images"}
    //             alt="julia"
    //             src={require(`../../../images/champions/US/females/karina/img_${
    //               i + 1
    //             }.png`)}
    //           />
    //         );
    //       }),
    //       [...Array(7)].map((e, i) => {
    //         return (
    //           <img
    //             className={"champions-images"}
    //             alt="julia"
    //             src={require(`../../../images/champions/US/females/vivi/img_${
    //               i + 1
    //             }.png`)}
    //           />
    //         );
    //       }),
    //       [...Array(17)].map((e, i) => {
    //         return (
    //           <img
    //             className={"champions-images"}
    //             alt="julia"
    //             src={require(`../../../images/champions/US/females/shiraz/img_${
    //               i + 1
    //             }.png`)}
    //           />
    //         );
    //       }),
    //     ]}
    //   />
    // </React.Fragment>
  );
}

export default FemalesChampions;
