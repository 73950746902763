import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";

import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={
              <span>
                Dear Timea,
                <br /> Thank you so much for Mona and Izabella. They are
                wonderful dogs, and have already brought so much love and fun
                into our lives. We appreciate you thinking of us, and hope you
                will continue to stay in touch with us and them.
                <br /> Thanks again!
              </span>
            }
            name1="Cathy and Dave"
            images={
              <img
                style={{
                  maxWidth: "500px",
                  maxHeight: "500px",
                  height: "auto",
                  width: "auto",
                }}
                alt="puppies"
                src={require(`../../../images/testimonials/page1/1/img_${1}.jpg`)}
              />
            }
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text={
              <span>
                Dear Timea
                <br /> Thank you so much for our wonderful pup Louie! He has
                brought so much joy to our family... he is "too" cute,
                intelligent and fun lovely ....great boat dog. Our ''poodle''
                experience with you has been top notch from the very start...
                the videos, texts and pictures really enhanced the experience.
                We hope to maintain the relationship as Louie grows into
                adulthood.
                <br />
              </span>
            }
            name1="Jeanne and Frans van"
            name2="Hekken"
            images={[...Array(5)].map((e, i) => {
              return (
                <img
                  style={{
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/2/img_${i}.png`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text="Garth finishing his puppy obedience class ...way to go, boy !!!"
            images={
              <img
                style={{
                  maxHeight: "500px",
                  height: "auto",
                  width: "auto",
                }}
                alt="puppies"
                src={require(`../../../images/testimonials/page1/3/img_${1}.png`)}
              />
            }
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text="Maximus Minimus love to spend time with his mom and dad being spoiled and travel everywhere they go."
            images={[...Array(4)].map((e, i) => {
              return (
                <img
                  style={{
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/4/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first active">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div>2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div>3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div>4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div>5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div>6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
