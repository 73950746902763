import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";
import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={<span>Bogie is enjoying his summer and staying cool.</span>}
            images={[
              <img
                style={{
                  maxWidth: "500px",
                  maxHeight: "500px",
                  height: "auto",
                  width: "auto",
                  marginBottom: "30px",
                  borderRadius: "15px",
                }}
                alt="puppies"
                src={require(`../../../images/testimonials/page1/5/img_1.jpg`)}
              />,
              <img
                style={{
                  maxWidth: "500px",
                  maxHeight: "500px",
                  height: "auto",
                  width: "auto",
                  marginBottom: "30px",
                  borderRadius: "15px",
                }}
                alt="puppies"
                src={require(`../../../images/testimonials/page1/5/img_2.jpeg`)}
              />,
            ]}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text={
              <span>
                Abbie is getting all the attention just by looking pretty.
              </span>
            }
            images={[...Array(6)].map((e, i) => {
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/6/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text="Walter Harvey and Blossom are best BFFs"
            images={[...Array(5)].map((e, i) => {
              let ext = ".jpg";
              if (i === 3) ext = ".png";
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/7/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text="Saban loves his big sister. Now Sophie has a play mate to keep up."
            images={[...Array(4)].map((e, i) => {
              let ext = ".jpg";
              if (i === 1) ext = ".jpeg";
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/8/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div className="active">2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div>3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div>4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div>5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div>6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
