import React from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function TextCards(props) {
  return (
    <div id="textCards" className={"section single"}>
      <div className={"block content-1170 center-relative"}>
        <div
          style={{ marginBottom: props.marginBottom }}
          className={"entry-content"}
        >
          <Row style={{ textAlign: "center", border: "1px #000000 solid" }}>
            {props.titles.map((title, index) => {
              return (
                <Col style={{ marginBottom: "30px" }} xs={12} sm={6}>
                  <div style={{ minHeight: "120px" }}>
                    <h4
                      style={{ color: "#000000", fontWeight: "700" }}
                      className={"entry-title"}
                    >
                      {title}
                    </h4>
                  </div>
                  <div className={"excerpt"}>{props.texts[index]}</div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default TextCards;
