import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2015(props) {
  const folder1 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(11)].map((e, i) => {
    let ext = "jpg";
    if (i === 8) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/5/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder6 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/6/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder7 = [...Array(6)].map((e, i) => {
    let ext = "jpg";
    if (i === 1 || i === 2 || i === 3) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2015"
        src={require(`../../../images/gallery/2015/7/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>
      <div className="flex-custom-container">{folder5}</div>
      <div className="flex-custom-container">{folder6}</div>
      <div className="flex-custom-container">{folder7}</div>;
    </React.Fragment>
  );
}

export default Gallery2015;
