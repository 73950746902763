import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Services(props) {
  return (
    <div id="services" className={"section"}>
      {props.title && (
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>{props.title}</h3>
        </div>
      )}

      <div
        style={{ paddingTop: props.paddingTop }}
        className={"section-wrapper block content-1170 center-relative"}
      >
        {props.bigTitle && (
          <h2
            style={{ color: "white", textAlign: "center" }}
            className={"entry-title member-name"}
          >
            {props.bigTitle}
          </h2>
        )}
        {props.bigText && <p>{props.bigText}</p>}
        <div className={"content-wrapper"}>
          <br />
          {/* <div
            style={{
              textAlign: "center",
              maxWidth: "95%",
              margin: "auto",
              marginBottom: "3rem",
            }}
          >
            <img
              className={"values-image"}
              src={require("../../../images/values.jpg")}
              alt="Values"
            />
          </div> */}
          <div className={`${props.makeFull ? "one" : "one_third"}`}>
            <div
              className={`service-holder ${
                props.valuesImage ? "values" : null
              }`}
            >
              <p className={"service-num"}>{props.first.number}</p>
              <div className={"service-txt"}>
                <div className={"service-txt-info"}>
                  <h4
                    style={{ fontSize: props.smallerHeading ? "36px" : "46px" }}
                  >
                    {props.first.title}
                  </h4>
                  <p>{props.first.description}</p>
                </div>
                <br />
                <div className={"button-holder text-left"}>
                  {props.buttonIsAvailable &&
                    (props.href1 ? (
                      <Link className={"button-dot"} to={props.href1}>
                        <span>MORE</span>
                      </Link>
                    ) : (
                      <HashLink
                        to={"more-details"}
                        elementId={props.first.title.toLowerCase()}
                        className={"button-dot"}
                      >
                        {" "}
                        <span>MORE</span>
                      </HashLink>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`${props.makeFull ? "one" : "one_third"}`}>
            <div
              className={`service-holder ${
                props.valuesImage ? "values" : null
              }`}
            >
              <p className={"service-num"}>{props.second.number}</p>
              <div className={"service-txt"}>
                <div className={"service-txt-info"}>
                  <h4
                    style={{ fontSize: props.smallerHeading ? "36px" : "46px" }}
                  >
                    {props.second.title}
                  </h4>
                  <p>{props.second.description}</p>
                </div>
                <br />
                <div className={"button-holder text-left"}>
                  {props.buttonIsAvailable &&
                    (props.href2 ? (
                      <Link className={"button-dot"} to={props.href2}>
                        <span>MORE</span>
                      </Link>
                    ) : (
                      <HashLink
                        to={"more-details"}
                        elementId={props.first.title.toLowerCase()}
                        className={"button-dot"}
                      >
                        {" "}
                        <span>MORE</span>
                      </HashLink>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`${props.makeFull ? "one" : "one_third"} last`}>
            <div
              className={`service-holder ${
                props.valuesImage ? "values" : null
              }`}
            >
              <p className={"service-num"}>{props.third.number}</p>
              <div className={"service-txt"}>
                <div className={"service-txt-info"}>
                  <h4
                    style={{ fontSize: props.smallerHeading ? "36px" : "46px" }}
                  >
                    {props.third.title}
                  </h4>
                  <p>{props.third.description}</p>
                </div>
                <br />
                <div className={"button-holder text-left"}>
                  {props.buttonIsAvailable &&
                    (props.href3 ? (
                      <Link className={"button-dot"} to={props.href3}>
                        <span>MORE</span>
                      </Link>
                    ) : (
                      <HashLink
                        to={"more-details"}
                        elementId={props.first.title.toLowerCase()}
                        className={"button-dot"}
                      >
                        {" "}
                        <span>MORE</span>
                      </HashLink>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={"clear"}></div>
          {props.ackImage && (
            <div style={{ display: "flex", marginBottom: "5rem" }}>
              <img
                className="image"
                style={{ margin: "auto", maxHeight: "550px", maxWidth: "90%" }}
                src={props.image1}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Services;
