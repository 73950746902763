import React, { useRef, useMemo } from "react";
import { HashLink } from "react-router-hash-link";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function ChampionsGallery(props) {
  let females = true;
  if (props.dogNames[0] !== "Julia") females = false;
  return (
    <React.Fragment>
      <div style={{ marginBottom: "4rem" }} className={"content-wrapper"}>
        <div>
          <div className={"champions-buttons-container"}>
            {props.dogNames.map((dog, i) => {
              return (
                <HashLink to={""} className={"button"} elementId={dog}>
                  <span>{dog}</span>
                </HashLink>
              );
            })}
          </div>
          {props.images.map((_, i) => {
            let paddingTop = "3rem";
            let paddingBottom = "3rem";
            if (i === 0) {
              paddingTop = "4rem";
            } else if (i === props.images.length - 1) {
              paddingBottom = "7rem";
            }

            return (
              <div
                style={{
                  paddingBottom: paddingBottom,
                  paddingTop: paddingTop,
                  width: "100%",
                }}
                className={"text-slider-wrapper relative"}
              >
                <h1
                  id={props.dogNames[i]}
                  style={{ color: "white", textAlign: "center" }}
                  className={"medium-text entry-title"}
                >
                  {props.dogNames[i]}
                </h1>
                <div className={"text-slide"}>
                  <p className={"text-slide-content"}>{props.text}</p>
                </div>
                <div className="flex-custom-container">{props.images[i]}</div>

                <div className={"clear"}></div>
              </div>
            );
          })}
          <div
            style={{ paddingTop: "0", paddingBottom: "7rem" }}
            className={"champions-buttons-container"}
          >
            {props.dogNames.map((dog, i) => {
              return (
                <HashLink to={""} className={"button"} elementId={dog}>
                  <span>{dog}</span>
                </HashLink>
              );
            })}
          </div>
        </div>
      </div>
      <TextAndTwoButtons
        title="Learn more"
        text={`Did you enjoy the images? Check out our ${
          females ? "males" : "females"
        } champions gallery here or go back to the previous page to meet all our champions.`}
        button1="Go back to the main champions page"
        href1={"/champions-us"}
        button2={`See the ${females ? "males" : "females"} gallery`}
        href2={`/champions-us/${females ? "males" : "females"}`}
      />
    </React.Fragment>
  );
}

export default ChampionsGallery;
