import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Carousel } from "react-responsive-carousel";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function MalesChampions(props) {
  const dante_array = [...Array(4)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/males/dante/img_${
          i + 1
        }.png`)}
      />
    );
  });
  const norbi_array = [...Array(9)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/males/norbi/img_${
          i + 1
        }.png`)}
      />
    );
  });
  const baldwin_array = [...Array(2)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/US/males/baldwin/img_${
          i + 1
        }.jpeg`)}
      />
    );
  });
  return (
    <>
      <GenericInfoPage
        title="US Male Champions"
        postQuoteHeadings={[
          "CH Dimarnique's And Danube's Empower Mint aka Dante",
          "FCI Ch Dzholli Star Shine Danube Dreams In Red aka Norbi",
          "FCI Ch Baldwin Grand Sound On Danube Glissando aka Baldwin",
        ]}
        postQuoteBody={[
          <>
            <p>
              This boy can steal your heart with his eyes! He is a very smart
              and calculated boy. When you talk to him you can tell he
              understands everything. Very balanced personality and a perfect
              leg movement. We call him “the little perfect robot” because his
              trotting is perfect all the time. He is such a mama’s boy. In the
              morning he loves to play peekaboo games. When it’s snuggle time he
              will give you a full facial wash with focus on eye area. He's a
              generous guy!
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {dante_array}
            </Carousel>
          </>,
          <>
            <p>
              He’s quite the character! He loves being outdoors and he loves
              playing fetch. When it’s nice and sunny outside he loves to
              sunbathe with a big smile on his face. He's the only one that
              mastered barking wile having a toy in his mouth and he never goes
              to sleep without taking along his favorite toy. We are delighted
              to see his genes passed on in several generations.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {norbi_array}
            </Carousel>
          </>,
          <>
            <p>
              We've all met a person or a child that is always smiling and up
              for jokes, adventures, fun. That’s our Baldwin, always happy, up
              for shenanigans, doesn’t keep grudges and lives in the moment. He
              lights up the room and he loves to run. He is quite the funny
              uncoordinated acrobat. We love him to pieces and looking forward
              to see him pass on his genetics with that wonderful temperament
              and personality.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {baldwin_array}
            </Carousel>
          </>,
        ]}
      />

      <GenericInfoPage
        articleLayout={true}
        preQuoteHeadings={["Video Gallery"]}
        preQuoteBody={[
          <iframe
            width="1206"
            height="695"
            src="https://www.youtube.com/embed/UkFkGR52Z-0"
            title="Baldwin being himself"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="440"
            height="783"
            src="https://www.youtube.com/embed/7VBTPh7jmAU"
            title="Baldwin being himself 🤣🐩"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="695"
            src="https://www.youtube.com/embed/imOo2AhUzfI"
            title="Brothers Misha & Baldwin ￼"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />
      <br />

      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol or natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>
  );
}

export default MalesChampions;
