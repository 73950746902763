import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import PoisonousPlants from "../../../resources/Poisonouse-Plant-Guide.pdf";

function GettingReady(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Getting ready to own a poodle"
        preQuoteBody={[
          <p>
            <em className="make-green">
              For my Danube Poodle Extended Family I have an extensive e-book to
              help out with your new puppy ! (Ask me about it !)
            </em>
          </p>,
        ]}
        postQuoteHeadings={[<b>Be prepared for any emergencies</b>]}
        postQuoteBody={[
          <div>
            <h6 style={{ textAlign: "center" }}>
              Always a good idea to have on hand a magnetic reminder on your
              refrigerator with emergency phone numbers:
            </h6>
            <div className={"single-post-featured-image"}>
              <img
                alt="Magnet with emergency numbers"
                src={require("../../../images/magnetic.png")}
                style={{ borderRadius: "15px" }}
              ></img>
            </div>
            <Row style={{ textAlign: "center" }}>
              <Col sm={6} xs={12}>
                <h6>
                  ....or a wallet card (could include microchip number,rabies
                  tag nr, poison control nr etc)
                </h6>
                <div className={"single-post-featured-image"}>
                  <img
                    alt="Emergency card"
                    src={require("../../../images/safety-card.png")}
                    style={{ borderRadius: "15px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={6} xs={12}>
                <h6>...window sticker</h6>
                <br />
                <br />
                <div className={"single-post-featured-image"}>
                  <img
                    alt="Emergency window sticker"
                    src={require("../../../images/window-sticker.png")}
                    style={{ borderRadius: "15px", maxHeight: "260px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={6} xs={12}>
                <h6>
                  ...an emergency first aid kit, one in your house and maybe one
                  in your car.
                </h6>
                <div className={"single-post-featured-image"}>
                  <img
                    alt="Emergency card"
                    src={require("../../../images/emergency-kit.png")}
                    style={{ borderRadius: "15px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={6} xs={12}>
                <h6>
                  .... get yourself familiar with how to provide care for
                  emergency situations.
                </h6>

                <div className={"single-post-featured-image"}>
                  <img
                    alt="Emergency window sticker"
                    src={require("../../../images/dummies.png")}
                    style={{ borderRadius: "15px", maxHeight: "575px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12}>
                <h6>
                  CPR - You can definitely do it! Click{" "}
                  <a href="https://www.youtube.com/watch?v=0AFrUiRIeVo">
                    <u>
                      <b>HERE</b>
                    </u>
                  </a>
                </h6>

                <div>
                  <br />
                  <img
                    alt="CPR guide"
                    src={require("../../../images/CPR-guide.png")}
                    style={{ borderRadius: "15px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12}>
                <br />
                <h6>Dangerous foods for your dog</h6>
                <div>
                  <br />
                  <img
                    alt="Poisonous foods for dogs"
                    src={require("../../../images/dangerous-foods.png")}
                    style={{ borderRadius: "15px", maxHeight: "800px" }}
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12}>
                <br />
                <h6>
                  Poisonous plants - learn more{" "}
                  {/*! To Do: change the link for actual website */}
                  <a href={PoisonousPlants}>
                    <u>HERE</u>
                  </a>
                </h6>
                <div>
                  <br />
                  <img
                    alt="Poisonous foods for dogs"
                    src={require("../../../images/poisonous.png")}
                    style={{ borderRadius: "15px", maxHeight: "400px" }}
                  ></img>
                </div>
              </Col>
            </Row>
            <br />
            <h3
              style={{
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <em className="make-green">
                Always ... better safe than sorry !
              </em>
            </h3>
          </div>,
        ]}
      />
    </React.Fragment>
  );
}

export default GettingReady;
