import React, { Component } from "react";

class News extends Component {
  render() {
    return (
      <div id="news" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>NEWS</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <div
              className={
                "blog-holder-scode latest-posts-scode block center-relative"
              }
            >
              <article className={"relative blog-item-holder-scode"}>
                <div className={"post-thumbnail"}>
                  <a href="single.html">
                    <img
                      className={
                        "latest-posts-background-featured-image-holder"
                      }
                      src={require("./blog_img_01.jpg")}
                    />
                  </a>
                </div>
                <h4 className={"entry-title"}>
                  <a href="single.html">How to write rogue mote of dust love</a>
                </h4>
                <div className={"excerpt"}>
                  Bearable only through love hydrogen atoms bits of moving fluff
                  culture shores of the cosmic ocean paroxysm of global death
                  ...
                </div>
              </article>

              <article className={"relative blog-item-holder-scode"}>
                <div className={"post-thumbnail"}>
                  <a href="single.html">
                    <img
                      className={
                        "latest-posts-background-featured-image-holder"
                      }
                      src={require("./blog_img_02.jpg")}
                    />
                  </a>
                </div>
                <h4 className={"entry-title"}>
                  <a href="single.html">Epochs encyclopa galactica stellar</a>
                </h4>
                <div className={"excerpt"}>
                  Bearable only through love hydrogen atoms bits of moving fluff
                  culture shores of the cosmic ocean paroxysm of global death
                  ...
                </div>
              </article>

              <article className={"relative blog-item-holder-scode"}>
                <div className={"post-thumbnail"}>
                  <a href="single.html">
                    <img
                      className={
                        "latest-posts-background-featured-image-holder"
                      }
                      src={require("./blog_img_03.jpg")}
                    />
                  </a>
                </div>
                <h4 className={"entry-title"}>
                  <a href="single.html">How to write rogue mote of dust love</a>
                </h4>
                <div className={"excerpt"}>
                  Bearable only through love hydrogen atoms bits of moving fluff
                  culture shores of the cosmic ocean paroxysm of global death
                  ...
                </div>
              </article>
            </div>
            <br />
            <div className={"button-holder center-text"}>
              <a href="blog.html" className={"button"}>
                {" "}
                GO TO BLOG{" "}
              </a>
            </div>
          </div>
          <div className={"clear"}></div>
        </div>
      </div>
    );
  }
}

export default News;
