function KeyFeatures(props) {
  return (
    <div id="millstones" className={"section"}>
      <div className={"page-title-holder"}>
        <h3 className={"entry-title"}>{props.title}</h3>
      </div>
      <div className={"section-wrapper block content-1170 center-relative"}>
        <div className={"content-wrapper"}>
          <ul className={"milestones-holder"}>
            {props.keys.map((oneKey) => {
              return (
                <li className={"milestone"}>
                  <div className={"milestone-info-left"}>
                    <p className={"milestone-num"}>{oneKey.impact}</p>
                  </div>
                  <div className={"milestone-info-right"}>
                    <h5>{oneKey.title}</h5>
                    <p className={"milestone-text"}>{oneKey.text}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default KeyFeatures;
