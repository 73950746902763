import React, { Component } from "react";

class Skills extends Component {
  render() {
    return (
      <div id="skills" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>SKILLS</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <div className={"one_half"}>
              <p className={"title-description-up"}>OUR NUMBERS</p>
              <h2 className={"entry-title medium-text"}>
                Check our <br />
                PERFORMACES
              </h2>
              <p>
                Code the energy hidden in matter citizens of distant epochs from
                which we spring drake equation perga inconspicuous motespatch
                clean designed code and energy matter.
              </p>
              <br />
              <div className={"button-holder text-left"}>
                <a href="#portfolio" className={"button"}>
                  LEARN MORE
                </a>
              </div>
            </div>

            <div className={"one_half last"}>
              <div className={"skills-holder"}>
                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>75%</div>
                  <div className={"skill-text"}>
                    <span>Performace Z</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"75%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>45%</div>
                  <div className={"skill-text"}>
                    <span>Performace A</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"45%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>90%</div>
                  <div className={"skill-text"}>
                    <span>Performace B</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"90%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>65%</div>
                  <div className={"skill-text"}>
                    <span>Performace C</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"65%"}></div>
                    </div>
                  </div>
                </div>

                <div className={"skill-holder"}>
                  <div className={"skill-percent"}>85%</div>
                  <div className={"skill-text"}>
                    <span>Performace D</span>
                    <div className={"skill"}>
                      <div className={"skill-fill"} data-fill={"85%"}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"clear"}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
