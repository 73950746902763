import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import ScrollBottomButton from "../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../components/ScrollButton/ScrollTopButton";
import TextAndTwoButtons from "../../components/TextAndTwoButtons/TextAndTwoButtons";
import { Link } from "react-router-dom";

function ChampionsEU(props) {
  const bobImages = [...Array(11)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/bob/img_${i + 1}.jpg`)}
      />
    );
  });

  const ariaAndMayaImages1 = [...Array(6)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/carousel1/img_${i + 1}.png`)}
      />
    );
  });
  const ariaAndMayaImages2 = [...Array(14)].map((e, i) => {
    let ext = "png";
    if (i === 13) ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/carousel2/img_${i + 1}.${ext}`)}
      />
    );
  });

  const aria = [...Array(5)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/aria/img_${i + 2}.jpg`)}
      />
    );
  });

  const maya1 = [...Array(7)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/maya/lugoj/img_${i}.jpg`)}
      />
    );
  });
  const maya2 = [...Array(5)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/maya/us/img_${i + 1}.jpg`)}
      />
    );
  });
  const maya3 = [...Array(12)].map((e, i) => {
    let ext = "jpg";
    if (i === 11) ext = "png";
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "500px" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/maya/img_${i + 1}.${ext}`)}
      />
    );
  });
  const flying = [...Array(4)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../images/champions/EU/flying/img_${i}.jpg`)}
      />
    );
  });

  return (
    <React.Fragment>
      <GenericInfoPage
        imageName="champions/champion_trophies.png"
        preQuoteBody={[
          <div style={{ textAlign: "center" }}>
            <h2>
              Get to know our champion poodles and discover their unique
              personalities by clicking on one of the categories below.
            </h2>
          </div>,
        ]}
        title=<span className="medium-text">Our EU Poodle Team</span>
      />
      <div
        style={{
          textAlign: "center",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className="flex-custom-container champions"
      >
        <div className="poodle-sign">
          <br />
          <Link to="females">
            <img src={require(`../../images/champions/females.png`)} />
          </Link>
        </div>
        <div className="poodle-sign">
          <br />
          <Link to="retired">
            <img src={require(`../../images/champions/retired.png`)} />
          </Link>
        </div>

        <br />
      </div>
      {/* <GenericInfoPage
        title=<span className="medium-text">Our EU champion dogs</span>
        preQuoteHeadings={[
          <span className={"make-green"}>
            <b>BOB at the Poodle Specialty in Hungary ~ 05.13.18 ~</b>
          </span>,
          <span className={"make-green"}>
            <b>Aria and Maya</b>
          </span>,
          <span className={"make-green"}>
            <b>Ansvel Aria Nesum Dorma to Danube aka ARIA</b>
          </span>,
          <span className={"make-green"}>
            <b>Danube's Hungarian Rhapsody No 5 aka MAYA</b>
          </span>,
          <span className={"make-green"}>
            <b>Travelling</b>
          </span>,
        ]}
        preQuoteBody={[
          <div className="flex-custom-container">{bobImages}</div>,
          <div className="flex-custom-container">
            {ariaAndMayaImages1}
            {ariaAndMayaImages2}
          </div>,
          <div className="flex-custom-container">{aria}</div>,
          <div className="flex-custom-container">
            {maya1}
            {maya2}
            {maya3}
          </div>,
          <div className="flex-custom-container">{flying}</div>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span>
          Click on the first button below to see more about Autumn and Maya or
          check out our <em>But I want a pet not a show dog </em>page to
          understand why our poodles are the best companions.
        </span>
        button1="Autumn and Maya"
        href1="autumn-and-maya"
        button2="But I want a pet not a show dog"
        href2="/not-a-show-dog"
      /> */}
      <TextAndTwoButtons
        title="Learn More"
        text="Check out our United States Champion Poodle Team by clicking on the second button below. Or go back to the homepage and discover more about us, about our health protocol and natural rearing."
        button1="US Champions"
        href1="/champions-us"
        button2="Home"
        href2="/"
      />
      ;
    </React.Fragment>
  );
}

export default ChampionsEU;
