import React from "react";

function Main(props) {
  const folder1 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    if (i === 0) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(1)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(9)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/5/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder6 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/6/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder7 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/7/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder8 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    if (i === 3 || i === 4) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/8/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder9 = [...Array(3)].map((e, i) => {
    let ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/9/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder10 = [...Array(3)].map((e, i) => {
    let ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/10/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder11 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    if (i === 0 || i === 2) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="main"
        src={require(`../../../images/gallery/main/11/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>
      <div className="flex-custom-container">{folder5}</div>,
      <div className="flex-custom-container">{folder6}</div>,
      <div className="flex-custom-container">{folder7}</div>
      <div className="flex-custom-container">{folder8}</div>
      <div className="flex-custom-container">{folder9}</div>
      <div className="flex-custom-container">{folder10}</div>
      <div className="flex-custom-container">{folder11}</div>,
    </React.Fragment>
  );
}

export default Main;
