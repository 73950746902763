import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2011(props) {
  const folder1 = [...Array(8)].map((e, i) => {
    let ext = "jpg";
    if (i !== 4) {
      return (
        <img
          style={{ border: "1px solid black" }}
          className={"champions-images"}
          alt="2009"
          src={require(`../../../images/gallery/2011/img_${i + 1}.${ext}`)}
        />
      );
    }
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>, ;
    </React.Fragment>
  );
}

export default Gallery2011;
