import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeSection extends Component {
  render() {
    return (
      <div id="home" className={"section no-page-title"}>
        <div id="home-overlay"></div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div style={{ textAlign: "center" }} className={"content-wrapper"}>
            {/* <img
              className={"home-section-image"}
              alt="logo"
              src={require("../../../images/logo-transparent.png")}
            /> */}
            <div id="filler-div" />
            <h1
              style={{ marginBottom: 0, fontWeight: "600" }}
              className={"big-text"}
            >
              Danube Poodles
            </h1>
            <h3 style={{ color: "white" }} className={"big-text"}>
              Changing The Future Of Poodles One Puppy At A Time
            </h3>
            <br />
            <div className={"button-holder display-button"}>
              <Link className={"button"} to="/danube-health-protocol">
                LEARN MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSection;
