import React, { Component } from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
class Contact extends Component {
  render() {
    return (
      <div id="contact" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>CONTACT</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <div className={"one_half"}>
              <p className={"title-description-up"}>CONTACT US</p>
              <h2 className={"entry-title medium-text"}>
                We would love <br />
                to hear from you!
              </h2>
              <p>
                Please fill out the form and we will get back to you as quickly
                as we can. Also you can check out our social media pages, where
                we constantly share updates, valuable informations and
                insights.
              </p>
              <br />
              <div className={"social"}>
                <a
                  href="https://www.facebook.com/danubepoodles"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
              </div>

              <div className={"social"}>
                <a href="#" target="_blank" rel="noreferrer">
                  <FaInstagram />
                </a>
              </div>
            </div>

            <div className={"one_half last"}>
              <div className={"contact-form"}>
                <p>
                  <input
                    id="name"
                    type="text"
                    name="your-name"
                    placeholder="Name"
                  />
                </p>
                <p>
                  <input
                    id="contact-email"
                    type="email"
                    name="your-email"
                    placeholder="Email"
                  />
                </p>
                <p>
                  <input
                    id="subject"
                    type="text"
                    name="your-subject"
                    placeholder="Subject"
                  />
                </p>
                <p>
                  <textarea
                    id="message"
                    name="your-message"
                    placeholder="Message"
                  ></textarea>
                </p>
                <p className={"contact-submit-holder"}>
                  <input type="submit" value="SEND" />
                </p>
              </div>
            </div>
            <div className={"clear"}></div>

            <p>
              <iframe
                title="Where you can find us"
                src="https://maps.google.com/maps?q=Rock%20Island,%20TN,%2038581&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="600"
                height="450"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
