import GenericInfoSection from "../SimpleInfoSection/SimpleInfoSection";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function TextAndTwoButtons(props) {
  const navigate = useNavigate();

  return (
    <GenericInfoSection
      content1={
        <React.Fragment>
          <h5
            style={{
              textAlign: props.alignment ? props.alignment : "center",
              color: props.id === "millstones" ? "white" : "black",
            }}
            className={"entry-title medium-text"}
          >
            {props.title}
          </h5>

          <div
            style={{ textAlign: props.alignment ? props.alignment : "center" }}
          >
            {props.text}
          </div>

          <br />

          <div
            style={{ textAlign: props.alignment ? props.alignment : "center" }}
            className={"button-holder text-left"}
          >
            {props.href1 ? (
              <Link className={"button"} to={props.href1}>
                {props.button1}
              </Link>
            ) : (
              <a className={"button"} onClick={() => navigate(-1)}>
                {props.button1}
              </a>
            )}
            {"  "}
            <Link className={"button"} to={props.href2}>
              {props.button2}
            </Link>
          </div>
          <br />
          <br />
        </React.Fragment>
      }
    />
  );
}

export default TextAndTwoButtons;
