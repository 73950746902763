import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Link } from "react-router-dom";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";
import image1 from "../../../images/testings/health5.png";

function ImmuneSystem() {
  return (
    <React.Fragment>
      <GenericInfoPage
        title=<p className={"make-green"}>
          Maintaining a Healthy Immune System
        </p>
        imageGallery={[
          "immuneSystem/1.jpeg",
          "immuneSystem/2.jpeg",
          "immuneSystem/3.jpeg",
          "immuneSystem/4.jpeg",
        ]}
        blockQuoteValue=<p>
          How does one expect their companion's immune system to be properly
          maintained? <br />
          Not only do you put faith in what your companion's immune system can
          naturally do but you also help support it being your companion's care
          giver.
        </p>
      />
      <GenericInfoPage
        articleLayout="true"
        title=<p className={"make-green"}>
          Ten ways to naturally maintain a healthy immune system in your dog
        </p>
        postQuoteHeadings={[
          "",
          "1. Household cleaning products, soaps and air fresheners",
          "2. Sanitation",
          "3. Toxins",
          "4. Other medications",
          "5. Vaccinations",
          "6. Species appropriate diet",
          "7. Reduce stress",
          "8. Exercise and weight control",
          "9. Antioxidants",
          "10. Herbs and nutraceuticals",
        ]}
        postQuoteBody={[
          <div style={{ display: "flex", marginBottom: "5rem" }}>
            <img
              className="image"
              style={{ margin: "auto", maxHeight: "400px" }}
              src={image1}
            />
          </div>,
          <span>
            <p>
              Along with sanitation comes products used to keep your home fresh
              and clean.Many cleaning produces, soaps and air fresheners contain
              various harmful toxins like bleach and ammonia which can be
              ingested, absorbed, or breathed in by your companion, easily
              harming your companion, easily harming your sensitive dog. Many
              will treat their yard for weed and pests that are also full of
              chemicals, hard metals and other toxins.They can be tracked in on
              your shoes or if your companion is supervised outside, they can be
              ingested, absorbed , or breathed in by your companion as well.
            </p>
            <p>
              Cleaning and treating your home inside and out with natural
              plant-based products are the best war to avoid harmful chemicals
              and other toxins coming in contact with your companion animal that
              could affect how their body functions including the immune system.
            </p>
          </span>,
          "While we should not allow our companions to live in a bubble, one thing that really improve disease control is certainly sanitation.Clean yards and picking up after your dog , food and water bowls and generally a maintained living environment can really prevent a lot of illness and disease.",
          <span>
            <p>
              Be aware of other toxins your companions could encounter such as
              those found in chemical flea, tick, parasites and worm products
              and home/yard treatments.Our companions are much smaller and much
              closer to the ground than we are and unlike humans they lick
              themselves ingesting many of these products directly from their
              coat and paws.
            </p>
            <p>
              In addition to ingestion, these products like flea and tick
              medications are absorbed into your animal putting stress on the
              immune system and detoxification organs like the kidney and liver.
              Deworming and parasite products often harm the gastrointestinal
              tract.There are many natural products that can be used to prevent,
              treat and control fleas, ticks, worms and parasites.
            </p>
          </span>,
          "This also applies to pharmaceutical  medications like antibiotics, steroids, antihistamines and more. While these may be required in extreme situations, overuse or use when it isn't necessary can cause damage to your companion, resulting in more serious health concerns.The best thing you can do is continue to support a healthy immune system, use proactive health care, attempt to determine the root of the problem and treat with natural methods first before resorting to pharmaceutical drugs.",
          <p>
            As discusses in all our{" "}
            <u>
              <strong>
                <Link to={"/vaccinations"}>vaccine related posts</Link>
              </strong>
            </u>
            , vaccines are a huge stressors on the body including the immune
            system.They are associated with various side affects including
            chronic disease, cancer, kidney disease, and death. They contain
            harmful chemicals, adjutants and other ingredients that contribute
            to various illness and disease and do not support the immune system
            as they are intended. To learn more please view the various posts on
            the topic which includes a plethora of scientific resources and
            studies.
          </p>,
          <span>
            <p>
              This is one of the easiest things you can do for your companion.
              Not only are commercial kibble and canned foods highly processed
              they contain toxins, chemical preservatives , dyes , texturizers,
              ingredients like carbohydrates and grains that not only impair the
              immune system but are just not appropriate for an obligate
              carnivore. Just like humans a raw whole food diet is the best way
              to maintain a strong immune system.
            </p>
            <p>
              Diet is one of the most important components of a healthy immune
              system because the gastrointestinal tract is the prime location
              for immunity. The nutrients your companion ingests not only helps
              your companion thrive but also the beneficial bacteria and enzymes
              will aid the immune system.
            </p>
          </span>,
          "Stress ultimately hampers the immune system.This can include territorial issues among animals, unsettling noise, pregnancy, nursing, veterinary trips, illness, disease , surgery, moving, the birth of a new baby and much more, by reducing stress or keeping it at a minimum your companions body can focus on other things versus trying to cope with things that may not be in their control or otherwise are compromising their immune system.",
          "Not only does exercise reduce stress it strengthens out immune system and keeps our weight ar a controlled level.It is a well known fact that being overweight greatly increases an animals chances of disease and infections as well as stressors the body and its immune system. Dogs can be walked on leash, run or hike along side , play fetch , swim, are all great options for getting that blood pumping.",
          "Antioxidants are responsible for dealing with oxidative damage and destroying free radicals they boost the immune system and have been found to prevent infections, cancer and much more. Many whole foods contain antioxidants. Antioxidants contains vitamins A, C, E that can easily be supplied with a raw species appropriate diet witch includes a plethora of  scientific researches and science.",
          "There are so many herbs and natural foods that have wonderful medicinal benefits.",
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        title=<p className={"make-green"}>About antibiotics</p>
        preQuoteHeadings={["Overview", "What should you do"]}
        preQuoteBody={[
          <span>
            <p>
              Antibiotics work by affecting bacteria cells in several ways.
              Beta-lactam ( penicilin) kill bacteria by interfering with the
              process they use to build cell walls. Macrolide antibiotics (
              erythromycin) block bacterial specific ribisomes and prevent them
              from building proteins. Since proteins do all the cell's work, a
              bacterium that cannot build proteins cannot survive.Quinolone
              antibiotics ( ciprofloxacin/levofloxacin) work by causing bacteria
              DNA strands to break and then prevents the breaks from being
              repaired.
            </p>
            <p>
              The good news is that antibiotics don't affect human cells because
              although there are similarities between human and bacterial cells
              there are also differences.The bad news is that antibiotics will
              harm your dog's beneficial bacteria. These friendly bacteria play
              a role in digestion by producing enzymes and some vitamins, and
              also immune activity by preventing opportunistic pathogenic
              bacteria from multiplying. For example, colostidium bacteria are
              part of the internal environment and do not normally harm healthy
              animals.However,when antibiotics kill to many friendly bacteria in
              the intestine, this bacteria multiplies and produces toxins that
              can make your dog sick with symptoms like fever, nausea and
              diarrhea.
            </p>
            <p></p>
          </span>,
          <p>
            <b>Use antibiotics only when necessary!</b> Taking antibiotics "
            just in case " or ineffectively against a virus can hurt your dog by
            damaging colonies of beneficial microbes.Damaging these important
            "resident" bacteria can lead to their replacement with opportunistic
            bacteria that can lead to underlying health issues in your dog that
            last their lifetime.So instead of "killing" think "building"by
            strengthening your animal's immune system and replenishing
            probiotics on a daily basis. If necessary, provide a safer, less
            damaging alternative to antibiotics.
          </p>,
        ]}
      />
      <br />
      <br />
      <TextAndTwoButtons
        text={
          <span>
            <h1>Want to find out more?</h1>
            <p>
              Keep reading for more interesting and useful information. Or if
              you think you are ready for a great companion, fill out the{" "}
              <b>puppy application</b> below.
            </p>
          </span>
        }
        button1="Health Protocol"
        href1="/danube-health-protocol"
        button2="Puppy application"
        href2="/"
      />
    </React.Fragment>
  );
}

export default ImmuneSystem;
