import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import Contact from "../../components/ContactSection/ContactSection";

function ContactPage(props) {
  return (
    <React.Fragment>
      <div
        style={{ textAlign: "center", paddingTop: "7rem" }}
        className="section"
      >
        <div className={"block content-1170 center-relative"}>
          <h1 className={"medium-text"}>Get in touch</h1>
        </div>
      </div>

      <Contact
        separatePage="true"
        titleDescription="CONTACT"
        bigTitle="We would love to hear from you!"
        description=<div>
          Please fill out the form and we will get back to you as quickly as we
          can. Also you can check out our social media pages, where we
          constantly share updates, valuable informations and insights.
        </div>
        facebook="https://www.facebook.com/danubepoodles"
        instagram="https://www.instagram.com/danubepoodles/"
      />
      <br />
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

export default ContactPage;
