import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2020(props) {
  const folder1 = [...Array(1)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2020"
        src={require(`../../../images/gallery/2020/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(6)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2020"
        src={require(`../../../images/gallery/2020/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2020"
        src={require(`../../../images/gallery/2020/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(8)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2020"
        src={require(`../../../images/gallery/2020/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>;
    </React.Fragment>
  );
}

export default Gallery2020;
