import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { send } from "emailjs-com";
import axios from "axios";

import {
  FaFacebookF,
  FaInstagram,
  FaUserAlt,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";

function ContactSection(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [visitors, setVistors] = useState(10829);
  const currentURL = window.location.pathname;

  useEffect(() => {
    console.log("in use effect");
    let timer = setTimeout(() => setMessage(null), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  useEffect(() => {
    console.log("in use effect counter");
    if (currentURL === "/contact") {
      axios
        .get("https://api.countapi.xyz/hit/danubepoodles.com/home")
        .then((resp) => {
          setVistors(visitors + resp.data.value);
          // setVistors(visitors - 1);
          console.log(currentURL);
        });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(toSend);

    send("service_sgcy3pq", "template_dip1owt", toSend, "hwKDVVgCLEeX9Y95R")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setIsError(false);
        setIsLoading(false);
        setMessage(
          <p>
            ✅ Thank you for your message! We will get back to you as soon as
            possbile!
          </p>
        );
        setToSend({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setMessage(
          <p>
            ❌ Unfortunately there was an unexpected error. Please try again...
          </p>
        );
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div id="contact" className={"section"}>
      {props.sectionTitle && (
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>CONTACT</h3>
        </div>
      )}

      <div
        style={props.padding ? { paddingTop: props.padding } : {}}
        className={`block content-1170 center-relative ${
          props.sectionWrapper ? props.sectionWrapper : ""
        }`}
      >
        {props.separatePage && (
          <div
            style={{
              textAlign: "center",
              maxWidth: "95%",
              marginBottom: "3rem",
              margin: "auto",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img
                style={{ maxHeight: "500px", maxWidth: "100%", margin: "auto" }}
                src={require("../../images/contact.png")}
                alt="Business Card"
              />
            </div>
            <br />
            <h3>
              <b>
                The best way to reach us is through a direct phone call or
                email. Please do <u>NOT</u> send a text message. Thank you!
              </b>
            </h3>
            <h2 className="medium-text" style={{ fontSize: "26px" }}>
              {visitors} <br />
              <span style={{ fontSize: "20px" }}>
                people have visited our webiste
              </span>
            </h2>

            <Row
              style={{
                marginBottom: "5rem",
                marginTop: "3rem",
                color: "#2b7a0b",
                fontWeight: "700",
              }}
            >
              <Col xs={12} sm={4}>
                <FaUserAlt />{" "}
                <a href="https://www.facebook.com/danubepoodles">
                  <u>Timea R Bodi</u>
                </a>
              </Col>
              <Col xs={12} sm={4}>
                <FaEnvelope />{" "}
                <a href="mailto:timearbodi@yahoo.com">
                  <u>timearbodi@yahoo.com</u>
                </a>
              </Col>
              <Col xs={12} sm={4}>
                {" "}
                <FaPhoneAlt />{" "}
                <a href="tel:410-603-4977">
                  <u>410-603-4977</u>
                </a>
              </Col>
            </Row>
          </div>
        )}
        <div className={"content-wrapper"}>
          <div className={"one_half"}>
            {props.titleDescription && (
              <p className={"title-description-up"}>{props.titleDescription}</p>
            )}
            {props.bigTitle && (
              <h2 className={"entry-title member-name"}>{props.bigTitle}</h2>
            )}

            {props.description && <p>{props.description}</p>}
            {props.facebook && (
              <div className={"social"}>
                <a href={props.facebook} target="_blank" rel="noreferrer">
                  <FaFacebookF />
                </a>
              </div>
            )}
            {props.instagram && (
              <div className={"social"}>
                <a href={props.instagram} target="_blank" rel="noreferrer">
                  <FaInstagram />
                </a>
              </div>
            )}
            <br />
            <br />
            <div>
              {" "}
              <h6 style={{ color: "black" }}>
                Are you ready to welcome a Danube Poodle Puppy in your family ?
              </h6>
              <p>
                Click{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/exVrsmmJnFoqYv2x9"
                >
                  <u>HERE</u>
                </a>{" "}
                to apply for a puppy!
              </p>
              <br />
            </div>
          </div>

          <div className={"one_half last"}>
            <form onSubmit={onSubmit} className={"contact-form"}>
              <p>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={toSend.name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </p>
              <p>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={toSend.email}
                  onChange={handleChange}
                />
              </p>
              <p>
                <input
                  id="subject"
                  type="text"
                  name="subject"
                  value={toSend.subject}
                  placeholder="Subject"
                  onChange={handleChange}
                />
              </p>
              <p>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={toSend.message}
                  onChange={handleChange}
                ></textarea>
              </p>
              <p className={"contact-submit-holder"}>
                <input type="submit" value="SEND" />
              </p>
              <p>{message}</p>
            </form>
          </div>

          <div className={"clear"}></div>

          <br></br>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
