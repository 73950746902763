import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import PuppyBooks from "../../../resources/puppy-books.pdf";
import BuyersEtiquette from "../../../resources/puppy-buyer-etiquette.pdf";

function BooksRecommendations(props) {
  const folder = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="books"
        src={require(`../../../images/books/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <>
      <GenericInfoPage
        title="Books Recommendations"
        imageName="books/img_3.jpg"
        blockQuoteValue="IF YOU ARE NOT WILLING TO LEARN NO ONE CAN HELP YOU. IF YOU ARE DETERMINED TO LEARN, NO ONE CAN STOP YOU."
        preQuoteBody={[
          <div style={{ textAlign: "center" }}>
            <h2>Check out these resources</h2>
            <a
              className="button special"
              href={BuyersEtiquette}
              target="_blank"
              rel="noreferrer"
            >
              Buyers's etiquette
            </a>
            {"   "}
            <a
              className="button "
              href={PuppyBooks}
              target="_blank"
              rel="noreferrer"
            >
              Recommended books for puppies
            </a>
          </div>,
        ]}
        postQuoteBody={[<div className="flex-custom-container">{folder}</div>]}
      />
    </>
  );
}

export default BooksRecommendations;
