import React from "react";
import { Outlet, Link } from "react-router-dom";

function Testimonial(props) {
  return (
    <div
      id="clientss"
      style={{ backgroundColor: "black" }}
      className={"section no-page-title"}
    >
      <div
        style={{ color: "white" }}
        className={"section-wrapper block content-1170 center-relative"}
      >
        <h1
          className="medium-text"
          style={{ color: "white", paddingTop: "0.5rem", textAlign: "center" }}
        >
          Testimonials
        </h1>
        <br />
        <Outlet />
      </div>
    </div>
  );
}

export default Testimonial;
