import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function Autumn(props) {
  const folder1 = [...Array(2)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/1/img_${i + 1}.jpg`)}
      />
    );
  });
  const folder2 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    if (i === 9 || i === 6) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/2/img_${
          i + 1
        }.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(10)].map((e, i) => {
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/3/img_${i + 1}.jpg`)}
      />
    );
  });
  const folder4 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    if (i === 0) ext = "png";
    if (i === 9) ext = "webp";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/4/img_${i}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(7)].map((e, i) => {
    let ext = "jpg";
    if (i === 1) ext = "png";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="bob"
        src={require(`../../../images/champions/EU/autumn/5/img_${
          i + 1
        }.${ext}`)}
      />
    );
  });

  return (
    <React.Fragment>
      <GenericInfoPage
        title=<span className="medium-text">Autumn and Maya</span>
        preQuoteHeadings={[
          <span className={"make-green"}>
            <b>Champions</b>
          </span>,
          <span className={"make-green"}>
            <b>In the park</b>
          </span>,
          <span className={"make-green"}>
            <b>At the lake</b>
          </span>,
          <span className={"make-green"}>
            <b>At the beach</b>
          </span>,
          <span className={"make-green"}>
            <b>In the park</b>
          </span>,
        ]}
        preQuoteBody={[
          <div className="flex-custom-container">{folder1}</div>,
          <div className="flex-custom-container">{folder2}</div>,
          <div className="flex-custom-container">{folder3}</div>,
          <div className="flex-custom-container">{folder4}</div>,
          <div className="flex-custom-container">{folder5}</div>,
        ]}
      />
      <TextAndTwoButtons
        title="Learn more"
        text=<span>
          Click on the first button below to see more about Aria and Maya or
          check out our <em>But I want a pet not a show dog </em>page to
          understand why our poodles are the best companions.
        </span>
        button1="Aria and Maya"
        href1="/champions-eu"
        button2="But I want a pet not a show dog"
        href2="/not-a-show-dog"
      />
      ;
    </React.Fragment>
  );
}

export default Autumn;
