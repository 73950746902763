import React, { Component } from "react";
import HomeSections from "./HomeSection/HomeSection";
import Services from "./Services/Services";
import About from "./About/About";
import Clients from "./Clients/Clients";
import News from "./News/News";
import Portfolio from "./Portfolio/Portfolio";
import Pricing from "./Pricing/Pricing";
import Team from "./Team/Team";
import Skills from "./Skills/Skills";
import Milestones from "./Milestones/Milestones";
import Contact from "./Contact/Contact";
import Footer from "../Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";
import SimpleInfoSection from "../SimpleInfoSection/SimpleInfoSection";
import { Link } from "react-router-dom";
import ContactSection from "../ContactSection/ContactSection";
import TextAndTwoButtons from "../TextAndTwoButtons/TextAndTwoButtons";

class Home extends Component {
  render() {
    return (
      <div className={"site-wrapper"}>
        <div id="content" className={"site-content center-relative"}>
          <HomeSections />
          <ParallaxProvider>
            <About />
          </ParallaxProvider>
          <Services
            paddingTop="7rem"
            bigTitle="Our core values at Danube Poodles"
            makeFull="true"
            valuesImage="true"
            first={{
              number: "1",
              title: "Hard Work",
              description:
                "Caring for the poodles at Danube is my full-time endeavor. These poodles are freely given love and attention throughout the 24 hours in a day. We accommodate each poodle based on their individual needs and personalities. With the innumerable benefits of a raw food nutrition, it has been fully implemented at Danube to provide a complete and balanced species appropriate diet. We do not spray our property with any chemicals or pesticides and we pay attention to significantly reduce exposure to any possible toxins around them. The hygiene, health, and regular grooming is additionally facilitated with an on-site fully equipped grooming and pampering room. Thanks to my 10 year experience working as a veterinary technician my poodles benefit from several in house medical equipment therefore alleviating some of the wildly stressful trips to the vet office.",
            }}
            second={{
              number: "2",
              title: "Family",
              description:
                "Our poodles are family members therefore we share our home, friends, family and even our beds. We include them in all our plans, daily activities and travels, hikes and adventure trips. We love all our poodles young and old. We pamper our retired poodles and we make sure they get to enjoy their golden years in retirement with us. We would never consider rehoming them. They are our lives and hearts that run in our family generationally.",
            }}
            third={{
              number: "3",
              title: "Health",
              description:
                "Our poodles from birth, receive the best possible diversified  and ideally balanced nutrition, mental stimulation and socialization with other dogs within a pack and one on one interaction with humans because we understand how important it is for puppies to be exposed to new elements so they can grow up to have strong balanced, fun personalities. Our puppies get to grow up on a 10 acre country side property with sensory and herb gardens  where they benefit from cleaner air, less toxicity and more exercise so they can develop a stronger immune system that will jump start them to achieve a healthier and longer life. We understand that if we want happy and healthy long lived poodles then extra care and attention to detail is needed. And that is our number one priority.",
            }}
          />{" "}
          <SimpleInfoSection
            sectionTitle="Why Danube Poodles"
            titleDescription="Why should you consider a Danube Poodle?"
            title="Performance, heart and quality"
            additionalClass="section-wrapper"
            content1={
              <React.Fragment>
                <div style={{ fontSize: "18px" }}>
                  <p>
                    I'm an{" "}
                    <em className="make-green">
                      AKC Breeder of Merit & NR Poodle Breeder
                    </em>{" "}
                    which means all my dogs are health tested and raised as
                    natural as possible with Holistic & Zoopharmacognocy
                    approach to healing and stimulation of their immune system.
                    The care that my poodles receive is based on my experience
                    as a <em className="make-green">veterinary technician</em>{" "}
                    and my knowledge acquired wile attending many{" "}
                    <em className="make-green">
                      holistic seminars and in depth personal research
                    </em>{" "}
                    so my dogs get the best care possible. Also because I work
                    in close relationship with my parents, who show and breed
                    poodles in Europe, I have the constant opportunity to breed
                    for diversity therefore benefiting the diversity in gene.
                  </p>
                  <p>
                    Our poodles are shown{" "}
                    <em className="make-green">across the globe</em> and we have
                    <em className="make-green"> champion winners</em> in US and
                    Europe including poodle specialty shows in many countries.
                    We are honored for our poodles to be handled by{" "}
                    <em className="make-green">top canine handlers</em>, with
                    high expertise and showmanship to bring the beauty, quality
                    of our poodles in show rings at a competitive high level.
                  </p>
                  <p>
                    I truly believe, after all the care and attention that I
                    give to them that{" "}
                    <em className="make-green">my poodles are the best</em>.
                    They are not just poodles they are{" "}
                    <em className="make-green">individuals</em>. Every poodle in
                    our home receive individual attention with correctly
                    balanced meals formulated by our{" "}
                    <em className="make-green">nutritionist</em> friend. We go
                    above and beyond to provide the best we can at all times.{" "}
                  </p>
                  <p>
                    <em className="make-green">
                      A healthy mind in a healthy body is a true statement.
                    </em>{" "}
                    Our poodles are feed a variety of fresh proteins from beef,
                    bison, lamb, chicken, turkey to alpaca, emu, camel, elk,
                    beaver, ostrich, yak etc{" "}
                    <em className="make-green">
                      up to 32 sources of protein and counting
                    </em>
                    . We focus on sourcing correctly and ethically and care to
                    provide extra nutrition elements supporting our local
                    farmers and small business as well as our own organically
                    raised chicken, ducks, quail, guineas flocks.
                  </p>
                </div>

                <br />
                <div
                  style={{ margin: "auto", textAlign: "center" }}
                  className={"button-holder text-left"}
                >
                  <Link className={"button"} to={"litter-plans"}>
                    Apply for a puppy HERE
                  </Link>
                </div>
              </React.Fragment>
            }
          />
          <Portfolio />
          <ParallaxProvider>
            <Clients />
          </ParallaxProvider>
          {/* <News /> */}
          {/* <Pricing /> */}
          {/* <ParallaxProvider>
            <Team />
          </ParallaxProvider> */}
          {/* <Skills /> */}
          <Milestones />
          <ContactSection
            // padding="3rem"
            sectionWrapper="section-wrapper"
            sectionTitle="CONTACT"
            titleDescription={
              <>
                The best way to reach us is through a direct phone call or
                email. Please do <u>NOT</u> send a text message. Thank you!
              </>
            }
            bigTitle="We would love to hear from you!"
            description=<div>
              Please fill out the form and we will get back to you as quickly as
              we can. Also you can check out our social media pages, where we
              constantly share updates, valuable informations and insights.
            </div>
            facebook="https://www.facebook.com/danubepoodles"
            instagram="https://www.instagram.com/danubepoodles"
          />
        </div>
      </div>
    );
  }
}

export default Home;
