import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import Gallery from "../../../components/Gallery/Gallery";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";
function PastLitters(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Past Litters"
        imageName="pastLitters.jpg"
        preQuoteBody={[
          <iframe
            width="476"
            height="847"
            src="https://www.youtube.com/embed/gSqIAgYBMoc"
            title="Grateful for all our past litters 💝🍼🐩🙏🏻"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/5OYGllidNEs"
            title="Danube Poodles Puppies"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="993"
            height="759"
            src="https://www.youtube.com/embed/RO4XACMlWa8"
            title="Puppy Fun Interactive PlayRoom"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/Xq1R-OFCewQ"
            title="Danube Poodles Puppies"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/Q7AaUeDYpBI"
            title="Dante and Aria Puppies 🐾🍼🐩💝"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/mcWHUZ2BMIs"
            title="Aria’s babies 🍼🙌💝"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/RP0UtIbGksE"
            title="Karina Past Litter Born in MD"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="1206"
            height="759"
            src="https://www.youtube.com/embed/p7Pb3Q-imLg"
            title="Danube Past Litter Puppies"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />
      <TextAndTwoButtons
        text={
          <span>
            {" "}
            <h6 style={{ textAlign: "center" }}>
              Have you made up your mind? Are you ready for a poodle? Fill the
              application form below and let's get to know each other!
            </h6>
            <p style={{ textAlign: "center" }}>
              Or if you need more information, check the page below.
            </p>
          </span>
        }
        button2="Health Protocol"
        href2="/danube-health-protocol"
        button1="Puppy application"
        href1="/litter-plans"
      />
      ,
      {/* <SimpleInfoSection
        title="Litter one"
        content1={
          <div className="flex-custom-container">
            {[...Array(33)].map((x, e) => {
              return (
                <img
                  style={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "20px",
                    borderRadius: "15px",
                  }}
                  alt="puppy"
                  src={require(`../../../images/past-litters/past_litters_one_${
                    e + 1
                  }`)}
                />
              );
            })}
          </div>
        }
      /> */}
    </React.Fragment>
  );
}

export default PastLitters;
