import React from "react";
import { FaCheck } from "react-icons/fa";
function TestingTable(props) {
  return (
    <div
      style={{ paddingTop: "0", paddingBottom: "0" }}
      className={"section-wrapper section"}
    >
      {props.sectionTitle && (
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>props.sectionTitle</h3>
        </div>
      )}
      <div className={" block content-1170 center-relative"}>
        <div className={"content-wrapper"}>
          <div className={"one"}>
            <div
              style={{ maxWidth: "none", marginBottom: "0" }}
              className={"pricing-table"}
            >
              {props.impact && <p className={"pricing-num"}>{props.impact}</p>}
              <div className={"pricing-wrapper"}>
                <div className={"pricing-table-header"}>
                  {props.title && (
                    <div className={"pricing-table-title"}>{props.title}</div>
                  )}
                </div>
                {/* <!-- <div className={"pricing-table-price"}>$25</div>
                  <div className={"pricing-table-desc}>PER MONTH</div> --> */}
                <div className={"pricing-table-content-holder"}>
                  <ul>
                    {props.testings.map((test, index) => {
                      return (
                        <li className={"pricing-list included-yes"}>
                          <FaCheck />{" "}
                          <span style={{ fontSize: "1.7rem" }}>{test}</span>
                          <p>{props.testsDescriptions[index]}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {props.button && (
                <div className={"pricing-button"}>
                  <a href="#" className={"button scroll"}>
                    {props.button}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className={"clear"}></div>
        </div>
      </div>
    </div>
  );
}

export default TestingTable;
