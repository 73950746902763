import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";
import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={
              <span style={{ textAlign: "center" }}>
                Vivi and Norbi's puppys
              </span>
            }
            images={[...Array(3)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page3/1/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>

        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              Today she began giving a paw and viewing herself in a hanging
              mirror. I got her revved up with a squeaky voice and she got her
              groove on. Like a baby, she liked having blanket thrown over her
              head for peekaboo. We put bed in her crate with door open. She
              goes in taking her stuffed dogs in there. Haven't shut door yet
              but will work for that. Last dog was my guardian angel but would
              cry when left. Went to funerals, weddings, reunions. Patmore is
              more independent so far by temperament. She is a quick learner as
              all poodles are. She has the intelligence of a four year old child
              at this stage . The gym activity is great because she amuses
              herself. Going under the gym and sleeping under it could be why
              she doesn't mind going in the bed inside the crate. Not sure.! Her
              day pen is where we are in view and we go out to the garage past
              her pen many times a day. If she cries, we ignore her completely.
              She rarely cries. We don't enter her pen at all if she jumps up.
              Food gets down when she sits. She doesn't get back in the house
              from outdoors till calm and sitting. Sure she'll mess up along the
              way. People do and poodles are the closest beings to humans. Know
              that from having had hound dogs! <br />
              Very happy with our girl, Patmore. Her growth and development are
              right on target. She weighs 10..4 lbs now and looks just like
              Vivi. Some of her baby teeth are loosening with the permanents
              emerging.. Her heart is strong and disposition cheerful. Energy
              level is high. Often she plays with two toys at same time. Loves
              her meals. I'm guessing she'll be a heavier poodle than our last
              boy. Her front paws are quite wide. Good thing we don't need to
              shop for shoes. She has quiet, lovey times when she curls up
              crosswise on my chest and zonks out by the fire. My husband
              lengthened the legs of her gym and there is a bassinett mattress
              that fits perfectly under it should she decide to lounge. I'm very
              happy that she'll nap in the crate. Door is open, Water bottle
              available, but with the door shut she can be left 3 hours without
              incident. Car trips are fine in her carrier. She walks in
              voluntarly. She performed her version of eye surgery on a stuffed
              animal. Will be removing eyes and stitching. up with carpet
              thread. Her playspace is enlarged but she doesn't have freedom to
              roam. Keeps dry every night but will sometimes piddle when excited
              in her day space. I sense she has bonded greatly because she is
              teaching us her cues and we are reading each other more easily.
              Girl loves shaking hands and high fives, also teases and does fast
              escapes from her play yard gate in the laundry... typical poodle
              .Hope all of you are good and glad the last snow melted. That was
              messy. Just put away Christms stuff and we are watching Eagles
              football. Hi to the gang.😂Gloria
            </span>
            images={[...Array(13)].map((e, i) => {
              let ext = ".jpg";
              if (i === 4 || i === 9 || i === 7 || i === 12) ext = ".png";
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page3/2/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text=<span>
              Timea, Slav and I can not tell you how grateful we are that we met
              you and that Syrus is now a part of our family. Syrus is smart,
              confident , curious and outgoing. He loves spending time with his
              family. Syrus and Odin are best buds who enjoy exploring, rough
              housing, and taking long naps together. <br /> Love Tracy
            </span>
            images={[...Array(15)].map((e, i) => {
              let ext = ".jpg";
              if (i === 3) ext = ".png";
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page3/3/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              Pepper is a fun, energetic girl who loves meeting new people. She
              is a great addition to our family, and we look forward to seeing
              her and the kids grow up together. <br /> Emily and Jon
            </span>
            images={[...Array(13)].map((e, i) => {
              let ext = ".jpg";
              if (i === 2) ext = ".jpeg";
              if (i === 12) ext = ".png";

              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page3/4/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            images={[...Array(14)].map((e, i) => {
              let ext = ".jpg";
              if (i === 7 || i === 11) ext = ".png";
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page3/5/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div>2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div>3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div>4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div>5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div className="active">6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
