import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";

function AreYouReady(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Are you ready for a poodle ?"
        preQuoteBody={[
          <br />,
          <p>
            Acquiring a pet is one of the most significant commitments you will
            make in your lifetime. Accepting the responsibility of caring for
            another life — a creature that will be totally dependent on you —
            <u>isn’t something to take lightly</u>.
          </p>,
          <div>
            <p>
              <em style={{ color: "#2b7a0b" }}>
                As loving, committed pet parents, we want to keep our animal
                companions with us as long as possible, well into old age.
                Caring for a happy, healthy senior means providing these three
                basics:
              </em>
              <ul>
                <li>Physical and emotional comfort as your pet ages</li>
                <li>
                  A nutritionally balanced,{" "}
                  <u>species-appropriate, fresh food diet</u>
                </li>
                <li>
                  Ongoing, regular opportunities for exercise, socialization and
                  mental stimulation
                </li>
              </ul>
            </p>
          </div>,
          <h6 style={{ textAlign: "center", padding: "0" }}>
            {" "}
            I can’t emphasize enough the need to carefully evaluate your
            readiness and ability to care for a pet, and encourage you to ask
            yourself the following important questions before making a decision
            to bring home a new family member.
          </h6>,
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        title="9 Questions to Ask Yourself Before You Buy a Dog"
        preQuoteHeadings={[
          "Time and Energy",
          "",
          "Money",
          "Agreement",
          "Training",
          "Integration with other pets",
          "Messes and Accidental Destructions",
          "Integration with your lifestyle",
          "Long-term commitment",
        ]}
        preQuoteBody={[
          <p>
            Even relatively low-maintenance pets require attention from their
            humans, so if your life is already very busy or you’re not home
            much, a pet may not be the best idea.
          </p>,
          <p>
            Without social interaction and stimulation, pets tend to develop
            behavior and emotional problems. In addition to spending time with
            you, your pet also deserves to be exercised, played with, trained,
            groomed, and cuddled. If you come home at night exhausted, you
            should think seriously about whether you have the energy reserves
            you’ll need to offer a pet a good quality of life.
          </p>,
          <p>
            Caring properly for a pet can put <u>a dent in your budget</u>. You
            should think realistically about whether you can afford the cost of
            a high-quality diet, toys, other supplies, obedience training,
            wellness visits to the veterinarian, etc. In addition, your pet
            could get sick or injured, and you should have a plan in mind for
            how you’ll pay those vet bills in the event something serious
            happens to your animal companion.
          </p>,
          <p>
            It’s ideal if everyone in the family or household is onboard with
            getting a pet. Otherwise, resentments can build and relationships
            can suffer. It’s a good idea to involve all members of the household
            in the decision-making process, openly discuss concerns, and
            determine who will have primary responsibility for the pet’s care.
          </p>,
          <p>
            Combine a lack of healthy socialization with the potential for
            negative, fear-based training or a neglectful/abusive first few
            months, and you have the recipe for a lifetime of dysfunctional
            behaviors and responses to everyday life in the animal you just got.
            <br />
            Are you committed to a lifetime of “damage control” when it comes to
            positively addressing negative behaviors and phobias that your newly
            adopted furry companion may arrive with? And can you trust everyone
            in your household to participate in positive training to correct
            behavior issues?
          </p>,
          <p>
            You definitely need to plan ahead if you already have a pet and want
            to add another to the household. Most animals can learn to get along
            or at least tolerate each other, but there are situations in which
            it’s just too dangerous or stressful to keep two poorly matched pets
            under the same roof. Often it just takes some time and a few helpful
            tips to put an existing pet and a new one on the road to a
            harmonious relationship.
          </p>,
          <p>
            Pet ownership means there will be the{" "}
            <u>inevitable accidents and other messes</u> in the house, paw
            prints on your furniture and bedding, and the random destroyed
            slipper or other personal belonging. If you can’t tolerate the
            thought of a less than perfectly clean house, you might want to
            reconsider the idea of pet ownership. Even the most well-behaved,
            well-trained animal companion makes the occasional mess or forgets
            his manners.
          </p>,
          <p>
            It’s important to think about how you’d like your new pet to fit
            into your lifestyle. For example, if you do a lot of traveling and
            want to take your pet along, a small dog is probably a better choice
            than a large breed. If you plan to{" "}
            <u>take your pet jogging with you</u>, some dogs are better suited
            to long runs than others.
            <br />
            It’s also important to think about what you can offer a potential
            pet. If, for instance, you’re the outdoorsy type who enjoys hiking
            and camping, those activities have tremendous appeal to miniature
            and standard poodles but not for toy breeds. Ideally, you do plan to
            include your pet in many of your leisure time pursuits, so it’s
            important to give the subject some careful thought.
          </p>,
          <p>
            While we can’t predict the future, most of us have a vision for our
            lives that extends years down the road. Regardless of the type of
            pet you’re considering, you’ll be taking on a multi-year commitment.
            It’s important to be reasonably sure your lifestyle will be as
            pet-friendly in 5, 10, or 20 years as it is today.
          </p>,
          <ol style={{ margin: "auto" }}>
            <li>Do you have the time to devote to a pet?</li>
            <li>Do you have the energy to dedicate to a pet?</li>
            <li>Can you afford a pet?</li>
            <li>Is everyone in the household sold on the idea of a pet?</li>
            <li>
              Does your prospective new pet come with emotional or behavioral
              “baggage” you can accept or commit to dealing with?
            </li>
            <li>Will your existing pet (if you have one) accept a new pet?</li>
            <li>
              Are you prepared to prioritize your pet over your belongings?
            </li>
            <li>What kind of relationship do you want with your pet?</li>
            <li>
              What changes do you expect in your life in the next 5, 10, or 15
              years?
            </li>
          </ol>,
          <br />,
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        title="Why not gift a puppy?"
        preQuoteBody={[
          "What is not fun are statistics that show pets given as gifts abandoned following Christmas. Sadly shelters fill up with relinquished pets. Similar statistics occur following Easter with the abandonment of rabbits and chicks.",
          "Don’t get me wrong, planning ahead for a pet can be good when the recipient understands the responsibility of a pet, they understand what is required to care for a pet – and they understand medical issues common in some breeds and when they actively participate in choosing their pet.",
          "You can always give a card saying “Merry Christmas, your wish has been granted. You are getting the pet of your choice so lets plan a time to find one”. When the recipient chooses their pet they connect with it and they will bond with it.",
          <br />,
          <br />,
        ]}
      />
      <GenericInfoPage
        articleLayout="true"
        title="Why You Shouldn’t Give Puppies As Gifts This Christmas"
        preQuoteBody={[
          " It seems completely harmless, the idea of presenting someone you care about with a large box only to watch as their face changes to a look of pure joy when they see a cute little puppy or kitten popping out with a red ribbon tied to their collar. However, unlike other gifts, living animals can’t just be returned or exchanged if it turns out to be a bad fit.",
          "That is why we should never give pets as Christmas gifts. It’s just a bad idea all around. Here are some of the things you should be considering NOT giving a pet as a Christmas gift. Christmas time is already pretty hectic as it is, do you really want to make someone or some family even more bonkers during the busiest time of the year by sticking them with a new family addition? Pets are a long term commitment – dogs can live for 12-15 years on average, while cats can easily live more than 15 years. Unless you know for a fact that this is exactly the kind of commitment that someone has been looking for, don’t give them a pet. Get them a pair of socks instead.",
          "Often times people view pets as commodities rather than living beings who require a lot of care as well as plenty of time and attention. If someone isn’t willing to take the time to properly care for and train a new pet, then don’t give them the gift of a pet for Christmas. Improper care or training can lead to behavioral problems, which if not addressed, can lead to even bigger problems. There are endless numbers of pets that end up shelters or taken in to be euthanized for “behavior problems” that could have easily been avoided with proper care and training.",
          "Now, very rarely is there ever an occasion that giving a pet as a gift is a great idea. But, if you diligently do your homework, there might be a rare exception, so here are some tips for giving pets as presents:",
          <p>
            <em>Always ask first.</em> Never surprise someone with a pet gift.
            Even if you know full well that the person is on the lookout for a
            pet, you need to ask first in order to be sure what kind of pet to
            get them. You want to make sure that you’re not getting your elderly
            parents an energetic puppy that will be too much for them to handle
            when an elderly house-trained dog would’ve done just as fine as a
            companion. <br />
            Give pets to immediate family only. Buying someone else’s child a
            puppy is not a good idea, even if you know they want one. Keep the
            pet gift-giving to your parents or your children only.{" "}
          </p>,
          <p>
            <em>Make sure they can care for the pet properly.</em> Before you
            buy your elderly mother that dog, be sure she can physically keep up
            with it. And before you add a new puppy to the family, make sure
            that your kids are old enough to assist in its care. If the answer
            is no, then don’t get the pet. Avoid impulsive decisions. Buying a
            pet the same as buying a pair of shoes. It should not be a light
            impulse one day that you’re at the mall. It’s a decision that should
            involve the entire family.
          </p>,
          <p>
            <em>Wait until the holidays are over.</em> The holidays are a busy
            time. So if you are serious about adding to the family, consider
            doing so when things start to quiet down in January and February.
            That way, you can have the entire family sit down and discuss
            everything involved. That way you have time to do your homework, get
            all the supplies you will need, and head down to the local shelter
            in order to adopt rather than shop.
          </p>,
          "So this holiday season, definitely avoid the impulse to buy the fluffy puppy sitting in the window of the pet store just because the kids would love it. Instead, really think about whether it would be a good idea or not to add to your family. Unfortunately, Christmas is the time of year when many pets end up being given as holiday presents only to end up as products of the shelter system weeks later. Don’t be that jerk that feeds into it by impulsively giving a pet as a gift. Instead, always give plenty of time and forethought into the decision. ",
        ]}
      />
      <br />
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

export default AreYouReady;
