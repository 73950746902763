import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import TestimonialBody from "../components/TestimonialBody/TestimonialBody";
import { Link } from "react-router-dom";
function TestimonialPageOne(props) {
  return (
    <React.Fragment>
      <ParallaxProvider>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            text={
              <span>
                We are so happy to have Beau. He is a total delight. He has a
                wonderful disposition. He will sit calmly on my lap for an hour
                each morning when we have coffee with our friends and then later
                when we play, he is totally engaged. <br />
                He is an affectionate and very happy little guy. <br />
                Thanks for giving him such a good start, Timea
              </span>
            }
            images={[...Array(8)].map((e, i) => {
              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/13/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            images={[...Array(9)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/14/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[-5, 0]}>
          <TestimonialBody
            images={[...Array(6)].map((e, i) => {
              return (
                <img
                  className={"testimonial-images"}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/15/img_${
                    i + 1
                  }.jpg`)}
                />
              );
            })}
          />
        </Parallax>
        <Parallax translateX={[0, -5]}>
          <TestimonialBody
            text=<span>
              <em>How to do justice to Homer?</em> Named after the epic poet, he
              has character to match! He is a striking mix of strong and
              endearing. True to the breed he is somehow shockingly intelligent,
              and he likes to feel in charge. At the dog-park he is bossy
              towards the big dogs and easily keeps up with them, but he is
              protective of the ones who are smaller than the rest. He would
              play all day every day if it were up to him--and it usually is. We
              have never encountered a creature as affectionate and expressive
              of love. Our neighbor aptly described him as , if not emotional
              then, emotive. He is devoted to us , and we are devoted to him.
              Thinking about it I realize he has changed the way we live. We are
              so grateful to Timea for the gift of Homer. She is a remarkable
              person and breeder, and we wouldn't go t o anyone else ( unless
              she sent us there!).
            </span>
            images={[...Array(12)].map((e, i) => {
              let ext = ".jpg";

              return (
                <img
                  style={{
                    maxWidth: "500px",
                    maxHeight: "500px",
                    height: "auto",
                    width: "auto",
                    marginBottom: "30px",
                    borderRadius: "15px",
                  }}
                  alt="puppies"
                  src={require(`../../../images/testimonials/page1/16/img_${
                    i + 1
                  }${ext}`)}
                />
              );
            })}
          />
        </Parallax>
      </ParallaxProvider>
      <br />
      <div className="pagination-container">
        <div className="pagination">
          <Link to={"/testimonials/one"}>
            <div className="first">1</div>
          </Link>{" "}
          <Link to={"/testimonials/two"}>
            <div>2</div>
          </Link>
          <Link to={"/testimonials/three"}>
            <div>3</div>
          </Link>
          <Link to={"/testimonials/four"}>
            <div className="active">4</div>
          </Link>
          <Link to={"/testimonials/five"}>
            <div>5</div>
          </Link>
          <Link to={"/testimonials/six"}>
            <div>6</div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TestimonialPageOne;
