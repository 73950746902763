import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import GenericInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import { Link, useNavigate } from "react-router-dom";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function MoreInfoPage() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <GenericInfoPage title="What we offer our poodles" />
      <GenericInfoPage
        id="health"
        preQuoteHeadings={["HEALTH"]}
        preQuoteBody={[
          `Health is incredible important for any living being. If a creature is not healthy they cannot live a happy and fulfilling life. All our poodles and puppies are evaluated and recorded on a regular basis for health. We are proud advocates  on screenings to ensure our poodles are free of hereditary diseases. We are proud to provide our poodles with various forms of enrichment and stimulation within their environment and in our home.`,
        ]}
        articleLayout="true"
      />

      <GenericInfoPage
        id="exercise"
        articleLayout="true"
        preQuoteHeadings={["EXERCISE"]}
        preQuoteBody={[
          `Exercise is never optional. Animals are meant to move almost constantly. In the wild, playing, moving from place to place, and hunting are done daily. Movement is required to find food, to grow and stay strong, and to release toxins from the body.
           Exercise, especially outside in the fresh air. tends to lead to an increase in natural killer cells, neutrophilis and monocytes, witch ultimately increases immune function.
           Water - we always give pure, fresh, unadulterated water and avoid community tap water that has been treated with chlorine, fluoride or other toxins.`,
        ]}
      />
      <GenericInfoPage
        id="sun"
        articleLayout="true"
        preQuoteHeadings={["SUN"]}
        preQuoteBody={[
          `Animals instinctually know what is needed to stay healthy ( see Zoopharmacognocy ). Sunlight provides numerous benefits and many animal species are often observed napping in the sun.Animals benefit from the sun's effect on their bodies.`,
        ]}
      />
      <GenericInfoPage
        id="supplements"
        preQuoteHeadings={["SUPPLEMENTS"]}
        preQuoteBody={[
          `Supplements are especially needed when a raw , species specific diet is not being fed and/ or when the animal is already in a dis-eased state.This is due to the fact that most soils are nutrient depleted.This in turn translates into a lack of nutrients in the animals that eat grasses grown on nutrient depleted ground and in turn for the carnivore that eats the omnivore that ate the grasses.However, when supplements are used, they need to be used in moderation and qualified for each animal's unique needs.`,
        ]}
        articleLayout="true"
      />
      <GenericInfoPage
        id="temperance"
        articleLayout="true"
        preQuoteHeadings={["TEMPERANCE"]}
        preQuoteBody={[
          `Temperance is an old-fashioned word for moderation. Too much of even a good thing can wind up being detrimental — for example, too much exercise, too much sun, too much water, etc.`,
        ]}
      />
      <GenericInfoPage
        id="air"
        articleLayout="true"
        preQuoteHeadings={["AIR"]}
        preQuoteBody={[
          `Every living thing needs fresh air daily if possible. Fresh air energizes. Research shows that spending time in fresh air, especially surrounded by trees and natural water sources, increases energy.`,
        ]}
      />
      <GenericInfoPage
        id="rest"
        preQuoteHeadings={["REST"]}
        preQuoteBody={[
          `Companion animals need plenty of quality rest. They do best without all the noise of modern appliances and electronic EMFs that interfere with deep rest and biorhythms. The stress of not enough good rest can alone bring about illness and behavior problems.`,
        ]}
        articleLayout="true"
      />
      <GenericInfoPage
        id="trust"
        articleLayout="true"
        preQuoteHeadings={["TRUST"]}
        preQuoteBody={[
          `Trust is not something very many people think about as being a law of nature and health, but it is the eighth law. Trust is a firm belief in the reliability, truth, ability, and strength of something (or someone).
            Wherever your spirituality comes from, research indicates there are real health benefits to both yourself and to your animals. Animals and people have forged an incredibly close connection and animals have an uncanny ability to “read us” and reflect our emotions/moods and attitudes.`,
        ]}
      />
      <GenericInfoPage
        id="temperament"
        articleLayout="true"
        preQuoteHeadings={["TEMPERAMENT"]}
        preQuoteBody={[
          `Although not every poodle will do therapy work or enter the show ring, a great out going, social and loving personality is characteristic of the breed. We strive to for our poodles to be and integral part of every family.Not fleeing the scene and hiding from sight.All of our poodles and puppies are in constant human contact, handled regularly and get personalized tender, love and care. Danube poodles are exposed to various experiences including other dogs, cats, chickens and other animals.Traveling in the car and sometime in an airplane, boat, people of al kinds including children, the elderly and those with disabilities. Big crowds of people and outdoor festivities. `,
        ]}
      />
      <GenericInfoPage
        id="genetics"
        preQuoteHeadings={["GENETICS"]}
        preQuoteBody={[
          `In order to truly produce impeccable poodles that not only advance the individual but breed, one needs an understanding of poodle genetics.Genetics is integral for maintaining health as well as producing puppies with specific characteristics that exemplify the characteristics of the poodle.At Danube Poodles every pairing is carefully planned with a purpose.`,
        ]}
        articleLayout="true"
      />
      <GenericInfoPage
        id="nutrition"
        articleLayout="true"
        preQuoteHeadings={["NUTRITION"]}
        preQuoteBody={[
          `Nutrition is the fuel of life.Without the proper diet domestic dogs of all kinds cannot live to their full potential 100%. They are merely surviving versus thriving.This is exactly why our poodles are fed a species appropriate diet and balanced diet of raw meat, organs and bones.We take our poodles nutrition very seriously and make sure each of our poodles are the perfect advocate for prey model raw feeding.

Nutrition is the cornerstone of health. All species MUST eat what they were designed to eat in order to thrive (species-specific raw food). In nature, food is not cooked. Each species consumes raw food that is dense with readily bio-available nutrition needed for their unique anatomy and physiology.`,
        ]}
      />
      <GenericInfoPage
        id="standard"
        articleLayout="true"
        preQuoteHeadings={["STANDARD"]}
        preQuoteBody={[
          `Although brilliant color and the right point pigmentation are characteristics of the breed, there is certainly more to it than flash. With moving the breed forward , it is important to Danube Poodles that the standard is fallowed while going with the flow as the breed develops into the beautiful poodle.This is also why we choose to show our poodles.Not only are they evaluated buy our breeder friends, but they are evaluated by countless judges who know the standard to a T as well.`,
        ]}
      />
      <TextAndTwoButtons
        text="We do the best for our poodles. Our website is our proof of that.
              Keep reading in order to discover more about who we are, what do
              we stand for and what do we do with and for our poodles."
        button1="Our About Page"
        button2="Home"
        href2="/"
      />
    </React.Fragment>
  );
}

export default MoreInfoPage;
