import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import { Carousel } from "react-responsive-carousel";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function Females() {
  const izabella_array = [...Array(7)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/EU/izabella/img_${i + 1}.jpg`)}
      />
    );
  });

  const aria_array = [...Array(7)].map((e, i) => {
    return (
      <img
        style={{ maxHeight: "700px", width: "auto" }}
        key={i}
        alt="puppies"
        className="image"
        src={require(`../../../images/champions/EU/aria/img_${i + 2}.jpg`)}
      />
    );
  });
  return (
    <>
      <GenericInfoPage
        title="EU Female Champions"
        postQuoteHeadings={[
          "FCI Ch Danube's Csardas With American Rhapsody Twist aka Izabella",
          "FCI Ch Ansvel Aria Nesum Dorma To Danube aka Aria",
        ]}
        postQuoteBody={[
          <>
            <p>
              She’s the Energizer bunny of the pack. She doesn’t have a stop
              button and when she does you can tell she is planning for her next
              shananigan. She has a very developed sense of smell always on the
              move exploring and digging for lost treasures. She’s able to steal
              your heart in a beat.
            </p>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {izabella_array}
            </Carousel>
          </>,
          <>
            <br />
            <Carousel
              className="champions-carousel"
              showThumbs={true}
              showIndicators={false}
              swipeable={true}
              emulateTouch={true}
            >
              {aria_array}
            </Carousel>
          </>,
        ]}
      />

      <GenericInfoPage
        articleLayout={true}
        preQuoteHeadings={["Video Gallery"]}
        preQuoteBody={[
          <iframe
            width="640"
            height="568"
            src="https://www.youtube.com/embed/djNph_-1lrk"
            title="Baby Izabella is turning into a beautiful adolescent poodle 💝🐩"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="640"
            height="568"
            src="https://www.youtube.com/embed/6OAb1qZ2Xxo"
            title="Izabella the firecracker poodle 🤪🥳🐩💞"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
          <iframe
            width="435"
            height="773"
            src="https://www.youtube.com/embed/1LBzPwnXAwo"
            title="Staring contest between Izabella and her mom Aria. Who do you think won ? ⏳🐩💞🐩"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>,
        ]}
      />
      <br />
      <TextAndTwoButtons
        title="Learn more"
        text=<span style={{ fontSize: "18px" }}>
          Get back to the champions page and learn more about our champion
          poodles. Or go back to the homepage and discover more about us, about
          our health protocol and natural rearing.
        </span>
        button1="Our Champions Page"
        button2="Home"
        href2="/"
      />
    </>
  );
}

export default Females;
