import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./components/Helpers/ScrollToTop";

// Evergreen Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

// Pages
import Home from "./components/Home/Home";
import AboutPage from "./pages/AboutPage/AboutPage";
import MoreInfoPage from "./pages/AboutPage/MoreInfoPage/MoreInfoPage";
import GroomingPage from "./pages/AboutPage/GroomingPage/GroomingPage";
import HeavenPage from "./pages/AboutPage/HeavenPage/HeavenPage";
import HealthProtocol from "./pages/HealthPages/HealthProtocol/HealthProtocol";
import NaturalRearings from "./pages/HealthPages/NaturalRearings/NaturalRearings";
import RawDiet from "./pages/HealthPages/RawDiet/RawDiet";
import Vaccination from "./pages/HealthPages/Vaccination/Vaccination";
import HealthTestings from "./pages/HealthPages/HealthTestings/HealthTestings";
import ImmuneSystem from "./pages/HealthPages/ImmuneSystem/ImmuneSystem";
import SpayingAndNeutering from "./pages/HealthPages/SpayingAndNeutering/SpayingAndNeutering";
import NotAShowDog from "./pages/AreYouReadyPages/NotAShowDog/NotAShowDog";
import GettingReady from "./pages/AreYouReadyPages/GettingReady/GettingReady";
import AreYouReady from "./pages/AreYouReadyPages/AreYouReady/AreYouReady";
import Contact from "./pages/ContactPage/ContactPage";
import PastLitters from "./pages/PuppyPages/PastLitters/PastLitters";
import LitterPlans from "./pages/PuppyPages/LitterPlans/LitterPlans";
import Breeding from "./pages/PuppyPages/Breeding/Breeding";
import BooksRecommendations from "./pages/AreYouReadyPages/BooksRecommendations/BooksRecommendations";
import BuyersEtiquette from "./pages/Resources/PFD/BuyersEtiquette";
import PuppyBooks from "./pages/Resources/PFD/PuppyBooks";
import Testimonial from "./pages/TestimonialPages/Testimonial";
import TestimonialPageOne from "./pages/TestimonialPages/pages/TestimonialPageOne";
import TestimonialPageTwo from "./pages/TestimonialPages/pages/TestimonialPageTwo";
import TestimonialPageThree from "./pages/TestimonialPages/pages/TestimonialPageThree";
import TestimonialPageFour from "./pages/TestimonialPages/pages/TestimonialPageFour";
import TestimonialPageFive from "./pages/TestimonialPages/pages/TestimonialPageFive";
import TestimonialPageSix from "./pages/TestimonialPages/pages/TestimonialPageSix";
import ChampionsMain from "./pages/Champions/ChampionsMain";
import ChampionsUS from "./pages/Champions/ChampionsUS";
import ChampionsEU from "./pages/Champions/ChampionsEU";
import FemalesChampions from "./pages/Champions/US/FemalesChampions";
import MalesChampions from "./pages/Champions/US/MalesChampions";
import RetiredChampions from "./pages/Champions/US/RetiredChampions";
import AngelChampions from "./pages/Champions/US/AngelChampions";
import Autumn from "./pages/Champions/EU/Autumn";
import Gallery from "./pages/Gallery/Gallery";
import Gallery2007 from "./pages/Gallery/2007/Gallery2007";
import Gallery2008 from "./pages/Gallery/2008/Gallery2008";
import Gallery2009 from "./pages/Gallery/2009/Gallery2009";
import Gallery2010 from "./pages/Gallery/2010/Gallery2010";
import Gallery2011 from "./pages/Gallery/2011/Gallery2011";
import Gallery2012 from "./pages/Gallery/2012/Gallery2012";
import Gallery2013 from "./pages/Gallery/2013/Gallery2013";
import Gallery2014 from "./pages/Gallery/2014/Gallery2014";
import Gallery2015 from "./pages/Gallery/2015/Gallery2015";
import Gallery2016 from "./pages/Gallery/2016/Gallery2016";
import Gallery2017 from "./pages/Gallery/2017/Gallery2017";
import Gallery2018 from "./pages/Gallery/2018/Gallery2018";
import Gallery2019 from "./pages/Gallery/2019/Gallery2019";
import GalleryPCA2019 from "./pages/Gallery/2019/GalleryPCA2019";
import Gallery2020 from "./pages/Gallery/2020/Gallery2020";
import Gallery2021 from "./pages/Gallery/2021/Gallery2021";
import Gallery2022 from "./pages/Gallery/2022/Gallery2022";
import Store from "./pages/Store/Store";

import Main from "./pages/Gallery/Main/Main";
import PageNotFound404 from "./pages/PageNotFound/PageNotFound404";
import Females from "./pages/Champions/EU/Females";
import Retired from "./pages/Champions/EU/Retired";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="quality miniature poodle, quality miniature poodles, red poodle, black poodle, red poodles, black poodles, red pudel, black pudel, red caniche, black caniche,  red miniature poodle, black miniature poodle, AKC miniature poodle, AKC red miniature poodles, AKC black miniature poodles, red poodle breeder, black poodle breeder, red poodle puppies for sale, black poodle puppies for sale, reputable poodle breeder, AKC poodle breeder of merit, Natural Reared Poodle, Natural Reared Poodle Puppy, Champion poodles, pudel medium, pudel dwarf, poodle puppies for sale, miniature red poodle, miniature black poodle, miniature poodle, miniature poodle breeder, puppies for sale, poodles in Hungary, poodles in Romania, poodles in USA, poodles in Tennessee, AKC reputable poodle breeder, black poodle , miniature black poodle , miniature black poodles, natural reared black poodle breeder, black poodle breeder of merit, miniature black poodle puppy , miniature black poodle puppies, mini poodles, moyen red poodles, dwarf black poodles, medium black poodle breeder, champion bloodline poodle breeder, show miniature poodle, miniature show poodle breeder, red show poodles, champion red poodle, black champion poodles, say no to doodles pick a poodle, poodle love, poodle lover, poodle world, poodle planet, poodle variety, agility poodles , hunting poodles, canine good citizen poodle, travel size poodle, pure poodles, exceptional poodles, poodle life, best poodles, grand champion poodles, 🐩, 🐩❤️"
      />
      {/* <!-- Open Graph --> */}
      <meta
        property="og:title"
        content="Danube Poodles - Quality miniature poodle"
      />
      <meta property="og:url" content="https://www.danubepoodles.com/" />
      <meta property="og:locale" content="en_US" />
      <meta
        property="og:description"
        content="Changing The Future Of Poodles One Puppy At A Time. Welcome! Here where we breed quality Miniature Poodles under the name Danube. Immeasurable care and attention is given to each litter because we respect the natural rearing foundations and we work hard to respect all the our health protocols. This way we make sure that our poodles are the best poodles in the entire world! Come and see for youself!"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://www.danubepoodles.com/static/media/values.e5d0e523c8e6030dddd9.jpg"
      />
      {/* <!-- Open Graph --> */}
      {/* <!-- Twitter Cards --> */}
      <meta
        name="”twitter:card”"
        content="Changing The Future Of Poodles One Puppy At A Time"
      />
      <meta
        name="twitter:description"
        content="Welcome! Here where we breed quality Miniature Poodles under the name Danube. Immeasurable care and attention is given to each litter because we respect the natural rearing foundations and we work hard to respect all the our health protocols. This way we make sure that our poodles are the best poodles in the entire world! Come and see for youself!"
      />
      <meta
        name="twitter:title"
        content="Danube Poodles - Quality miniature poodle"
      />
      <meta name="”twitter:site”" content="@danubepoodles" />
      <meta
        property="twitter:image"
        content="https://www.danubepoodles.com/static/media/values.e5d0e523c8e6030dddd9.jpg"
      />
      {/* <!-- Twitter Cards --> */}
      {/* <!-- Google + --> */}
      <meta
        itemprop="name"
        content="Danube Poodles - Quality miniature poodle"
      />
      <meta
        itemprop="description"
        content="Changing The Future Of Poodles One Puppy At A Time. Welcome! Here where we breed quality Miniature Poodles under the name Danube. Immeasurable care and attention is given to each litter because we respect the natural rearing foundations and we work hard to respect all the our health protocols. This way we make sure that our poodles are the best poodles in the entire world! Come and see for youself!"
      />
      {/* <!-- Google + --> */}
      <link rel="canonical" href="https://www.danubepoodles.com/" />
    </Helmet>
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutPage />}></Route>
        <Route path="about/more-details" element={<MoreInfoPage />} />
        <Route path="about/our-piece-of-heaven" element={<HeavenPage />} />
        <Route path="about/our-grooming-room" element={<GroomingPage />} />
        <Route path="about/more-details" element={<MoreInfoPage />} />
        <Route path="test" element={<Home />} />
        <Route path="danube-health-protocol" element={<HealthProtocol />} />
        <Route path="natural-rearings" element={<NaturalRearings />} />
        <Route path="raw-diet" element={<RawDiet />} />
        <Route path="vaccinations" element={<Vaccination />} />
        <Route path="health-testings" element={<HealthTestings />} />

        <Route
          path="maintaining-a-healthy-immune-system"
          element={<ImmuneSystem />}
        />
        <Route
          path="spaying-neutering-info"
          element={<SpayingAndNeutering />}
        />
        <Route path="not-a-show-dog" element={<NotAShowDog />} />
        <Route path="getting-ready" element={<GettingReady />} />
        <Route path="are-you-ready" element={<AreYouReady />} />
        <Route
          path="books-recommendations"
          element={<BooksRecommendations />}
        />

        <Route path="litter-plans" element={<LitterPlans />} />
        <Route path="past-litters" element={<PastLitters />} />
        <Route path="testimonials" element={<Testimonial />}>
          <Route path="one" element={<TestimonialPageOne />} />
          <Route path="two" element={<TestimonialPageTwo />} />
          <Route path="three" element={<TestimonialPageThree />} />
          <Route path="four" element={<TestimonialPageFour />} />
          <Route path="five" element={<TestimonialPageFive />} />
          <Route path="six" element={<TestimonialPageSix />} />
        </Route>
        <Route path="champions-us" element={<ChampionsMain />}>
          <Route path="" element={<ChampionsUS />} />
          <Route path="females" element={<FemalesChampions />} />
          <Route path="males" element={<MalesChampions />} />
          <Route path="retired" element={<RetiredChampions />} />
          <Route path="angels" element={<AngelChampions />} />
        </Route>
        <Route path="champions-eu" element={<ChampionsMain />}>
          <Route path="" element={<ChampionsEU />} />
          <Route path="autumn-and-maya" element={<Autumn />} />
          <Route path="females" element={<Females />} />
          <Route path="retired" element={<Retired />} />
        </Route>
        <Route
          path="gallery"
          element={
            <Gallery title="Gallery 2007 - 2022" last={2022} first={2007} />
          }
        >
          <Route path="" element={<Main />} />
          <Route path="2007" element={<Gallery2007 />} />
          <Route path="2008" element={<Gallery2008 />} />
          <Route path="2009" element={<Gallery2009 />} />
          <Route path="2010" element={<Gallery2010 />} />
          <Route path="2011" element={<Gallery2011 />} />
          <Route path="2012" element={<Gallery2012 />} />
          <Route path="2013" element={<Gallery2013 />} />
          <Route path="2014" element={<Gallery2014 />} />
          <Route path="2015" element={<Gallery2015 />} />
          <Route path="2016" element={<Gallery2016 />} />
          <Route path="2017" element={<Gallery2017 />} />
          <Route path="2018" element={<Gallery2018 />} />
          <Route path="2019" element={<Gallery2019 />} />
          <Route path="2020" element={<Gallery2020 />} />
          <Route path="2021" element={<Gallery2021 />} />
          <Route path="2022" element={<Gallery2022 />} />
        </Route>
        <Route
          path="gallery"
          element={
            <Gallery
              title="PCA National Grooming Competition"
              last={2019}
              first={2019}
            />
          }
        >
          <Route path="PCA/2019" element={<GalleryPCA2019 />} />
        </Route>

        <Route path="breeding" element={<Breeding />} />

        <Route path="contact" element={<Contact />} />
        <Route path="store" element={<Store />} />

        <Route path="puppy-books" element={<PuppyBooks />} />
        <Route path="buyers-etiquette" element={<BuyersEtiquette />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="404-page-not-found" element={<PageNotFound404 />} />

        <Route
          path="*"
          element={<Navigate to="/404-page-not-found" replace />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
