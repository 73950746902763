import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";

function Breeding(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="So you think you want to breed?"
        blockQuoteValue=<p>
          Witnessing the miracle of life when a baby animal is born is truly a
          humbling and learning experience for adults but even more for
          children. <br /> However, the reality of breeding is much more{" "}
          <u>intense and involved</u>!
        </p>
        postQuoteHeadings={[
          "'Breeding dogs' are not pets ! What does this mean?",
          "1. Lots of noise!",
          "2. Lots of pee!",
          "3. Escaping or running away!",
          "4. Fights and aggression!",
          "5. You must have a male!",
          "6. TIME! ...this is a big one!",
          "7. Care for puppies",
          "8. Money",
          "9. Finding homes for your puppies...wile you are sleep deprived and trying to keep a stress free environment for your dogs?",
          "STILL WANT TO BREED?",
        ]}
        postQuoteBody={[
          <p>
            Like all unaltered animals breeding dogs simply want to do
            that...BREED, pass on their genes.Their main purpose is to find a
            mate so they can achieve this. <em>What is involved?</em>
          </p>,
          "Females and males will bark or cry sometimes a ear piece howling as they look for a mate. Day in and out they will wine and cry until they are bred. Some females will come into their heat 1 to 3 times a year for a 2-3 week period especially if they can sense an intact male around.",
          "If you already dread cleaning then you would not appreciate the territory markings from females and males. Especially males ( some females as well ) will lift their leg and pee on floor, on walls,doors,furniture,bed and pretty much everywhere they can.This must be cleaned on a regular basis and the smell of urine can be hard to remove.",
          "Both males & females will CONSTANTLY be in search of a mate.If there are no mates in the house -- ESCAPING is their new purpose in life. Not only is a potentially expensive poodle now outside, they can be attacked by other animals, stolen or the worse case scenario hit by a car.",
          "Breeding animals are always in competition to get the best mate first.No matter how they were raised, HORMONES and MATERNAL INSTINCT is priority. Especially males will go as far as killing each other or offspring of their competitor.This can mean even aggressiveness towards people.",
          <p>
            If there's no males to breed to constantly cycling females - could
            result in <em>health issues </em>like uterine infections, putting
            the female's life at risk and an expensive veterinary bill. <br />
            If you do not have a male, you may have to send the female to be
            bred or pay for a sperm sample. Numerous veterinary visits to take
            blood sample for a progesterone level test ( to figure out best
            breeding time). Both are stressful for your female and expensive.
            <br />
            Wile some kennels are closed to outside dogs, some may take in your
            female for several weeks potentially exposing your female to illness
            and disease. The same applies if you bring in a male. <br /> When
            you looking for genetic diversity, importing a stud dog or buying
            frozen semen is highly expensive and there's a possibility it might
            not impregnate your female.
          </p>,
          <p>
            <em>YOU MUST ALWAYS BE AVAILABLE for when puppies are born.</em> You
            can only plan ahead so much. <br />
            <em>
              You cannot go on vacations or have a normal sleeping schedule!
            </em>{" "}
            <br />
            Puppies can be born at any time and EMERGENCIES can quickly arise
            requiring a veterinary trip whether this is 3 PM or 3 AM.If you need
            an emergency veterinary service, this can be extremely expensive.
            Puppies can be born with health problems and / or a female may
            require a C-section surgery. <br />
            You must have time to keep everything clean to avoid bacteria ,
            viral and other illness/disease among your poodles and puppies.{" "}
            <br />
            You must have time to socialize puppies plus various forms of
            enrichment and stimulation within their environment and house.{" "}
            <br />
            Spend time with breeders and handlers.
          </p>,
          <div>
            <p>This will include:</p>
            <ul>
              <li>
                Monitoring their health and development ( some puppies may be so
                ill that they may pass away. Can you handle this? Or call the
                shot to humanly put an end of a suffering puppy and live with
                your decision? )
              </li>
              <li>
                Making sure they are eating and growing properly ( some puppies
                may require tube feeding or help eliminating waste).
              </li>
              <li>
                Daily socialization including handling, playing, getting used to
                various situations such as noises, strangers and other animals.
              </li>
            </ul>
          </div>,
          <p>
            Do you have the funds to feed multiple dogs or puppies that
            constantly are growing and eating? <br />
            To run your washer and dryer around the clock and buying cleaning
            supplies? <br />
            To take the puppies to the vet for screening, fecal samples tested,
            health exams or if they become ill? <br />
            To provide all the supplements to bust their immune system? <br />​
            Can you afford to show your dogs and maintain a long coat and have
            all health screening done so you can bring into this world puppies
            who have the best chance for a long life?
          </p>,
          <p>
            Advertising and /or maintaining a website or social media presence
            can be time consuming and expensive. <br /> Will you be ok with
            having people know your physical address and phone number. Will you
            be patient to answer phone calls multiple times a day wile you
            trying to keep your puppies on a schedule. Take pictures and videos
            to update and educate new owners? <br />
            Will you be ready to deal with difficult customers that may be
            demanding or are constantly calling and emailing you with questions
            and concerns? ​<br />
            Will you know how to handle phone calls, later on, if something bad
            happens to your puppy in someone else care that you trusted? <br />
            Do you know how to carefully screen people to avoid puppy mills,
            cross breeding backyard breeders, people who don't care for them as
            a family member but rather just a nice Birthday or Christmas present
            that can be tossed when inconveniences arise and agents who are
            third party buyer etc.?
          </p>,
          <p>
            Do you have a great understanding of genetics? How about the breed
            standard? Can you tell the difference between a breed quality, show
            quality or a pet quality poodle? <br />
            <u>
              Do you understand breeding is more than a beautiful litter and you
              should only be breeding to further the breed?
            </u>
          </p>,
        ]}
      />
      <br />
      <br />
    </React.Fragment>
  );
}

export default Breeding;
