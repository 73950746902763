import React, { Component } from "react";
import { Parallax } from "react-scroll-parallax";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

class Clients extends Component {
  render() {
    return (
      <div id="clients" className={"section no-page-title"}>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <Parallax translateY={[-5, 0]}>
              <div>
                <h1 className={"medium-text years-pagination "}>
                  Testimonials
                </h1>
                <div
                  style={{ paddingBottom: "130px" }}
                  className={"text-slider-wrapper relative"}
                >
                  <div className={"text-slider-header-quotes"}></div>

                  <div
                    style={{ marginTop: "2rem" }}
                    className={"years-pagination"}
                  >
                    <iframe
                      width="978"
                      height="697"
                      src="https://www.youtube.com/embed/rgDjTnNAM-k"
                      title="Danube Poodles Extended Families"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <Link
                      style={{ marginTop: "2rem" }}
                      className={"button"}
                      to={"/testimonials/one"}
                    >
                      See all testimonials
                    </Link>
                  </div>
                  {/* </div> */}
                  <div className={"clear"}></div>
                </div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
