import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";

function PrivacyPolicy() {
  return (
    <GenericInfoPage
      title="Policies and Discraimers"
      preQuoteBody={[
        <div>
          <h2 className="make-green">LEGAL/LIABILITY DISCLAIMER</h2>

          <p>
            Information contained in Danube Poodle’s site (or found in any
            external links provided) is meant for educational purposes only, and
            is not to be construed as veterinary, medical or legal advice.  
          </p>

          <p>
            Recommendations or descriptions of actual care here are derived from
            personal experiences and years of study, however your decisions
            concerning feeding, vaccinations, regular veterinary care, and
            training - and how you implement those decisions - are yours and
            yours alone.
          </p>

          <p>
            Site owners, author, and contributors accept no responsibility or
            liability for your decisions.
          </p>

          <h2 className="make-green">PRIVACY POLICY</h2>

          <p>
            Danube Poodles (the “Company”) is committed to protecting the
            privacy of its users. This Privacy Policy (“Privacy Policy”) is
            designed to help you understand what information we gather, how we
            use it, what we do to protect it, and to assist you in making
            informed decisions when using our Service.  Unless otherwise
            indicated below, this Privacy Policy applies to any website that
            references this Privacy Policy, any Company website, as well as any
            data the Company may collect across partnered and unaffiliated
            sites.  
          </p>

          <p>
            For purposes of this Agreement, “Service” refers to the Company’s
            service which can be accessed via our website at
            http://www.danubepoodles.com or through our mobile application. The
            terms “we,” “us,” and “our” refer to the Company. “You” refers to
            you, as a user of Service.
          </p>

          <h6>I. CONSENT</h6>

          <p>
            By accessing our Service, you accept our Privacy Policy and Terms of
            Use, and you consent to our collection, storage, use and disclosure
            of your personal information as described in this Privacy Policy. In
            addition, by using our Service, or services across partnered and
            unaffiliated sites, you are accepting the policies and practices
            described in this Privacy Policy. Each time you visit our website,
            or use the Service, and any time you voluntarily provide us with
            information, you agree that you are consenting to our collection,
            use and disclosure of the information that you provide, and you are
            consenting to receive emails or otherwise be contacted, as described
            in this Privacy Policy. Whether or not you register or create any
            kind of account with us, this Privacy Policy applies to all users of
            the website and the Service.
          </p>

          <h6>II. INFORMATION WE COLLECT</h6>

          <p>
            We may collect both “Non-Personal Information” and “Personal
            Information” about you. “Non-Personal Information” includes
            information that cannot be used to personally identify you, such as
            anonymous usage data, general demographic information we may
            collect, referring/exit pages and URLs, platform types, preferences
            you submit and preferences that are generated based on the data you
            submit and number of clicks. “Personal Information” includes
            information that can be used to personally identify you, such as
            your name, address and email address.
          </p>

          <p>
            In addition, we may also track information provided to us by your
            browser or by our mobile application when you view or use the
            Service, such as the website you came from (known as the “referring
            URL”), the type of browser you use, the device from which you
            connected to the Service, the time and date of access, and other
            information that does not personally identify you.  We use this
            information for, among other things, the operation of the Service,
            to maintain the quality of the Service, to provide general
            statistics regarding use of the Service and for other business
            purposes. We track this information using cookies, or small text
            files which include an anonymous unique identifier. Cookies are sent
            to a user’s browser from our servers and are stored on the user’s
            computer hard drive. Sending a cookie to a user’s browser enables us
            to collect Non-Personal Information about that user and keep a
            record of the user’s preferences when utilizing our services, both
            on an individual and aggregate basis.  The Company may use both
            persistent and session cookies; persistent cookies remain on your
            computer after you close your session and until you delete them,
            while session cookies expire when you close your browser. Persistent
            cookies can be removed by following your Internet browser help file
            directions. If you choose to disable cookies, some areas of the
            Service may not work properly.
          </p>

          <h6>III. HOW WE USE AND SHARE INFORMATION</h6>

          <p>Personal Information:</p>

          <p>
            In general, we do not sell, trade, rent or otherwise share your
            Personal Information with third parties without your consent. We may
            share your Personal Information with vendors and other third-party
            providers who are performing services for the Company. In general,
            the vendors and third-party providers used by us will only collect,
            use and disclose your information to the extent necessary to allow
            them to perform the services they provide for the Company.  For
            example, when you provide us with personal information to complete a
            transaction, verify your credit card, place an order, arrange for a
            delivery, or return a purchase, you consent to our collecting and
            using such personal information for that specific purpose, including
            by transmitting such information to our vendors (and their service
            providers) performing these services for the Company.
          </p>

          <p>
            However, certain third-party service providers, such as payment
            processors, have their own privacy policies in respect of the
            information that we are required to provide to them in order to use
            their services.  For these third-party service providers, we
            recommend that you read their privacy policies so that you can
            understand the manner in which your Personal Information will be
            handled by such providers.
          </p>

          <p>
            In addition, we may disclose your Personal Information if required
            to do so by law or if you violate our Terms of Use.
          </p>

          <p>Non-Personal Information:</p>

          <p>
            In general, we use Non-Personal Information to help us improve the
            Service and customize the user experience. We also aggregate
            Non-Personal Information in order to track trends and analyze use
            patterns of the Service. This Privacy Policy does not limit in any
            way our use or disclosure of Non-Personal Information and we reserve
            the right to use and disclose such Non-Personal Information to our
            partners, advertisers and other third parties at our sole
            discretion.
          </p>

          <h6>IV. HOW WE PROTECT INFORMATION</h6>

          <p>
            We implement reasonable precautions and follow industry best
            practices in order to protect your Personal Information and ensure
            that such Personal Information is not accessed, disclosed, altered
            or destroyed.  However, these measures do not guarantee that your
            information will not be accessed, disclosed, altered or destroyed by
            breach of such precautions. By using our Service, you acknowledge
            that you understand and agree to assume these risks.
          </p>

          <h6>V. LINKS TO OTHER WEBSITES</h6>

          <p>
            As part of the Service, we may provide links to or compatibility
            with other websites or applications. However, we are not responsible
            for the privacy practices employed by those websites or the
            information or content they contain. This Privacy Policy applies
            solely to information collected by us through the Service.
            Therefore, this Privacy Policy does not apply to your use of a
            third-party website accessed by selecting a link via our Service. To
            the extent that you access or use the Service through or on another
            website or application, then the privacy policy of that other
            website or application will apply to your access or use of that site
            or application. We encourage our users to read the privacy
            statements of other websites before proceeding to use them.
          </p>

          <h6>VI. AGE OF CONSENT</h6>

          <p>
            By using the Service, you represent that you are at least 18 years
            of age.
          </p>

          <p>VII. CHANGES TO OUR PRIVACY POLICY</p>

          <p>
            The Company reserves the right to change this Privacy Policy and our
            Terms of Use at any time. If we decide to change this Privacy
            Policy, we will post these changes on this page so that you are
            always aware of what information we collect, how we use it, and
            under what circumstances we disclose it. Any such modifications
            become effective upon your continued access to and/or use of the
            Service five (5) days after we first post the changes on the website
            or otherwise provide you with notice of such modifications. It is
            your sole responsibility to check this website from time to time to
            view any such changes to the terms of this Privacy Policy. If you do
            not agree to any changes, if and when such changes may be made to
            this Privacy Policy, you must cease access to this website. If you
            have provided your email address to us, you give us permission to
            email you for the purpose of notification as described in this
            Privacy Policy.
          </p>

          <h6>VII. EMAIL COMMUNICATIONS &amp; OPTING OUT</h6>

          <p>
            We will send you Service-related announcements on occasions when it
            is necessary to do so. For instance, if our Service is temporarily
            suspended for maintenance, or a new enhancement is released, which
            will affect the way you use our Service, we might send you an
            email.  Generally, you may not opt-out of these communications,
            which are not promotional in nature.  Based upon the Personal
            Information that you provide us, we may communicate with you in
            response to your inquiries to provide the services you request and
            to manage your account. We will communicate with you by email or
            telephone, in accordance with your wishes.  We may also use your
            Personal Information to send you updates and other promotional
            communications. If you no longer wish to receive those email
            updates, you may opt-out of receiving them by following the
            instructions included in each update or communication.
          </p>

          <h6>IX. CONTACT US &amp; WITHDRAWING CONSENT</h6>

          <p>
            If you have any questions regarding this Privacy Policy or the
            practices of this Site, or wish to withdraw your consent for the
            continued collection, use or disclosure of your Personal
            Information, please contact us by sending an email to
            danubepoodles@yahoo.com.
          </p>

          <p>
            Last Updated: This Privacy Policy was last updated on Fri Jan 13
            2023.
          </p>
        </div>,
      ]}
    />
  );
}

export default PrivacyPolicy;
