import React, { Component } from "react";
import { FaCheck } from "react-icons/fa";
class Pricing extends Component {
  render() {
    return (
      <div id="pricing" className={"section"}>
        <div className={"page-title-holder"}>
          <h3 className={"entry-title"}>PRICING</h3>
        </div>
        <div className={"section-wrapper block content-1170 center-relative"}>
          <div className={"content-wrapper"}>
            <div className={"one_third"}>
              <div className={"pricing-table"}>
                <p className={"pricing-num"}>1</p>
                <div className={"pricing-wrapper"}>
                  <div className={"pricing-table-header"}>
                    <div className={"pricing-table-title"}>ONE</div>
                  </div>
                  {/* <!-- <div className={"pricing-table-price"}>$25</div>
                      <div className={"pricing-table-desc}>PER MONTH</div> --> */}
                  <div className={"pricing-table-content-holder"}>
                    <ul>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={"pricing-button"}>
                  <a href="#" className={"button scroll"}>
                    LEARN MORE
                  </a>
                </div>
              </div>
            </div>

            <div className={"one_third"}>
              <div className={"pricing-table"}>
                <p className={"pricing-num"}>2</p>
                <div className={"pricing-wrapper"}>
                  <div className={"pricing-table-header"}>
                    <div className={"pricing-table-title"}>TWO</div>
                  </div>
                  <div className={"pricing-table-content-holder"}>
                    <ul>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={"pricing-button"}>
                  <a href="#" className={"button scroll"}>
                    LEARN MORE
                  </a>
                </div>
              </div>
            </div>

            <div className={"one_third last"}>
              <div className={"pricing-table"}>
                <p className={"pricing-num"}>3</p>
                <div className={"pricing-wrapper"}>
                  <div className={"pricing-table-header"}>
                    <div className={"pricing-table-title"}>THREE</div>
                  </div>
                  <div className={"pricing-table-content-holder"}>
                    <ul>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-yes"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                      <li className={"pricing-list included-no"}>
                        <FaCheck />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={"pricing-button"}>
                  <a href="#" className={"button scroll"}>
                    {" "}
                    LEARN MORE{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className={"clear"}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;
