import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function GalleryPCA2019(props) {
  const folder1 = [...Array(22)].map((e, i) => {
    let ext = "jpg";
    if (i === 21) ext = "png";
    return (
      <img
        style={{ border: "1px solid black", maxHeight: "450px" }}
        className={"champions-images"}
        alt="PCA 2019"
        src={require(`../../../images/gallery/PCA 2019/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(2)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="PCA 2019"
        src={require(`../../../images/gallery/PCA 2019/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="PCA 2019"
        src={require(`../../../images/gallery/PCA 2019/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>;
    </React.Fragment>
  );
}

export default GalleryPCA2019;
