import React from "react";
import ScrollBottomButton from "../../../components/ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../../../components/ScrollButton/ScrollTopButton";
function Gallery2021(props) {
  const folder1 = [...Array(1)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/1/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder2 = [...Array(5)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/2/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder3 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/3/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder4 = [...Array(2)].map((e, i) => {
    let ext = "jpg";

    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/4/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder5 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/5/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder6 = [...Array(11)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/6/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder7 = [...Array(5)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/7/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder8 = [...Array(10)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/8/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder9 = [...Array(7)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/9/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder10 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/10/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder11 = [...Array(3)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/11/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder12 = [...Array(2)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/12/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder13 = [...Array(8)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/13/img_${i + 1}.${ext}`)}
      />
    );
  });
  const folder14 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/14/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder15 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/15/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder16 = [...Array(4)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/16/img_${i + 1}.${ext}`)}
      />
    );
  });

  const folder17 = [...Array(6)].map((e, i) => {
    let ext = "jpg";
    return (
      <img
        style={{ border: "1px solid black" }}
        className={"champions-images"}
        alt="2021"
        src={require(`../../../images/gallery/2021/17/img_${i + 1}.${ext}`)}
      />
    );
  });
  return (
    <React.Fragment>
      <br />
      <br />
      <div className="flex-custom-container">{folder1}</div>
      <div className="flex-custom-container">{folder2}</div>
      <div className="flex-custom-container">{folder3}</div>
      <div className="flex-custom-container">{folder4}</div>
      <div className="flex-custom-container">{folder5}</div>,
      <div className="flex-custom-container">{folder6}</div>,
      <div className="flex-custom-container">{folder7}</div>
      <div className="flex-custom-container">{folder8}</div>
      <div className="flex-custom-container">{folder9}</div>
      <div className="flex-custom-container">{folder10}</div>
      <div className="flex-custom-container">{folder11}</div>,
      <div className="flex-custom-container">{folder12}</div>,
      <div className="flex-custom-container">{folder13}</div>
      <div className="flex-custom-container">{folder14}</div>
      <div className="flex-custom-container">{folder15}</div>
      <div className="flex-custom-container">{folder16}</div>
      <div className="flex-custom-container">{folder17}</div>, ;
    </React.Fragment>
  );
}

export default Gallery2021;
