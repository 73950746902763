import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import Services from "../../components/Home/Services/Services";
import { Link } from "react-router-dom";
import SimpleInfoSection from "../../components/SimpleInfoSection/SimpleInfoSection";
import GenericInfoSection from "../../components/GenericInfoSection/GenericInfoSection";
import image1 from "../../images/health_protocol1.png";
import image2 from "../../images/health_protocol2.png";

export default function AboutPage() {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Welcome to Danube Poodles"
        imageName="about/IMG_2956.webp"
        imageName2="about/about_collage.png"
        videos={[
          "../../images/about/video/video1.mp4",
          "../../images/about/video/video2.mp4",
          "../../images/about/video/video3.mp4",
        ]}
        // imageGallery={[
        //   "about/timi1.webp",
        //   "about/timi2.webp",
        //   "about/timi3.webp",
        //   "about/timi4.webp",
        // ]}
        imageGalleryHeight="200px"
        preQuoteHeadings={["Hello there,"]}
        preQuoteBody={[
          `My name is Timea R. Bodi, and I have the profound joy and privilege of breeding quality Miniature Poodles under the name Danube. My love affair with poodles began in Europe, where my family and I dedicated ourselves to breeding and showing these magnificent dogs. The poodle's intelligence, beauty, devotion, and joyful spirit have always held a special place in my heart.`,
        ]}
        blockQuoteValue="In 2007, I embarked on a new chapter in the USA, determined to continue my family's cherished tradition. While working as a Veterinary Technician for a decade, I amassed a wealth of knowledge and insights from esteemed poodle enthusiasts and experts beyond the breed. This journey has been one of continuous learning and heartfelt dedication."
        postQuoteBody={[
          `I am a small hobby breeder, and my home, not a kennel, is where all my poodles live. Here, they are showered with attention, care, and ample exercise. My breeding program is dedicated to creating poodles that excel in conformation, agility, and obedience rings. But more importantly, I strive to nurture athletic, intelligent, and long-lived family members who bring immense joy to their homes.`,
          `Every dog in my care undergoes rigorous testing to ensure the highest standards of health and well-being. This includes Optigen blood tests for prcd PRA, specialist ophthalmic SURF exams, hip x-rays with OFA ratings, DNA tests, dwarfism screenings, and patella luxation checks. All our puppies are AKC registered and thoroughly evaluated by a veterinarian who provides a health certificate.`,
          `My commitment to excellence extends beyond genetics and physical health. I am deeply invested in a holistic, natural-reared lifestyle for my poodles. This approach includes a species-appropriate diet, non-toxic environments, and comprehensive wellness practices. My poodles benefit from fresh air, exercise, mental stimulation, and a harmonious living space. I believe that these elements are essential for raising happy, healthy, and well-rounded dogs.`,
          `To stay at the forefront of the best practices in dog breeding and care, I continuously educate myself through seminars, pet summits, clinics, and an endless stream of books. This dedication to learning ensures that I am always equipped with the latest knowledge and techniques to nurture and elevate each generation of poodles I raise.`,
          `I take immense pride in my work, devoting myself to ensuring that each generation of puppies is the best representation of the poodle breed. My ultimate goal is to produce poodles with sound minds and bodies, dogs that excel in both conformation and performance, while also being loving companions that complete families and enrich lives.`,
          `This journey is more than just breeding dogs; it is about nurturing souls that bring light and joy into our lives. My poodles are not just pets—they are family, and they have the extraordinary power to fill your life with love, companionship, and endless happiness.`,
        ]}
      />
      <SimpleInfoSection
        content1={
          <React.Fragment>
            <iframe
              width="1206"
              height="656"
              src="https://www.youtube.com/embed/C61bgm0NTv8"
              title="Raising Poodles Naturally"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <br />
            <br />
            <br />
            <h5 style={{ textAlign: "center" }}>
              Learn more about our way of breeding.
              <br />
              See exactly where we raise and nurture our poodles plus our
              dedicated grooming, whelping and fun puppy areas.
            </h5>
            <br />

            <div
              style={{ textAlign: "center" }}
              className={"button-holder text-left"}
            >
              <Link className={"button"} to="our-piece-of-heaven">
                Our piece of Heaven
              </Link>{" "}
              <Link className={"button"} to="our-grooming-room">
                Our Special Poodle Rooms
              </Link>
            </div>
            <br />
            <br />
          </React.Fragment>
        }
      />
      {/* <GenericInfoPage
        articleLayout="true"
        preQuoteHeadings={["Our little piece of Heaven"]}
        preQuoteBody={[
          `This is our little piece of heaven where Danube Poodles get to enjoy fresh air, run and play all day long. We live in middle of Tennessee on a 10 acre land surrounded by God's nature beauty , rivers and picturesque waterfalls. Here is where our poodles get to learn to sharpen their senses chasing rabbits, butterflies ,birds and share undivided time and attention from family and friends.`,
        ]}
        blockQuoteValue="We feel getting into the nitty gritty of the breed is really what advances not only the individual poodle, but the breed as a whole."
      /> */}
      <Services
        buttonIsAvailable="true"
        paddingTop="5rem"
        smallerHeading={true}
        bigText="Danube is the biggest river in Europe passing through Romania and
            Hungary. We chose this name to represent our poodles because it is
            inspiring us to dream big and to represent our roots that unites us
            through a legacy of poodle generations giving us a plethora of genetics
            and timeless connections. And that is exactly our over arching goal.
            Besides the genetic diversity we achieve this in several ways:"
        first={{
          title: "HEALTH",
          description:
            "Health is incredible important for any living being. If a creature is not healthy they cannot live a happy and fulfilling life.",
          number: "1",
          extraDescription:
            "Health is incredible important for any living being. If a creature is not healthy they cannot <br/> live a happy and fulfilling life. All our poodles and puppies  are evaluated and recorded <br/> on a regular basis for health. We are proud advocates on screenings to ensure our poodles are free of hereditary diseases. <br/> We are proud to provide our poodles with various forms of enrichment and stimulation within their environment and in our home.",
        }}
        second={{
          title: "EXERCISE",
          description:
            "Exercise is never optional. Animals are meant to move almost constantly. In the wild, playing, moving from place to place, and hunting are done daily.",
          number: "2",
          extraDescription: "",
        }}
        third={{
          title: "SUN",
          description:
            "Animals instinctually know what is needed to stay healthy (see Zoopharmacognocy). ",
          number: "3",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        first={{
          title: "SUPPLEMENTS",
          description:
            "Supplements are especially needed when a raw , species specific diet is not being fed and/ or when the animal is already in a dis-eased state.",
          number: "4",
          extraDescription: "",
        }}
        second={{
          title: "TEMPERANCE",
          description:
            "Temperance is an old-fashioned word for moderation. Too much of even a good thing can wind up being detrimental",
          number: "5",
          extraDescription: "",
        }}
        third={{
          title: "AIR",
          description:
            "Every living thing needs fresh air daily if possible. Fresh air energizes.",
          number: "6",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        first={{
          title: "REST",
          description:
            "Companion animals need plenty of quality rest. They do best without all the noise of modern appliances and electronic EMFs.",
          number: "7",
          extraDescription: "",
        }}
        second={{
          title: "TRUST",
          description:
            "Trust is not something very many people think about as being a law of nature and health, but it is the eighth law. ",
          number: "8",
          extraDescription: "",
        }}
        third={{
          title: "TEMPERAMENT",
          description:
            "Although not every poodle will do therapy work or enter the show ring, a great out going, social and loving personality is characteristic of the breed.",
          number: "9",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        ackImage={true}
        image1={image1}
        first={{
          title: "GENETICS",
          description:
            "In order to truly produce impeccable poodles that not only advance the individual but breed, one needs an understanding of poodle genetics.",
          number: "10",
          extraDescription: "",
        }}
        second={{
          title: "NUTRITION",
          description:
            "Nutrition is the fuel of life.Without proper diet domestic dogs of all kinds cannot live to their full potential 100%. They are merely surviving versus thriving.",
          number: "11",
          extraDescription: "",
        }}
        third={{
          title: "STANDARD",
          description:
            "Although brilliant color and the right point pigmentation are characteristics of the breed, there is certainly more to it than flash.",
          number: "12",
          extraDescription: "",
        }}
      ></Services>
      <GenericInfoSection
        id="standards"
        title="AKC Poodle Breed Standard"
        sectionTitle="THE HIGHEST STANDARDS"
        titleDescription="OUR POODLES ARE THE BEST. REALLY."
        images="true"
        image1={image1}
        image2={image2}
        description="Poodles are among the greatest companions. And our poodles are the best out there. We abide to the heighest standards and we pride ourselves in bringing them up with love and joy."
        content1={
          <React.Fragment>
            <br />
            <br />
            <p>
              See for yourself what that entails. Click below to download or
              print the AKC Poodle Breed Standard in order to see what we are
              working hard to represent. You can also check below the Poodle
              History to see what we mean by "the greatest companions".{" "}
            </p>
            <br />

            <div className={"button-holder text-left"}>
              <a
                className={"button"}
                href="https://www.akc.org/dog-breeds/poodle-standard/#timeline"
              >
                AKC Poodle Breed Standard
              </a>{" "}
              <a
                className={"button"}
                href="http://cdn.akc.org/Poodle-club-flier.pdf?_ga=1.205349200.1940403961.1470613763"
              >
                Poodle History
              </a>
            </div>
            <br />
          </React.Fragment>
        }
      ></GenericInfoSection>
    </React.Fragment>
  );
}
